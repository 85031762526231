import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons';
import ReactEcharts from 'echarts-for-react';
import {
  HeadingTitle,
  ModalConfig,
  ImageStyled,
  HeaderWrapper,
  UrlDescription} from '../styledComponents';
import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {getDomain} from '@/utils/url';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';

export const ConversationHeader = observer(() => {
  const {inbox: {conversationOpportunityData, opConversationLoading}} = useStore('');

  const chartOption = dataValue => {
    return {
      series: [{

        type: 'gauge',
        startAngle: 190,
        endAngle: 350,
        itemStyle: {
          color: '#2D6CCA',
        },
        progress: {
          show: true,
          width: 5,
        },
        axisLine: {
          lineStyle: {
            width: 5,

          },
        },
        pointer: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          distance: -13,
          show: false,
          length: 10,
          lineStyle: {
            color: '#2D2F34',
            width: 0,
          },
        },
        splitNumber: 0,
        axisLabel: {
          show: false,
          color: '#2D6CCA',
          distance: 40,
          fontSize: 20,
        },
        detail: {
          show: true,
          offsetCenter: [0, '15%'],
          formatter: value => value || value==0 ? `${value}` : '',
          color: 'white',
          fontSize: 11,
        },
        data: [{
          value: `${dataValue}`,
        }],
      }],
    };
  };

  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };
  const getImgUrl = url => {
    return <ImageStyled onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(url)}`} alt={url}/>;
  };

  return <HeaderWrapper>
    {opConversationLoading ? <div style={{marginTop: '10px'}}><RingLoaderV2 width='40px' height='40px' fontSize={28}/></div> : getImgUrl(conversationOpportunityData?.opportunityDetails?.url)}
    <div>
      {opConversationLoading ?
        <>
          <SkeletonHorizontalLoaderGray height={37} width={260}/>
          <SkeletonHorizontalLoaderGray height={21} width={200}/>
        </> :
        <>
          <HeadingTitle>{conversationOpportunityData?.emailTo}<span></span></HeadingTitle>
          <UrlDescription>
            <a target='_blank' style={{color: '#2D6CCA'}} href={conversationOpportunityData?.opportunityDetails?.url} rel='noreferrer'>
              {conversationOpportunityData?.opportunityDetails?.url}
            </a>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#2D6CCA'/>
          </UrlDescription>
        </>}
      <div style={{padding: '6px 0 0 0'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <ModalConfig>
            <span>DR&nbsp;{opConversationLoading ?
              <SkeletonHorizontalLoaderGray height={15} width={60}/> :
              <><ReactEcharts style={{width: 24, height: 24}} option={chartOption(conversationOpportunityData?.opportunityDetails?.ahrefsDr)} />
                <b>{conversationOpportunityData?.opportunityDetails?.ahrefsDr}</b></>}</span>
          </ModalConfig>
          <ModalConfig>SS:&nbsp;{opConversationLoading ?
            <SkeletonHorizontalLoaderGray height={15} width={60}/> :
            <><FontAwesomeIcon icon={faCircle} color='#2AC155' fontSize={8} /><div style={{fontWeight: 600}}>&nbsp;1</div></>}</ModalConfig>
          <ModalConfig>
            <span>RD&nbsp;{opConversationLoading ?
              <SkeletonHorizontalLoaderGray height={15} width={60}/> :
              <><ReactEcharts style={{width: 24, height: 24}} option={chartOption(conversationOpportunityData?.opportunityDetails?.ahrefsRefdomains)} /><b>{conversationOpportunityData?.opportunityDetails?.ahrefsRefdomains}</b></>}</span>
          </ModalConfig>
          <ModalConfig>Traffic:&nbsp;{opConversationLoading ?
            <SkeletonHorizontalLoaderGray height={15} width={60}/> :
            <><div style={{fontWeight: 600}}>&nbsp;{conversationOpportunityData?.opportunityDetails?.ahrefsOt || 0}</div></>}</ModalConfig>
          <ModalConfig>Ext. Links:&nbsp;{opConversationLoading ?
            <SkeletonHorizontalLoaderGray height={15} width={60}/> :
            <><div style={{fontWeight: 600}}>&nbsp;{conversationOpportunityData?.opportunityDetails?.ahrefsRefdomains || 0}</div></>}</ModalConfig>
        </div>
      </div>
    </div>
  </HeaderWrapper>;
});
