import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Modal} from 'antd';
import {observer} from 'mobx-react';
import {UpdateSection} from './ModalLeftSection/UpdateSection';
import {PreviewSection} from './ModalRightSection/PreviewSection';
import {CreateSection} from './ModalLeftSection/CreateSection';
import {PlaceholdersSection} from './ModalRightSection/PlaceholdersSection';
import {useStore} from '@/store/root-store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {notification} from '@/utils/notification-v2';
import moment from 'moment';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  openedFromTable?: boolean;
  recordObj?: Record<string, any>;
}

const getMinutesDifference = date => {
  const givenDate = moment(date);
  const now = moment();
  const differenceInMinutes = now.diff(givenDate, 'minutes');
  return differenceInMinutes;
};

const calculateInitialPercentage = record => {
  if (!record) {
    return 0;
  }
  const now = moment();
  const createdAt = moment(record?.isRebuild ? record?.updatedAt : record?.createdAt);
  const difference = now.diff(createdAt, 'seconds');
  if (difference >= 90) {
    return 99;
  } else {
    const percentage = Math.min(Math.ceil((difference / 90) * 100), 99);
    return percentage;
  }
};

export const showErrorMessages = record => {
  if (record?.isAiGeneratedImages && !record?.isImagesGenerated && record?.cleanedHtml) {
    notification.warning('', 'AI failed to generate Images');
  }
  if (record?.status === 'Generated' && !record?.cleanedHtml) {
    notification.error('', 'AI failed to generate the Page');
  }
};

export const LandingPageGeneratorModal: React.FC<Props> = observer(({openModal, setOpenModal, openedFromTable, recordObj}) => {
  const isGenerating = ['Generating', 'Pending'].includes(recordObj?.status);
  const isRebuildOption = openedFromTable && getMinutesDifference(recordObj?.updatedAt) >= 10 && isGenerating && !recordObj?.isRebuild;

  const [markdown, setMarkdown] = useState(recordObj?.cleanedHtml);
  const [canRebuild, setCanRebuild] = useState(isRebuildOption ? true : false);
  const [generating, setGenerating] = useState(isGenerating ? true : false);
  const [activeStep, setActiveStep] = useState(openedFromTable && (!isGenerating || recordObj?.isRebuild) ? 1 : 0);
  const [record, setRecord] = useState(recordObj);
  const [percentage, setPercentage] = useState(isGenerating ? calculateInitialPercentage(recordObj) || 99 : 0);
  const [isAnimating, setIsAnimating] = useState(false);

  const {ottoV2Store: {
    loadIssueTableData,
    getOttoV2Project,
    setLandingPageDetailShouldRepoll,
    getLandingPageDetail,
    rebuildLandingPage,
    landingPageDetail,
  }} = useStore('');

  useEffect(() => {
    if (record?.cleanedHtml) setMarkdown(record?.cleanedHtml);
  }, [record?.cleanedHtml]);

  useEffect(() => {
    const date = landingPageDetail?.createdAt;
    if (date && ['Generating', 'Pending'].includes(landingPageDetail?.status) && !landingPageDetail?.isRebuild) {
      const fn = () => {
        const differenceInMinutes = getMinutesDifference(date);
        if (differenceInMinutes >= 10) {
          setCanRebuild(true);
        } else setCanRebuild(false);
      };
      fn();
      const interval = setInterval(fn, 60000); // check every minute if 10 minutes have passed since the page's creation time
      return () => clearInterval(interval);
    } else setCanRebuild(false);
  }, [landingPageDetail]);

  useEffect(() => {
    setLandingPageDetailShouldRepoll(true);
    const load = async () => {
      const res: any = await getLandingPageDetail(recordObj?.uuid);
      if (res?.status === 'Generated') {
        setRecord(res);
        setPercentage(100);
        setTimeout(() => {
          if (generating) showErrorMessages(res);
          setGenerating(false);
          setActiveStep(1);
          loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false, true);
        }, 2000);
      }
    };
    if (isGenerating) handleAnimate();
    if (recordObj?.uuid) load();
    return () => setLandingPageDetailShouldRepoll(false);
  }, []);

  const downloadHtml = useCallback((excludeHeader: boolean, excludeFooter: boolean) => {
    try {
      let serialized = markdown;
      if (excludeFooter || excludeHeader) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(markdown, 'text/html');
        if (excludeHeader) {
          const headers = doc.querySelectorAll('header, nav');
          headers?.forEach(header => header.remove());
        }
        if (excludeFooter) {
          const footers = doc.querySelectorAll('footer');
          footers?.forEach(footer => footer.remove());
        }
        const serializer = new XMLSerializer();
        serialized = serializer.serializeToString(doc);
      }

      const blob = new Blob([serialized], {type: 'text/html'});
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'file.html';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      notification.error('', 'Failed to download HTML');
    }
  }, [markdown]);

  const handleAnimate = () => {
    if (isAnimating) return;
    const intervalTime = 1000;
    const incrementValue = 99 / (90000 / intervalTime);
    const intervalId = setInterval(() => {
      setPercentage(prevPercentage => {
        if (prevPercentage >= 99) {
          clearInterval(intervalId);
          setIsAnimating(false);
          return prevPercentage === 100 ? 100 : 99;
        }
        return Math.round(Math.min(prevPercentage + incrementValue, 99));
      });
    }, intervalTime);
  };

  const rebuildPage = useCallback(async () => {
    try {
      setCanRebuild(false);
      setPercentage(0);
      handleAnimate();
      setGenerating(true);
      await rebuildLandingPage(record?.uuid);
      loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false);
      const response: any = await getLandingPageDetail(record?.uuid);
      if (response?.status === 'Generated') {
        setRecord(response || {});
        setPercentage(100);
        notification.success('Success', 'Landing Page regenerated successfully');
        setTimeout(() => {
          showErrorMessages(response);
          setGenerating(false);
          setActiveStep(1);
          loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false, true);
        }, 2000);
      }
    } catch (error) {
      setGenerating(false);
      setPercentage(100);
    }
  }, [record?.uuid]);

  const steps = [
    <>
      <CreateSection
        record={record}
        setPercentage={setPercentage}
        handleAnimate={handleAnimate}
        setActiveStep={setActiveStep}
        setRecord={setRecord} setOpenModal={setOpenModal}
        generating={generating}
        setGenerating={setGenerating}
        setCanRebuild={setCanRebuild}
      />
      <PlaceholdersSection rebuildPage={rebuildPage} canRebuild={canRebuild} generating={generating} percentage={percentage} />
    </>,
    <>
      <UpdateSection
        record={record}
        downloadHtml={downloadHtml}
        closeModal={() => {
          loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false);
          setOpenModal(false);
        }}
        generating={generating}
      />
      {generating ? (
        <PlaceholdersSection rebuildPage={rebuildPage} canRebuild={canRebuild} generating={generating} percentage={percentage} />
      ) : (
        <PreviewSection generating={generating} rebuildPage={rebuildPage} markdownId={record?.uuid} markdown={markdown} setMarkdown={setMarkdown} percentage={percentage} />
      )}
    </>,
  ];

  return (
    <StyledTopicalMapsModal
      style={{maxWidth: 1900, width: '94vw', borderRadius: 16, height: '90vh', maxHeight: 920}}
      width='90vw'
      closable={true}
      onCancel={() => {
        loadIssueTableData({page: 1, page_size: 10, issue_type: 'ai_landing_page_builder', uuid: getOttoV2Project?.uuid}, false, true);
        setOpenModal(false);
      }}
      visible={openModal}
      footer={null}
      hideCloseIcon={activeStep == 1}
      closeIcon={<FontAwesomeIcon style={{cursor: 'pointer'}} icon={faXmark} fontSize={24} color='#121212' onClick={() => setOpenModal(false)} />}
      centered
      maskStyle={{zIndex: 950}}
      wrapProps={{style: {zIndex: 950}}}
    >
      <div style={{display: 'flex', width: '100%', height: '100%', background: '#F9F9FB'}}>
        {steps[activeStep]}
      </div>
    </StyledTopicalMapsModal>
  );
});

const StyledTopicalMapsModal = styled(Modal)<{hideCloseIcon?: boolean}>`
   .ant-modal-content {
    border-radius: 16px;
    overflow: hidden;
    font-family: Inter;
    height: 100%;
    .ant-modal-body {
      padding: 0 !important;
      height: 100%;
    }
    .ant-modal-close {
      top: 6px !important;
    }
   }
`;
