// import {Button} from '@/components/common-components/components/index';
// import {Tooltip} from 'antd';
// import styles from './style.module.scss';
import {openUrl} from '@/utils/router';
import React, {useCallback} from 'react';
import GoogleButton from 'react-google-button';


interface Props{
  buttonText?:string;
}

export const GoogleLoginButtonRedirect:React.FC<Props> = () => {
  const handleGoogleRedirect = useCallback(
    () => {
      openUrl(`https://api.searchatlas.com/account/google/oauth/start?from_domain=${window.location.host}`, '_blank');
    },
    [],
  );

  return (
    <GoogleButton
      onClick={() => handleGoogleRedirect()}
    />
    // <Tooltip title={'Sign in with Google'} placement='top'>
    //   <Button
    //     className={styles.buttonGoogle}
    //     buttonType='primary'
    //     size='lg'
    //     onClick={handleGoogleRedirect}
    //   >
    //     <span className={styles.loginGoogleButton}>
    //       <img
    //         className={styles.loginGoogleIcon}
    //         src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
    //         alt='google button'
    //       />
    //     </span>
    //     {buttonText}
    //   </Button>
    // </Tooltip>
  );
};
