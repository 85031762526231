import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Dropdown, Form, Input, Menu, Select, Tabs, Tooltip, Checkbox} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faSortDown} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import styles from '../styles.module.scss';
import {useRouter} from 'next/router';
import {useStore} from '@/store/root-store';
import GoogleAnalyticsProjectModalContent from '../../report-builder/components/googleAnalyticsProjectModalContent';
import TextArea from 'antd/lib/input/TextArea';
import {snakeToCamel} from '@/utils/string';
import PhoneInput from 'react-phone-input-2';
import {LocationSelect} from '../../keyword-rank-tracker/add-new-website/location-select';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {formatCountryCode} from '../../content-ideas/drawer/languageUtil';
import Flag from '@/components/common-components/components/flag';
import {languages} from '../../landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer/languages';
import {notification} from '@/utils/notification-v2';
import {Button} from '@/components/common-components/v2';
import {integrationApi} from '@/api/gsc';
import {fieldOptionsMapping, fieldTypeMapping} from '../../otto-page-detail/Component/KnowledgeGraph/businessInfoForm';
interface Props {
  selectedAiModel: string;
  setSelectedAiModel: Dispatch<SetStateAction<string>>;
  knowledgeGraphSections: any[];
  form: any;
  serpLocation: any;
  setSerpLocation: Dispatch<SetStateAction<any>>;
  siteProperty: any;
  setSiteProperty: Dispatch<SetStateAction<any>>;
  gmbSelected: any;
  setGmbSelected: Dispatch<SetStateAction<any>>;
  gbpIdsArray: string[];
  setGbpIdsArray: Dispatch<SetStateAction<string[]>>;
  country: any;
  setCountry: Dispatch<SetStateAction<any>>;
  language: {language: string; code: string};
  setLanguage: Dispatch<SetStateAction<{language: string; code: string}>>;
}
const {Option} = Select;
const metaFieldLabels = {gbp_url: 'GBP URL'};
const metaFieldPlaceholders = {gbp_url: 'GBP URL'};
export const StepTwo: React.FC<Props> = observer(({
  selectedAiModel,
  setSelectedAiModel,
  knowledgeGraphSections,
  form,
  serpLocation,
  setSerpLocation,
  siteProperty,
  setSiteProperty,
  // gmbSelected,
  setGmbSelected,
  gbpIdsArray,
  setGbpIdsArray,
  country,
  setCountry,
  language,
  setLanguage,
}) => {
  const router = useRouter();
  const isPublicPage = router?.pathname?.includes('public');
  const {navBar,
    reportBuilder: {details: {getReportsGSCList, loadReportBuilderGAList, loadReportBuilderGSCList}},
    siteAuditorV2: {checkSpecificIDResponse},
    ottoV2Store: {knowledgeGraph, getOttoV2Project, getKnowledgeGraphData},
    gsc: {googleAnalytics: {gaDataModal, getGMBData, gaLoading, setGaLoading, getGMBSetting}},
    settings: {integration: {noGoogleUserProfiles}, customer: {profile: {whitelabelOtto, address}}},
  } = useStore('');
  const [locationError, setLocationError] = useState<boolean>(false);
  const [knowledgeGraphModalLoader, setKnowledgeGraphModalLoader] = useState(false);
  const [isVisibleGsc, setIsVisibleGsc] = useState(false);
  const [tabKeys, setTabKeys] = useState('business_section');
  const [businesses, setBusinessData] = useState([]);
  const aiModelOptions = [{label: 'OpenAI (GPT)', value: 'openai'}, {label: 'Anthropic (Claude)', value: 'anthropic'}];
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [multiSelectedGbp, setMultiSelectedGbp] = useState<any>([]);
  const [languageCode, setLanguageCode] = useState('');

  const getKnowledgeGraphDataModal = async () => {
    if (getOttoV2Project?.knowledgeGraphId) {
      setKnowledgeGraphModalLoader(true);
      await getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
      setKnowledgeGraphModalLoader(false);
    }
  };
  const getGscInitialData = async () => {
    setGaLoading(true);
    const res = await loadReportBuilderGAList();
    if (res) {
      if (gaDataModal?.saProjectId) {
        const response = await checkSpecificIDResponse(gaDataModal?.saProjectId);
        if (response?.gscContext) {
          setCountry([null, undefined].includes(response?.gscContext?.countryCode) ? (address?.defaultLocation?.toUpperCase() || 'Worldwide') : response?.gscContext?.countryCode?.toUpperCase() || 'Worldwide');
          setSiteProperty(response?.gscContext?.propertyUrl);
        }
      }
    }
    setGaLoading(false);
  };
  useEffect(() => {
    getKnowledgeGraphDataModal();
  }, [getOttoV2Project?.knowledgeGraphId]);

  const onLanguageChangeHandler = async (value, option) => {
    if (option.value) {
      try {
        setLanguage({language: option.country, code: option.value});
      } catch (err) {
        notification.error('', err);
      }
    }
  };

  useEffect(() => {
    if (!getGMBData) {
      getGMBSetting();
    }
    let locations = [];
    getGMBData?.forEach(business => {
      business?.businessAccounts?.forEach(account => {
        if (account?.locations?.length) {
          const updatedLocation = account?.locations?.map(location => ({...location, email: business?.email}));
          locations = [...locations, ...updatedLocation];
        }
      });
    });
    const locationWithPlaceId = locations?.filter(item => item?.placeId);
    const locationWithoutPlaceId = locations?.filter(item => !item.placeId);
    setBusinessData([...locationWithPlaceId, ...locationWithoutPlaceId]);
  }, [getGMBData?.length]);
  useEffect(() => {
    getGscInitialData();
    country || country == '' && loadReportBuilderGSCList(country);
  }, []);
  const onModalPropertyChange = value => {
    setSiteProperty(value);
    setIsVisibleGsc(!isVisibleGsc);
  };

  const handleSelectedLocation = e =>{
    if (!multiSelectedGbp.some(value => value?.locationId == e?.locationId)) {
      setMultiSelectedGbp([...multiSelectedGbp, e]);
    } else {
      setMultiSelectedGbp(multiSelectedGbp.filter(val => val.locationId != e.locationId));
    }
    const newArray = [...gbpIdsArray];
    const index = newArray.indexOf(e.locationId);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(e.locationId);
    }
    setGbpIdsArray(newArray);
    setGmbSelected(e);
  };
  const onLocationChange = value => {
    setSerpLocation(value.value);
    if (value.value) locationError && setLocationError(false);
  };
  const fields = (placeholder, defaultValue, fieldType, fieldName, options, form, style) => {
    const fieldsObj = {
      'text': <StyledInput placeholder={placeholder} defaultValue={defaultValue} style={style ? style : {}}/>,
      'text_area': <TextArea defaultValue={defaultValue} className='text-area' placeholder={placeholder} style={style ? style : {}}/>,
      'number': <StyledInput placeholder={placeholder} defaultValue={defaultValue} type='number' min={fieldName === 'number_of_employees' ? 0 : null} style={style ? style : {}}/>,
      'phone': <StyledPhoneInput inputClass='support-phone-input' country='us' value={defaultValue} width={style?.width ? style.width : ''}/>,
      'email': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} type='email' style={style ? style : {}}/>,
      'url': <Input defaultValue={defaultValue} className='email-input' placeholder={placeholder} style={style ? style : {}}/>,
      'dropdown': <StyledSelect
        onChange={value => {
          const obj = {};
          obj[fieldName] = value;
          form.setFieldsValue(obj);
        }}
        defaultValue={defaultValue}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
        placeholder={placeholder}
        showSearch
        getPopupContainer={trigger => trigger.parentNode}
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
      'geolocation-search': <LocationSelect
        variant='grey'
        placeholderTextColorChange={true}
        width={'100%'}
        dropDownBottomValue={1}
        height='44px'
        borderRadius='5px'
        style={{border: locationError ? '1px solid red' : ''}}
        marginLeft='0px'
        getPopupContainer={trigger => trigger.parentNode}
        suffixIcon={<FontAwesomeIcon color='#fff' icon={faCaretDown}/>}
        showSearch
        isKrtLocationSelect
        value={serpLocation}
        onChange={onLocationChange}
        excludeWorldwide
        // setSerpLocationId={setSerpLocationId}
        setSelectedCountryCode={() => ('')}
        isKnowledgeGraph
      />,
      'language_code': <StyledSelect
        onChange={value => {
          setLanguageCode(value as string);
        }}
        defaultValue={defaultValue}
        value={languageCode}
        suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#000000' />}
        placeholder={placeholder}
        getPopupContainer={trigger => trigger.parentNode}
        showSearch
        filterOption={(inputValue, option) =>
          option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
        }
      >
        {options?.map((item, index) => (
          <Option key={index} value={item?.value}>
            {item?.label}
          </Option>
        ))}
      </StyledSelect>,
    };
    return fieldsObj[fieldType];
  };

  const onConnectGSC = () => {
    window.open(integrationApi.getGoogleConnectUrl('?is_open=true'), '_self');
  };
  const onConnectGBP = () => {
    window.open(integrationApi.getGoogleMyBusinessConnectUrl('?is_open=true'), '_self');
  };
  return ( <>
    <Form.Item style={{marginBottom: 8, alignItems: 'center'}} name='gsc_property' >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <p style={{fontSize: 12, margin: '0px', color: '#4E5156', minWidth: '136px'}}>GSC Property</p>
        {!gaLoading ?
          <Dropdown
            trigger={['click']}
            visible={isVisibleGsc}
            onVisibleChange={() => setIsVisibleGsc(!isVisibleGsc)}
            overlay={getReportsGSCList?.length ?
              <GoogleAnalyticsProjectModalContent
                sitePropertiesList={getReportsGSCList || []}
                isOtto={true}
                countryCode={country}
                onProjectChange={onModalPropertyChange}
                selectedGSCProject={siteProperty || 'Select a property'}
                selectedCountry={country}
                title={'Google Search Console Domains'}
                actionBeforeDrawerOpen={() => setIsVisibleGsc(false)}
              /> : <MenuWrapper>
                <div style={{margin: '10px'}}>
                  <div>{noGoogleUserProfiles ? 'No Google Search Console sites found.' : 'Activate your imported GSC Sites'}</div>
                  {noGoogleUserProfiles ? <Button style={{marginTop: '10px', marginLeft: '0px'}}
                    variant='ghost'
                    onClick={onConnectGSC}
                    color='blue'>{'+ Connect GSC account'}</Button> : <Button style={{marginTop: '10px', marginLeft: '0px'}}
                    variant='ghost'
                    onClick={() => navBar.setIsActivatePropertiesDrawerOpen()}
                    color='blue'>{'+ Activate GSC Sites'}</Button>}
                </div>
              </MenuWrapper>
            }
            overlayStyle={{zIndex: 11, background: '#fff', width: getReportsGSCList?.length ? '400px' : '364px', borderRadius: '8px'}}>
            <SelectGSCStyled onClick={() => setIsVisibleGsc(true)} >
              <div className='gsc-property'>
                {siteProperty? <img src='/img/icon/GSC_Icon.png' width={26} /> : <></>}
                {siteProperty || 'Select a property'}
              </div>
              <FontAwesomeIcon color='#121212' icon={faCaretDown} />
            </SelectGSCStyled>
          </Dropdown> : <SkeletonHorizontalLoaderGray lightLoader height={'38px'} skeletonHeight={'12px'} width={200}/>}
      </div>
    </Form.Item>
    <Form.Item style={{marginBottom: 8, alignItems: 'center'}} name='google_business' >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <p style={{fontSize: 12, margin: '0px', color: '#4E5156', minWidth: '136px'}}>Google Business</p>
        <Dropdown
          trigger={['click']} visible={showGbpDropdown} onVisibleChange={e => setShowGbpDropdown(e)}
          overlay={<MenuWrapper>
            <GoogleBusinessMenu selectedKeys={gbpIdsArray}>
              {businesses.map((item, index) => (
                <Menu.Item
                  key={index}
                >
                  <Checkbox key={item?.locationId} value={item} checked={multiSelectedGbp.some(value => value?.locationId == item?.locationId)} onChange={e=> handleSelectedLocation(e.target.value)}>
                    {item?.title}
                  </Checkbox>
                </Menu.Item>
              ))}
            </GoogleBusinessMenu>
            {!businesses?.length && <div style={{margin: '10px'}}>
              <div>No Google Businesses Found.</div>
              <Button style={{marginTop: '10px', marginLeft: '0px'}}
                variant='ghost'
                onClick={onConnectGBP}
                color='blue'>{'+ Connect GBP profile'}</Button>
            </div>}
          </MenuWrapper>
          }
          overlayStyle={{background: '#fff', width: '300px', borderRadius: '8px'}}>
          <SelectGSCStyled>
            <div style={{display: 'flex', alignItems: 'center', gap: '8px', lineHeight: 'normal'}}>
              <div style={{padding: '5px 0px'}}>
                {multiSelectedGbp.length >= 1 ? <div><img src='/img/gmbIcon.svg' style={{width: '24px', height: '23px', marginRight: '5px'}} alt='icon' />  {multiSelectedGbp?.length} Selected</div> : <div>{'Select a business'}</div>}
              </div>
            </div>
            <FontAwesomeIcon color='#121212' icon={faCaretDown} /></SelectGSCStyled>
        </Dropdown>


      </div>
    </Form.Item>
    <Form.Item style={{marginBottom: 8, alignItems: 'center'}} name='business_description' >
      <div style={{display: 'flex', alignItems: 'flex-start'}}>
        <p style={{fontSize: 12, margin: '0px', color: '#4E5156', minWidth: '136px'}}>Business Description</p>
        <TextArea
          style={{borderRadius: '8px', height: '86px', resize: 'none', border: '1px solid #D2D2D2'}}
          placeholder='Provide a company description for AI to write better tailored content and give better recommendations' />
      </div>
    </Form.Item>
    <Form.Item name='language_code' style={{marginBottom: 8}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <p style={{fontSize: 12, margin: 0, color: '#4E5156', minWidth: '136px'}}>Language</p>
        <Tooltip
          overlayInnerStyle={{width: language?.code === 'None' ? '380px' : '', borderRadius: language?.code === 'None' ? '6px' : ''}}
          placement={language?.code === 'None' ? 'topLeft' : 'top'}
          title={language?.code === 'None' ? `${whitelabelOtto} will automatically identify the language of your webpage and generate content and optimizations accordingly.` :
            language?.code === 'en' ? language.language :
              `AI Writer language changed to ${language?.language}`}
        >
          <LanguageSelect
            variant='grey'
            showSearch
            value={language?.language}
            width={'100%'}
            height={'36px'}
            onChange={onLanguageChangeHandler}
            suffixIcon={<FontAwesomeIcon icon={faCaretDown} color={'#121212'} />}
            filterOption={(input, option) =>
              option.country.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onMouseEnter={()=><Tooltip title={'Download latest version of content from the import URL.'} placement='bottom' ></Tooltip>}
          >
            {
              languages.map((lang, index) => {
                return (
                  <Option disabled={false} key={index} country={lang.language} title={' '} value={lang.code}>
                    <Flag width={12} code={formatCountryCode(lang.language, lang.code)} style={{marginRight: '5px'}}></Flag>
                    {lang.language}
                  </Option>
                );
              })
            }
          </LanguageSelect>
        </Tooltip>
      </div>
    </Form.Item>
    <Form.Item name='crawl_frequency' style={isPublicPage ? {pointerEvents: 'none', filter: 'opacity(0.5)', marginBottom: 8} : {marginBottom: 8}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <p style={{fontSize: 12, margin: 0, color: '#4E5156', minWidth: '136px'}}>AI Model</p>
        <StyledSelect
          suffixIcon={<FontAwesomeIcon icon={faSortDown} color='#121212' fontSize={14}/>}
          style={{marginTop: 0, height: '48px'}}
          selectHeight={'36px'}
          selectLineHeight={'36px'}
          className={styles.selectStyle}
          defaultValue={selectedAiModel}
          showSearch
          disabled={isPublicPage}
          tabIndex={-1}
          placeholder='Select Crawl Frequency'
          optionFilterProp='children'
          onChange={(value: string) => {
            setSelectedAiModel(value);
          }}
          value={selectedAiModel}
        >
          {aiModelOptions.map((item, index) => {
            return (
              <Option key={index} value={item.value}>{item.label}</Option>
            );
          })}
        </StyledSelect>
      </div>
    </Form.Item>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
      <p style={{fontSize: 12, margin: 0, color: '#4E5156', minWidth: '136px'}}>Knowledge Graph</p>
      {knowledgeGraphModalLoader ? <>loading knowledge graph</> : <KnowledgeGraphWrapper className='kg-wrapper'>
        <Tabs activeKey={tabKeys} onChange={keys => setTabKeys(keys)}>
          {knowledgeGraphSections?.map(item => {
            const selectedItems = item?.value?.filter(item => item.name !== 'logo' && item.name !== 'image');
            return (
              (item?.name !== 'top_pages' && item?.name !== 'images') ? (
                <Tabs.TabPane tab={item?.label} key={item?.name} forceRender>
                  {selectedItems?.map((innerItem, index) => {
                    return (
                      <div key={index} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 8, gap: 16}}>
                        <p style={{fontSize: 12, margin: 0, color: '#4E5156', minWidth: '126px', marginTop: 4}}>{metaFieldLabels[innerItem?.name] || innerItem?.label}</p>
                        <Form.Item
                          style={{marginBottom: 0, alignItems: 'center', width: '-webkit-fill-available', alignSelf: 'center', flexGrow: 1}}
                          name={['knowledgeGraph', innerItem.name]}
                          key={index}
                          rules={
                            innerItem.type === 'url' ? [
                              {type: 'url', message: 'URL must be valid'},
                            ] : innerItem?.name === 'number_of_employees' ? [{
                              validator: (_, value) => {
                                if (Number(value) > 2147483647) {
                                  return Promise.reject(new Error('Value should be less than or equal to 2147483647'));
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }] : undefined
                          }
                        >
                          {fields(metaFieldPlaceholders[innerItem?.name] || innerItem.label,
                            knowledgeGraph[item?.name === 'iso_6523_code' ? 'iso6523Code' : snakeToCamel(item.name)],
                            fieldTypeMapping[innerItem?.name] || innerItem?.type,
                            innerItem.name, fieldOptionsMapping[innerItem?.name] || innerItem?.options, form, {
                              borderRadius: innerItem?.type === 'text_area' ? '6px' : '',
                              border: innerItem?.type === 'text_area' ? '1px solid #D2D2D2' : '',
                              width: innerItem?.type === 'phone' ? 'inherit' : innerItem?.type === 'dropdown' ? '-webkit-fill-available' : '',
                            })}
                        </Form.Item>
                      </div>
                    );
                  })}
                </Tabs.TabPane>) : (<></>)
            );
          })}
        </Tabs>
      </KnowledgeGraphWrapper>}
    </div>
  </>);
});

const StyledSelect = styled(Select)<{selectHeight?: string | number; selectLineHeight?: string | number}>`
.ant-select-dropdown{
  top: 34px !important;
}
.ant-select-selector {
  border-radius: 6px !important;
  height: ${p => p?.selectHeight ? p?.selectHeight : ''} !important;
  .ant-select-selection-item {
    line-height: ${p => p.selectLineHeight ? p?.selectLineHeight : ''} !important;
  }
}

.ant-select-arrow {
  top: 40%;
}
  @media screen and (max-width: 390px) {
    .ant-select-arrow {
      top: 26%;
    } 
  }
`;
const SelectGSCStyled = styled.div`
cursor: pointer;
align-items: center;
justify-content: space-between;
display: flex;
width: -webkit-fill-available;
flex-grow: 1;
padding: 2px 12px;
border: 1px solid #e0e0e0;
border-radius: 8px;
height: 36px;
  .gsc-property{
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
const KnowledgeGraphWrapper = styled.div`
  width: 364px;
  height: 352px;
  border-radius: 8px;
  border: 1px solid #D2D2D2;
  .ant-tabs {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        padding-left: 10px !important;
        padding-right: 10px !important;
        .ant-tabs-nav-list {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          .nt-tabs-ink-bar{
            background-color: #2D6CCA !important;
          }
          .ant-tabs-tab {
            font-family: 'Inter', sans-serif;
            font-size: 12px;
            font-weight: 400;
            color: #4E5156;
            &.ant-tabs-tab-active .ant-tabs-tab-btn {
              color: #121212;
              font-weight: 500;
              font-size: 12px;
            }
          }
        }
      }
    }
    .ant-tabs-nav .ant-tabs-ink-bar {
      height: 5px;
      border-radius: 4px 4px 0px 0px;
    }
    .ant-tabs-content-holder {
      padding: 0px 12px;
      overflow: hidden;
      height: 290px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #F9F9FB;
      }

      ::-webkit-scrollbar-thumb {
        background: #4E515680;
        border-radius: 5px;
      }
    }
  }
`;
const StyledInput = styled(Input)`
  border-radius: 8px;

`;
const StyledPhoneInput = styled(PhoneInput)<{width?: string}>`
  .form-control {
    width: ${p => p?.width ? p.width : ''};
  }
  .country-list {
    max-height: 145px !important;
  }
`;
const MenuWrapper = styled.div`
  height: fit-content;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
`;
const GoogleBusinessMenu = styled(Menu)`
  max-height: 500px;
  overflow-y: auto;
  border-radius: 8px;
  background-color: #FFFFFF;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;
const dropdownColors = {
  grey: {
    color: '#333',
    backgroundColor: '#F9F9FB',
    listItemsBackgroundColor: '#F9F9FB',
    borderColor: '#F9F9FB',
  },
};
const LanguageSelect = styled(Select)<{variant?: string; borderRadius?: string; width?: string; height?: string; marginLeft?: string}>`
  color:${ p => dropdownColors[p.variant].color};
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
  .ant-select-selector {
    /* max-width:180px !important; */
    min-width: ${p => p.width ? p.width : '168px'} !important;
    width: ${p => p.width ? p.width : '168px'} !important;
    height: ${p => p.height ? p.height : '38px'} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: #E8E8E8 !important;
    padding: 1px 8px 2px 11px !important;
    cursor: pointer !important;
  }
  .ant-select-selection-search {
    padding: 0px !important;

    .ant-select-selection-search-input{
      height: 32px !important;
    }
  }

 .ant-select-arrow {
  color:${ p => dropdownColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: ${ p => dropdownColors[p.variant].borderColor} !important
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

`;
