import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {Collapse, Modal} from 'antd';
import {notification} from '@/utils/notification-v2';

import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {Button} from '@/components/common-components/v2';
import {ChevronDown, ChevronUp} from 'react-feather';
import {faPlus, faPen} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import FreezeWrapper from './freezTooltip';

interface Props {
  data: any;
  isEdit: boolean;
  onConfirm: any;
}

export const CustomHtmlPopover = observer(({data, isEdit, onConfirm}: Props) => {
  const [visible, setVisible] = useState(false);
  const {ottoV2Store: {
    getOttoV2Project,
  }} = useStore('');
  const [headerContent, setHeaderContent] = useState('');
  const [bodyContent, setBodyContent] = useState('');
  const domain = getOttoV2Project?.hostname || getSingleUrlParam('domain');
  const [activeKeys, setActiveKeys] = useState([1, 2]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setHeaderContent(data?.customHtmlHead || '');
    setBodyContent(data?.customHtmlBody || '');
  }, [data]);

  const onCancel = () => {
    setVisible(false);
    setHeaderContent(data?.customHtmlHead || '');
    setBodyContent(data?.customHtmlBody || '');
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await onConfirm(data?.id, headerContent, bodyContent);
      notification.success('Success', 'Deployed Successfully');
      setLoading(false);
      setVisible(false);
    } catch (e) {
      setLoading(false);
      notification.error('Failure', 'Deployed Failure');
    }
  };

  return (
    <FreezeWrapper>
      <StyledModal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        centered
        width={680}
        style={{
          borderRadius: '12px',
          border: '1px solid #E8E8E8',
          background: '#F9F9FB',
          padding: '13px 9px 13px 9px',
        }}
      >
        <ContentWrapper>
          <Title>{isEdit ? 'Edit Custom HTML' : 'Add Custom HTML'}</Title>
          <SubHeadingWrapper>
            <SubHeading color='#4E5156'>Page:</SubHeading>
            <AnchorSubHeading href={addProtocolToDomainHttps(`${domain}${data?.path}`)} target='_blank'>{`${domain}${data?.path}`}</AnchorSubHeading>
          </SubHeadingWrapper>
          <CollapseStyled
            ghost
            expandIconPosition='right'
            activeKey={activeKeys}>
            <CollapsePanel style={{marginTop: '26px'}} header={(
              <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <span>Header HTML</span>
                <><ChevronUp className='chevron-up-icon'
                  onClick={() => setActiveKeys(prev => prev.filter(key => key != 1))}
                /><ChevronDown className='chevron-down-icon' onClick={() => setActiveKeys(prev => [...prev, 1])}/></>
              </div>
            )} key={1}>
              <CustomTextarea variant='light' minHeight={125} value={headerContent} onChange={e => setHeaderContent(e?.target?.value)} onKeyDown={() => {}} />
            </CollapsePanel>
            <CollapsePanel style={{marginTop: '26px'}} header={(
              <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
                <span>Body HTML</span>
                <><ChevronUp className='chevron-up-icon'
                  onClick={() => setActiveKeys(prev => prev.filter(key => key != 2))}
                /><ChevronDown className='chevron-down-icon' onClick={() => setActiveKeys(prev => [...prev, 2])}/></>
              </div>
            )} key={2}>
              <CustomTextarea variant='light' minHeight={125} value={bodyContent} onChange={e => setBodyContent(e?.target?.value)} onKeyDown={() => {}} />
            </CollapsePanel>
          </CollapseStyled>
          <Banner>
            <FontAwesomeIcon color='#2D6CCA' icon={faCircleInfo}/>
            <div>Custom HTML will be deployed instantly to your page.</div>
          </Banner>
          <div style={{display: 'flex', justifyContent: 'end', marginTop: '27px'}}>
            <ButtonStyled onClick={onCancel} isCancel={true}>Cancel</ButtonStyled>
            <ButtonStyled loading={loading} onClick={onSubmit} background='#2D6CCA' width='139px' height='44px'>{isEdit ? 'Edit & Deploy' : 'Add & Deploy'}</ButtonStyled>
          </div>
        </ContentWrapper>
      </StyledModal>
      <PopoverButton onClick={() => setVisible(true)}>
        <FontAwesomeIcon
          style={{cursor: 'pointer'}}
          icon={(data?.customHtmlHead || data?.customHtmlBody) ? faPen : faPlus}
          fontSize={12}
          color='#4E5156'
        />&nbsp;
        <div style={{fontSize: '13px', color: '#121212'}}>{(data?.customHtmlHead || data?.customHtmlBody) ? 'Edit Custom HTML' : 'Add Custom HTML'}</div>
      </PopoverButton>
    </FreezeWrapper>
  );
});

const StyledModal = styled(Modal)`
  .ant-modal-content {
    box-shadow: none;
    background-color: #F9F9FB;
  }
`;

const AnchorSubHeading = styled.a`
  color: #2D6CCA;
  cursor: pointer;
`;

const CollapseStyled = styled(Collapse)`
  .ant-collapse-item {
    margin-bottom: 20px;
    .ant-collapse-arrow {
      display: none !important;
    }
    .ant-collapse-header {
      padding: 0px;
      margin-bottom: 9px;
      .chevron-up-icon {
        display: none;
      }
      .ant-collapse-arrow {
        width: 17px;
        margin-right: 5px;
      }
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0px;
      }
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
          .chevron-up-icon {
            display: block;
          }
          .chevron-down-icon {
            display: none;
          }
    }
  }
`;

const PopoverButton = styled.div`
  width: fit-content;
  background-color: #F9F9FB;
  height: 22px;
  display: flex;
  align-items: center;
  padding: 5px 9px;
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  cursor: pointer;
  @media screen and (max-width: 1100px) {
    height: 25px;
    white-space: nowrap;
  }
`;

const CollapsePanel = styled(Collapse.Panel)`
.ant-collapse-content-box {
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  overflow: hidden;
}
  .ant-collapse-content-box * {
    background-color: #fff !important;
  }
  span textarea, div textarea, span textarea:first-child, div textarea:first-child {
    background-color: #fff !important;
    &.number-textarea {
      color: #A3A4A4 !important;
    }
  }
`;

export const ButtonStyled = styled(Button)<{isCancel?: true; background?: string; width?: string; height?: string; gap?: string}>`
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${p => p?.gap ? p?.gap : '10px'};
  font-size: 14px;
  font-style: normal;
  max-width: ${p => p?.isCancel ? 'fit-content' : p?.width ? p?.width : '187px'};
  height: ${p => p?.height ? p?.height : '44px'};
  font-weight: ${p => p?.isCancel ? '400' : '600'};
  background: ${p => p?.isCancel ? 'transparent' : p?.background ? p?.background : '#2D6CCA'};
  color: ${p => p?.isCancel ? '#4E5156' : ''};
  border-radius: 8px !important;

  &:hover {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
  &:focus {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
  &:disabled {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const SubHeadingWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const SubHeading = styled.div<{color: string}>`
  color: ${p => p?.color};
`;

const Banner = styled.div`
  background: rgba(45, 108, 202, 0.2);
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
