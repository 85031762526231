import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import React, {useEffect, ReactNode, useState} from 'react';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faArrowRight, faGear} from '@fortawesome/pro-solid-svg-icons';
import {Button} from '@/components/common-components/v2';
import {Dropdown, Popover, Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {faCircleCheck, faCircleXmark} from '@fortawesome/pro-duotone-svg-icons';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components/components';
import BlockListModal from '../createCampaignModal/sections/blockListModal';
import {faBan, faEllipsisVertical} from '@fortawesome/pro-regular-svg-icons';
import {toJS} from 'mobx';
import AddEmailModal from './addEmailModal/addEmailModal';

interface CampaignHeaderProps {
  isBackIcon: boolean;
  pageHeading: string;
  pageSubHeading: string;
  showCreateBtn: boolean;
  showManage: boolean;
  campaignSettings?: ReactNode;
  detailOverview?: ReactNode;
  historyCard?: ReactNode;
  opportunities?: ReactNode;
  templatesPage?: boolean;
  showArrowOnCreateBtn?: boolean;
  currentPage?: string;
  showBlockListModal?: boolean;
  isMainPage?: boolean;
  isInOTTO?: boolean;
  setCampaignsId?: (value: string) => void;
}

export const CampaignHeader = observer(({
  isBackIcon,
  pageHeading,
  pageSubHeading,
  showCreateBtn,
  showManage,
  campaignSettings,
  detailOverview,
  historyCard,
  templatesPage=false,
  showArrowOnCreateBtn=false,
  currentPage,
  opportunities,
  showBlockListModal,
  isInOTTO,
  setCampaignsId,
  isMainPage}: CampaignHeaderProps) => {
  const {campaigns: {
    getCampaignsCustomerEmails,
    updateDailyLimit,
    campaignsCustomerEmails,
    setCreateModalVisible,
    setCreateTempModalVisible,
    deleteConnectedEmail,
    getDeletingConnectedEmailsIds,
    // addNewMail,
  }, settings: {customer: {profile: {quotaUtilization}}}} = useStore('');

  const router = useRouter();
  const [blackListModalVisible, setBlackListModalVisible] = useState(false);
  const [addEmailModalVisible, setAddEmailModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState('stepOne');

  useEffect(() => {
    if (currentPage == 'main-page') {
      getCampaignsCustomerEmails();
    }
  }, []);

  const renderHeading = <>
    {
      pageHeading ? pageHeading :
        <>
          <SkeletonHorizontalLoaderGray skeletonHeight={8} height={32} width={70}/>
        </>
    }
  </>;

  const [emailLimits, setEmailLimits] = useState([]);

  useEffect(() => {
    campaignsCustomerEmails?.results && setEmailLimits([...toJS(campaignsCustomerEmails?.results)]);
  }, [campaignsCustomerEmails]);

  const handleDropdownClose = async open => {
    if (!open) {
      toJS(campaignsCustomerEmails?.results)?.map(async (campaignEmail, index) => {
        emailLimits?.map(async email => {
          if (campaignEmail?.id == email?.id) {
            if (campaignEmail?.dailyLimitByEmail != email?.dailyLimitByEmail) {
              await updateDailyLimit({
                id: email?.id,
                daily_limit_by_email: +(emailLimits[index]?.dailyLimitByEmail),
              });
              await getCampaignsCustomerEmails();
              setEmailLimits(toJS(campaignsCustomerEmails?.results));
            }
          }
        });
      });
    }
  };

  const manageOverlay = <ManageDropdown>
    <QuoutaConatiner>Connected email accounts: {quotaUtilization?.ke?.allowedOutreachConnectedEmails?.consumed || 0} of {quotaUtilization?.ke?.allowedOutreachConnectedEmails?.total || 0}</QuoutaConatiner>
    <DropdownHeader>
      <EmailColHead>EMAIL</EmailColHead>
      <StatusColHead>STATUS</StatusColHead>
    </DropdownHeader>
    <DropdownBody>
      {campaignsCustomerEmails?.results?.map((item, index) => (
        <DropdownBodyRow key={index}>
          <Email>{item?.emailAddress}</Email>
          <StatusWrapper>
            <StatusValue><FontAwesomeIcon fontSize={18} icon={item?.isLinked ? faCircleCheck : faCircleXmark} color={item?.isLinked ? 'rgb(42, 193, 85)' : 'rgba(244, 67, 67)'}/>{item?.isLinked ? 'Connected' : 'Failed'}</StatusValue>
            <Popover
              trigger='hover'
              overlayStyle={{width: 150, zIndex: 9999}}
              placement='bottom'
              content={
                <div style={{display: 'flex'}}>
                  {getDeletingConnectedEmailsIds.includes(item?.id) && <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: 'red'}} spin />} />}
                      &nbsp;&nbsp;
                  <div onClick={e => {
                    e.stopPropagation();
                    deleteConnectedEmail(item?.id);
                  }} style={{color: 'red', cursor: 'pointer'}}>Delete</div>
                </div>
              }>
              <FontAwesomeIcon icon={faEllipsisVertical}/>
            </Popover>
          </StatusWrapper>
        </DropdownBodyRow>
      ))}
      <AddAnotherRow>
        {/* <div onClick={() => window.open(CAMPAIGN_API.getAddNewMailUrl(), '_self')}>+ Add another Google Mail</div> */}
        <div onClick={() => setAddEmailModalVisible(true)}>+ Add new email</div>
      </AddAnotherRow>
    </DropdownBody>
  </ManageDropdown>;

  return (
    <HeaderContainer>
      <HeaderWrapper>
        {
          !isInOTTO ? <QuotaWrapper>
            {isMainPage ? <QuotaInfoDetail consumedData={quotaUtilization?.ke?.allowedOutreachCampaigns?.consumed} totalData={quotaUtilization?.ke?.allowedOutreachCampaigns?.total} heading={'Campaigns'} description='Quota Utilization Campaigns' /> : ''}
            <QuotaInfoDetail consumedData={quotaUtilization?.ke?.allowedOutreachSentEmails?.consumed} totalData={quotaUtilization?.ke?.allowedOutreachSentEmails?.total} heading={'Emails sent'} description='Emails sent' />
          </QuotaWrapper> : <></>
        }
        {
          isInOTTO ? <SubHeadingOtto>{isBackIcon && <BackArrowIcon className={isInOTTO ? 'otto': ''} icon={faArrowLeft} onClick={() => isInOTTO ? setCampaignsId('') : router.push('/outreach/campaigns')} style={{cursor: 'pointer'}}/>}<span>Back to All Campaigns</span>{campaignSettings}</SubHeadingOtto>:
            <Heading>{isBackIcon && <BackArrowIcon icon={faArrowLeft} onClick={() => router.push('/outreach/campaigns')} style={{cursor: 'pointer'}}/>}<span>{renderHeading}</span>{campaignSettings}</Heading>
        }
        {
          isInOTTO ? <HeadingOtto>{renderHeading}</HeadingOtto> : <></>
        }
        {pageSubHeading && <SubHeading>{pageSubHeading}</SubHeading>}
        {detailOverview && detailOverview}
        {historyCard && historyCard}
        {opportunities && opportunities}
        {showCreateBtn ? <ButtonWrapper>
          <ButtonStyled onClick={() => templatesPage ? setCreateTempModalVisible(true) : setCreateModalVisible(true)}>{templatesPage ? 'Create new template' : 'Create new campaign'} {showArrowOnCreateBtn && <FontAwesomeIcon icon={faArrowRight}/>}</ButtonStyled>
        </ButtonWrapper> : null}
        <div className='bottom-section'>
          {showManage ? <ManageWrapper>
            <Dropdown onVisibleChange={handleDropdownClose} overlay={manageOverlay} trigger={['click']}>
              <ManageText><FontAwesomeIcon icon={faGear}/>{' '}Manage connected emails</ManageText>
            </Dropdown>
          </ManageWrapper> : null}
          {showBlockListModal ?
            <BlackList onClick={() => setBlackListModalVisible(true)}>
              <FontAwesomeIcon icon={faBan} />{' '}Global blacklist
            </BlackList> :
            null}
        </div>
      </HeaderWrapper>
      <BlockListModal blackListModalVisible={blackListModalVisible} setBlackListModalVisible={setBlackListModalVisible} />
      <AddEmailModal visible={addEmailModalVisible} setVisible={setAddEmailModalVisible} currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </HeaderContainer>
  );
});

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .bottom-section {
    display: flex;
    align-items: center;
    gap: 34px;
    margin-top: 18px;
  }
`;
const HeaderWrapper = styled.div`
width: 100%;
`;
const QuotaWrapper = styled.div`
display: flex;
gap: 40px;
`;
const Heading = styled.div`
  color: #FFFFFF;
  margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  span, svg {
    font-weight: 700;
    font-size: 24px;
  }
`;
const HeadingOtto = styled.div`
  margin-top: 5px;
  color: black;
  position: relative;
  font-weight: 600;
  font-size: 18px;

`;
const SubHeadingOtto = styled.div`
  color: #4E5156;
  margin-top: 5px;
  font-family: Inter;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  display: flex;
  justify-content: space-between;
  span, svg {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
  }
`;
const SubHeading = styled.div`
  max-width: 455px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #E8E8E8;
  margin-top: 15px;
`;
const BackArrowIcon = styled(FontAwesomeIcon)`
position: absolute;
left: -30px;
top: 6px;
  &.otto {
    top: 9px;
  }
`;
const ButtonWrapper = styled.div`
margin-top: 23px;
`;
const ButtonStyled = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #2D6CCA !important;
  margin: 0px 0px;
  color: #fff;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
`;
const ManageWrapper = styled.div`

`;
const ManageText = styled.span`
color:#34AEF3;
cursor: pointer;
`;
const ManageDropdown = styled.div`
background-color: #FFF;
border-radius: 12px;
border: 1px solid #E8E8E8;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 10px;
width: 450px;
`;
const EmailColHead = styled.div``;
const StatusColHead = styled.div``;
const DropdownHeader = styled.div`
  display: flex;
  background-color: #F7F7F7;
  padding: 12px 12px;
  border-radius: 8px;
  ${EmailColHead} {
    width: 65%;
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
  ${StatusColHead} {
    width: 20%;
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }
  `;
const DropdownBodyRow = styled.div``;
const Email = styled.div``;
const StatusWrapper = styled.div`
text-align: center`;
const StatusValue = styled.div`
  margin-right: 28px;
`;
const DropdownBody = styled.div`
${DropdownBodyRow} {
  display: flex;
  align-items: center;
    padding: 8px 12px;
    ${Email} {
      width: 147%;
      color: #121212;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    ${StatusWrapper} {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${StatusValue} {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      & > svg {
        cursor: pointer;
      }
    }
}
`;

const AddAnotherRow = styled.div`
display: flex;
padding: 8px 12px;
div {
  color: #2D6CCA;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
`;

const BlackList = styled.div`
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #34AEF3;
`;

const QuoutaConatiner = styled.div`
  margin-left: 7px;
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #4E5156;
`;
