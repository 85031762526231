import React, {useState} from 'react';
import styled from 'styled-components';
import {Form, Input} from 'antd';
// import {faTrash} from '@fortawesome/pro-regular-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@/components/common-components/v2';
import ImagePicker from './imagePicker';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {notification} from '@/utils/notifications';

const ImagesForm = ({images, setImages, youtubeUrl, setYoutubeUrl, youtubeUrlError, setYoutubeUrlError, logo, setLogo, imagesError, style = {}, width, maxLimit, photosSectionTitle, logoPlaceholder, showUrlSection = true}: any) => {
  const [showImageActions, setShowImageActions] = useState(null);
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /^(https:\/\/)?(www\.)?(youtube\.com|youtu\.be)(\/.*)?$/;

  const handleAddField = () => {
    const newId = youtubeUrl?.length > 0 ? youtubeUrl[youtubeUrl.length - 1].id + 1 : 1;
    const newItem = {id: newId, value: ''};
    const updatedList = [...youtubeUrl, newItem];
    setYoutubeUrl(updatedList);
  };

  const handleChange = (value, id) => {
    setYoutubeUrl(youtubeUrl?.map(item => {
      if (item?.id === id) {
        return {...item, value};
      }
      return item;
    }));
    if (urlRegex.test(value) || !value?.length) {
      setYoutubeUrlError(null);
    } else {
      setYoutubeUrlError(id);
    }
  };

  const props = {
    name: 'file',
    multiple: true,
    accept: 'image/png, image/jpg, image/jpeg',
    showUploadList: false,
    customRequest: data => {
      if (
        data?.file &&
        data?.file?.type === 'image/png' ||
        data?.file?.type === 'image/jpg' ||
        data?.file?.type === 'image/jpeg'
      ) {
        handleImageUpload(data.file);
      } else {
        notification.error('Acceptable file formats: JPEG, PNG, JPG. Please try again.', 'topRight');
      }
    },
    onDrop(e) {
      e.preventDefault();
      const file = e.dataTransfer.files?.length ? e.dataTransfer.files[0] : null;
      if (
        file?.type === 'image/png' ||
        file?.type === 'image/jpg' ||
        file?.type === 'image/jpeg'
      ) {
        handleImageUpload(file);
      }
    },
  };

  const handleImageUpload = file => {
    const MAX_SIZE_BYTES = 2097152;
    if (file.size > MAX_SIZE_BYTES) {
      return notification.error('Image(s) size must be upto 2MB', 'topRight');
    }

    const isImageInList = images.some(image => image.url === file);
    if (!isImageInList) {
      setImages(prev => {
        const newId = prev.length > 0 ? prev[prev.length - 1].id + 1 : 0;
        const newItem = {id: newId, url: file, type: 'photos'};
        return [...prev, newItem];
      });
      setShowImageActions(false);
    }
  };

  const logoProps = {
    name: 'file',
    multiple: false,
    accept: 'image/png, image/jpg, image/jpeg',
    showUploadList: false,
    customRequest: ({file}) => {
      if (
        file &&
        file?.type === 'image/png' ||
        file?.type === 'image/jpg' ||
        file?.type === 'image/jpeg'
      ) {
        const MAX_SIZE_BYTES = 2097152;
        if (file.size > MAX_SIZE_BYTES) {
          notification.error('Image size must be up to 2MB', 'topRight');
          return;
        }
        setLogo(file);
      } else {
        notification.error('Acceptable file formats: JPEG, PNG, JPG. Please try again.', 'topRight');
      }
    },
    onDrop(e) {
      e.preventDefault();
      const file = e.dataTransfer.files?.length ? e.dataTransfer.files[0] : null;
      if (
        file?.type === 'image/png' ||
        file?.type === 'image/jpg' ||
        file?.type === 'image/jpeg'
      ) {
        handleImageUpload(file);
        setLogo(file);
      }
    },
  };


  return (
    <Container style={style}>
      <div>
        <div className='logo-text'>Logo</div>
        <ImagePicker width={width} setImages={setLogo} images={logo} type='logo' props={logoProps} showImageActions={showImageActions} setShowImageActions={setShowImageActions} placeholderText={logoPlaceholder} />
      </div>
      <div className='photos-container'>
        <div className='logo-text'>{photosSectionTitle || 'Photos'} {maxLimit > 0 && typeof maxLimit === 'number' ? `(maximum: ${maxLimit})` : null}</div>
        <div className='photos'>
          <ImagePicker width={width} setImages={setImages} images={images} type='photos' showImageActions={showImageActions} setShowImageActions={setShowImageActions} props={props} hideExtra={typeof maxLimit === 'number' && maxLimit === images.length} />
        </div>
        {imagesError && <div style={{color: '#ff4d4f', fontSize: 14, marginTop: 6}}>{imagesError}</div>}
      </div>
      {showUrlSection && <div className='youtube-input-container'>
        <div className='logo-text'>Youtube Video URL(s)</div>
        <div className='input-cont' style={{maxHeight: '240px', overflow: 'auto'}}>
          {youtubeUrl?.map((item, index) => (
            <>
              <StyledFormItem key={index} name={item?.name} className='youtube-input-delete-icon' rules={[{pattern: urlRegex, message: 'Invalid URL'}]}>
                <Input placeholder='Paste Video URL here...' className={youtubeUrlError === item?.id ? 'error-youtube-url' : 'youtube-url'} onChange={e => handleChange(e.target.value, item?.id)} value={item?.value} />
                <FontAwesomeIcon icon={faTrash} color='#A3A4A4' onClick={() => setYoutubeUrl(youtubeUrl?.filter(value => value.id !== item.id))} />
              </StyledFormItem>
              {youtubeUrlError === item?.id && <p className='error'>Invalid url</p>}
            </>
          ))}
        </div>
        <span className='add-field' onClick={handleAddField}>+Add field</span>
      </div>}
      {/* <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setIsKnowledgeModalVisible(false);
          initialValues();
          setTabKeys('business_section');
        }}>
          Cancel
        </div>
        <StyledButton
          color='green'
          className='update-button'
          htmlType='submit'
          loading={loading}
        >
           Update
        </StyledButton>
      </StyledModalFooter> */}
    </Container>
  );
};

export default ImagesForm;

const Container = styled.div`
  .logo-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #4E5156;
  }
  .photos-container {
    margin-top: 19px;
    .photos {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  .youtube-url, .error-youtube-url {
    border-radius: 8px;
    margin-top: 5px;
    height: 38px;
  }
  .error-youtube-url {
    border-color: #ff4d4f;
  }
  .youtube-input-container {
    margin-top: 29px;
    .error {
      color: #ff4d4f;
      font-size: 12px;
      margin-bottom: 0px !important;
    }
    .input-cont {
      padding-right: 5px;
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      ::-webkit-scrollbar-track {
      background: #F9F9FB;
      }

      ::-webkit-scrollbar-thumb {
        background: #4E515680;
        border-radius: 5px;
      }
    }
  }
  .youtube-input-delete-icon {
    display: flex;
    align-items: center;
    gap: 18px;
    svg {
      cursor: pointer;
      width: 12.25px;
      height: 14px;
    }
    svg:hover {
      color: red;
    }
  }
  .add-field {
    margin-top: 9px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #2D6CCA;
    cursor: pointer;
  }
  ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;


export const StyledButton = styled(Button)`
  border-radius: 8px !important;
`;

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0px !important;
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  .ant-form-item-explain-error {
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;
