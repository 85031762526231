import {Col, Form, Row} from 'antd';
import {observer} from 'mobx-react';
import React from 'react';
import styled from 'styled-components';
import {Button} from '@/components/common-components/v2';
import {useStore} from '@/store/root-store';
import {snakeToCamel} from '@/utils/string';
import OttoKnowledgeGraphFields from './ottoKnowledgeGraphFields';
import {languages} from '../../../landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer/languages';

const excludedLanguages = ['None', 'jv', 'tt', 'rw', 'tr'];

export const fieldOptionsMapping = {
  language_code: languages.map(lng => ({label: lng.language, value: lng.code})).filter(lng => !excludedLanguages.includes(lng.value)),
};
export const fieldTypeMapping = {
  lat: 'number',
  long: 'number',
};

export const BusinessInfoForm = observer(({
  data,
  serpLocation,
  setSerpLocation,
  setSerpLocationId,
  form,
  style = {},
  gutter = null,
  span = null,
  labelSpan = null,
  maxHeight = '600px',
  formErrorMargin = null,
  languageCode,
  setLanguageCode,
  dropDownBottomValue = null,
}) => {
  const {
    ottoV2Store: {knowledgeGraph},
  } = useStore('');

  return (
    <>
      <FieldsContainer style={style} maxHeight={maxHeight}>
        {data?.filter(item => item.name !== 'logo' && item.name !== 'image')?.map((item, index) => {
          return (
            <StyledFormItem
              name={item.name}
              key={index}
              rules={[
                item.validation,
                item.type === 'url' && {type: 'url', message: 'URL must be valid'},
                item?.validator,
              ].filter(rule => rule)}
              formErrorMargin={formErrorMargin}
            >
              <Row style={{marginTop: '6px'}} className='input-wrapper' {...(gutter && {gutter})}>
                <Col lg={labelSpan || 6} className='label' span={labelSpan}>{item.label}</Col>
                <Col lg={span || 18}>
                  <OttoKnowledgeGraphFields
                    dropDownBottomValue={dropDownBottomValue}
                    placeholder={item.label}
                    defaultValue={knowledgeGraph[item?.name === 'iso_6523_code' ? 'iso6523Code' : snakeToCamel(item.name)]}
                    fieldType={fieldTypeMapping[item?.name] || item?.type}
                    name={item.name}
                    options={fieldOptionsMapping[item?.name] || item?.options}
                    form={form}
                    serpLocation={serpLocation}
                    setSerpLocation={setSerpLocation}
                    setSerpLocationId={setSerpLocationId}
                    languageCode={languageCode}
                    setLanguageCode={setLanguageCode}
                  />
                </Col>
              </Row>
            </StyledFormItem>
          );
        })}
      </FieldsContainer>
    </>
  );
});

export const StyledFormItem = styled(Form.Item)<{formErrorMargin?: string}>`
  margin-bottom: 0px !important;
  .ant-form-item-explain-error {
    margin-left: ${p => p?.formErrorMargin ? p?.formErrorMargin : '25%'};
    margin-top: -8px;
    font-size: 12px;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px !important;
`;

export const FieldsContainer = styled.div<{maxHeight?: string}>`
 padding-right: 5px;
 max-height: ${p => p.maxHeight};
 overflow: auto;

 ::-webkit-scrollbar {
   width: 5px;
   height: 5px;
 }

::-webkit-scrollbar-track {
  background: #F9F9FB;
 }

::-webkit-scrollbar-thumb {
   background: #4E515680;
   border-radius: 5px;
 }
`;
