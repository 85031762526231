import {Tooltip, Spin, Dropdown, Menu} from 'antd';
import {observer} from 'mobx-react';
import React, {useCallback, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faCalendar, faCartShopping, faNewspaper, faTicketSimple, faTrash, faSquareInfo, faClock} from '@fortawesome/pro-regular-svg-icons';
import {faPen, faSpinnerThird, faSortDown, faTrashXmark} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {openUrl} from '@/utils/router';
import {DescriptionLearnMore, DescriptionWrapper, GbpDeleteIcon, ImageWrapper, PaginationStyled, StatusDropdown, StyledIssuesTable, StylesScheduledFor} from '../../../../style';
import {getFilteredDataForOtto} from '../../../tableIssuesCollapse';
import {debounce} from 'lodash';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {statusStyles} from '../../../../Constants';
import moment from 'moment';
import {DeleteConfirmationModal} from '@/components/common-components/components/delete-confirmation-modal';
import {timeNotification} from '@/utils/notification-timer';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../../../freezTooltip';

interface DateRange {
  startDate: string;
  endDate: string;
}
interface PostCreatedAt {
  created: DateRange;
  scheduled: DateRange;
  published: DateRange;
  unpublished: DateRange;
  updated: DateRange;
  rejected: DateRange;
}
interface Props {
  componentIssueType?: string;
  setPageChanged?: (value: boolean) => void;
  setIssueTable?: (value: string) => void;
  showConfirmationPopup?: (value: any) => void;
  setRecordEditing?: any;
  setEditingPost?: (value: boolean) => void;
  issueTable?: string;
  deleteItem?: any;
  setOpenGenerateGbpPostsModal?: (value: boolean) => void;
  standaloneTool?: any;
  searchText?: string;
  isActiveFilter?: string;
  postCreatedAt?: PostCreatedAt;
  selectedPostType?: string;
}

export const GbpPostsTable = observer(({componentIssueType, setPageChanged, setIssueTable, setRecordEditing, setEditingPost, setOpenGenerateGbpPostsModal, standaloneTool, deleteItem, isActiveFilter, searchText, postCreatedAt, selectedPostType}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    issueTypeArray,
    ottoUrlLoader,
    ottoIssueType,
    setIsDeploying,
    loadOttoV2Project,
    setSelectedIssue,
    setSelectedCategory,
    setLoadingDetail,
    selectedCategory,
    setOttoSearchTerm,
    ottoSearchTerm,
    selectedIssue,
    unPublishOttoGBPPost,
    publishOttoGBPPost,
    unApproveOttoGBPPosts,
    deployOttoGBPPosts,
    deletingPost,
    deleteGbpPost,
    setGeneratedBulkPost,
    selectedLocation,
    setGbpTableParams,
  }} = useStore('');

  const uuid = getSingleUrlParam('uuid');
  const {width} = useWindowSize();
  const [ottoUrls, setOttoUrls] = useState([]);
  const [urlId, setUrlId] = useState<any>(-1);
  const [selectedPostsDates] = useState([]);
  const [learnMoreArray, setLearnMoreArray] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deletedItem, setDeletedItem] = useState<any>(deleteItem || -1);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [pageSize, setPageSize] = useState(!standaloneTool ? getOttoTablePageSize(componentIssueType) : 10);
  const [page, setPage] = useState(1);


  useEffect(()=> {
    setGbpTableParams({page: page, pageSize: pageSize});
  }, []);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);


  useEffect(() => {
    if (filteredData && deletedItem == -1) {
      setTableData(filteredData);
    } else {
      const newData = filteredData.filter(val=> val?.id !== deletedItem);
      setTableData(newData);
    }
  }, [deletedItem]);

  useEffect(() => {
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    const filteredDataV2 = filteredData.filter(val=> val?.id !== deletedItem);
    setTableData(filteredDataV2);
  }, [ottoUrls]);

  useEffect(() => {
    if (standaloneTool && issueTypeArray?.length && selectedIssue == 'gbp_audit' && selectedCategory == 'gbp_posts') {
      loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, 1, 10, true, '');
    }
  }, [standaloneTool, issueTypeArray, selectedIssue, selectedCategory]);

  const publishGbpPostWithDate = async (id, imageUrl, selectedPostsDates, content, status, issueType, schedulePublishingAtDate) => {
    const filter = ottoUrls.filter(d => d.issueType === issueType);
    const matchingPost = selectedPostsDates?.find(post => post.id === id);
    const schedulePublishingAt = matchingPost ? matchingPost?.schedulePublishingAt : schedulePublishingAtDate ? schedulePublishingAtDate : null;
    const data = {
      id,
      imageUrl,
      schedulePublishingAt,
      content,
    };
    try {
      if (status === 'Published') {
        await unPublishOttoGBPPost(data);
        await deleteGbpPost(id);
        // notification.success('Post unpublished', '', 2);
      } else {
        await publishOttoGBPPost(data);
        notification.success('Post is publishing', '', 2);
      }
      // await ottoBulkImportPostOrQuestion(refreshKeyMapping[issueType], false);
      const remainingFilters = getFiltersObject();
      const params = {
        uuid,
        issue_type: issueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
        ...remainingFilters,
      };
      await loadIssueTableData(params, false);
    } catch (error) {
      setUrlId(-1);
    }
    setUrlId(-1);
  };

  const getFiltersObject = () => {
    const dateObjectsArray = Object.entries(postCreatedAt).map(([key, value]) => {
      if (value?.startDate || value?.endDate) {
        if (key === 'scheduled') {
          return {
            ...(value?.startDate && {[`schedule_publishing_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
            ...(value?.endDate && {[`schedule_publishing_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
          };
        }
        return {
          ...(value?.startDate && {[`${key}_at_after`]: moment(value.startDate).format('YYYY-MM-DD')}),
          ...(value?.endDate && {[`${key}_at_before`]: moment(value.endDate).format('YYYY-MM-DD')}),
        };
      } else {
        return null;
      }
    }).filter(item => item !== null);
    const dateFilters = dateObjectsArray.reduce((acc, obj) => {
      return {...acc, ...obj};
    }, {});
    const appliedFilters = {
      ...(searchText && {search: searchText}),
      ...(selectedPostType !== 'ALL') && {topic_type__in: selectedPostType === 'UPDATE' ? 'STANDARD' : selectedPostType},
      ...(!['All', 'all'].includes(isActiveFilter)) && {status__in: isActiveFilter === 'Draft' ? 'Pending Review, Unpublished' : isActiveFilter},
      ...dateFilters,
    };
    return appliedFilters;
  };
  const scheduledAndDraftPost = async (id, imageUrl, schedulePublishingAt, content, issueType, statusVal) => {
    const filter = ottoUrls.filter(d => d.issueType === issueType);
    setUrlId(id);
    try {
      const data = {
        id,
        imageUrl,
        schedulePublishingAt,
        content,
      };
      if (statusVal === 'Scheduled') {
        await unApproveOttoGBPPosts(data);
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, '');
        notification.success('Post drafted successfully', '', 2);
      } else {
        await deployOttoGBPPosts(data);
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, '');
        notification.success('Post scheduled successfully', '', 2);
      }
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }
      const remainingFilters = getFiltersObject();
      const params = {
        uuid,
        issue_type: issueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
        ...remainingFilters,
      };
      await loadIssueTableData(params, false);
    } catch (error) {
      setUrlId(-1);
    }
    setUrlId(-1);
  };

  const postNow = async record => {
    setUrlId(record?.id);
    try {
      if (['Pending Review', 'Unpublished', 'Published'].includes(record?.status)) {
        await deployOttoGBPPosts({
          id: record?.id,
          imageUrl: record?.imageUrl,
          schedulePublishingAt: record?.schedulePublishingAt,
          content: record?.content,
        });
      }
      await publishGbpPostWithDate(record?.id, record?.imageUrl, selectedPostsDates, record?.content, record?.status, record?.issueType, record?.schedulePublishingAt);
      setUrlId(-1);
      if (standaloneTool) {
        loadIssueTables(issueTypeArray, selectedIssue, selectedCategory, url?.page, url?.pageSize, true, '');
      }
    } catch (error) {
      setUrlId(-1);
    }
  };

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);

  useEffect(() => {
    const clone = [...tableData];
    const deletingRecord = clone.find(obj => obj?.id === deletedItem);
    if (deletedItem === deletingRecord?.id) {
      const newData = filteredData.filter(val=> val.id !== deletedItem);
      setTableData(newData);
    } else if (filteredData && !deletingPost && deletedItem === -1) {
      setTableData(filteredData);
    }
  }, [deletedItem]);

  const descriptionContainer = record => {
    return (
      <div>
        <DescriptionWrapper>
          {
            learnMoreArray.includes(record?.id) ? record?.recommendedValue : record?.recommendedValue?.substring(0, 200)
          }
        </DescriptionWrapper>
        {
          !learnMoreArray.includes(record?.id) ?
            <DescriptionLearnMore onClick={()=> setLearnMoreArray([...learnMoreArray, record?.id])}>
              {record?.recommendedValue?.length > 200 ? 'Show more': ''}
            </DescriptionLearnMore> :
            <DescriptionLearnMore onClick={()=> setLearnMoreArray(learnMoreArray.filter(id => id !== record?.id))}>
              {record?.recommendedValue?.length > 200 ? 'Show less': ''}
            </DescriptionLearnMore>
        }
      </div>
    );
  };

  const searchDebounce = debounce(async (params, isSitewide) => {
    await loadIssueTableData(params, isSitewide);
  }, 1000);

  const loadIssueTables = useCallback(async (issueArray: string[], issue: string, category: string, page: number, pageSize: number, stopLoading?: boolean, search?: string, remainingFilters=null) => {
    setSelectedIssue(issue);
    setSelectedCategory(category);
    if (!stopLoading) {
      setLoadingDetail(true);
    }
    for (let index = 0; index < issueArray.length; index++) {
      const issueType = issueArray[index];
      if (issueTypeArray.includes(issueType)) {
        const params = {
          uuid,
          otto_project: getOttoV2Project?.id,
          issue_type: issueType,
          page_size: pageSize,
          page,
          is_loading: false,
          ...(search) && {search: search},
          ...remainingFilters,
        };
        if (search) {
          setOttoSearchTerm(search);
        } else {
          setOttoSearchTerm('');
        }
        if (search) {
          searchDebounce(params, false);
        } else {
          await loadIssueTableData(params, false);
        }
      }
    }
    setLoadingDetail(false);
  }, []);

  const handlePaginationChange = (issueArray: string[], page, pageSize) => {
    let remainingFilters = {};
    if (!standaloneTool) {
      remainingFilters = getFiltersObject();
    }
    loadIssueTables(issueArray, selectedIssue, selectedCategory, page, pageSize, true, standaloneTool ? ottoSearchTerm : '', remainingFilters);
  };

  const onDeletePosts = async record => {
    try {
      setDeletedItem(record.id);
      const onDelayEnd = async () => {
        const filter = ottoUrls.filter(d => d.issueType === ottoUrls[0]?.issueType);
        await deleteGbpPost(currentRecord?.id);
        let remainingFilters = {};
        if (!standaloneTool) {
          remainingFilters = getFiltersObject();
        }
        const params = {
          uuid,
          issue_type: 'gbp_posts',
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
          ...remainingFilters,
        };
        await loadIssueTableData(params, false);
        setDeletedItem(-1);
      };
      const handleUndo = () => {
        setDeletedItem(-1);
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Item deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
    } catch (error) {
      setDeletedItem(-1);
      return Promise.reject(error);
    }
  };

  const onDelete = async () => {
    try {
      await onDeletePosts(currentRecord);
      setOpenModal(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      setDeletedItem(-1);
      console.error('Error: ', error);
    }
  };

  const columns = [
    {...(standaloneTool ? {
      title: <div className='column-title'>BUSINESS</div>,
      dataIndex: 'business',
      key: 'business',
      fixed: 'left' as 'left',
      render: () => {
        return <div style={{width: '300px'}}>
          <div style={{width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 600, color: '#121212'}}>
            {selectedLocation?.businessName}
          </div>
          <div style={{width: '280px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontSize: '12px', color: '#4E5156'}}>
            <Tooltip title={selectedLocation?.businessAddress}>
              {selectedLocation?.businessAddress}
            </Tooltip>
          </div>
        </div>;
      },
    } : [])},
    {
      title: (<div className='column-title'>
        <span>IMAGE</span>
      </div>),
      dataIndex: 'image',
      className: 'pr-5',
      key: 'image',
      // width: 140,
      ...(standaloneTool ? {} : {fixed: 'left' as 'left'}),
      render: (_, record) => {
        return (
          <ImageWrapper style={{width: 119}}>
            {record?.imageUrl ?
              <img referrerPolicy='no-referrer' key={record?.imageUrl} src={record?.imageUrl} className='image' style={{width: '100%', height: '60px', objectFit: 'cover'}} onClick={() => openUrl(record?.imageUrl, '_blank')} /> :
              <Tooltip title={'Image not available'}>
                <img src='/img/icon/broken-image.svg' className='image' style={{width: '100%', height: '60px', cursor: 'not-allowed', objectFit: 'cover'}}/>
              </Tooltip>}
          </ImageWrapper>
        );
      },
    },
    {
      title: <div className='column-title'>POST CONTENT</div>,
      dataIndex: 'content',
      key: 'content',
      // width: 500,
      ...(standaloneTool ? {} : {fixed: 'left' as 'left'}),
      render: (_, record) => {
        return (<ImageWrapper style={{display: 'block', width: 500}}>
          {(record?.topicType !== 'STANDARD' && record?.title) && <div className='content-title'>{record?.title}</div>}
          {
            descriptionContainer({...record, recommendedValue: record?.content})
          }
          {(record?.topicType === 'OFFER') ? (<>
            {record?.redeemUrl ? <div className='redeem-url' onClick={() => openUrl(record.redeemUrl, '_blank')}>{record.redeemUrl}</div> : <></>}
            <div className='dates-and-code-wrapper'>
              <div className='dates-wrapper'>
                <div className='date'>
                  <FontAwesomeIcon icon={faCalendar} fontSize={12} color='#4E5156'/>
                  {record?.startDateAt ? moment(record.startDateAt).format('MMM DD, YYYY') : '-'}
                </div>
                <div>{'-'}</div>
                <div className='date'>
                  {record?.endDateAt ? moment(record.endDateAt).format('MMM DD, YYYY') : '-'}
                </div>
              </div>
              {record?.couponCode ? <div className='code-wrapper'>
                <FontAwesomeIcon icon={faTicketSimple} fontSize={12} color='#4E5156'/>{record.couponCode}
              </div> : <></>}
            </div>
            {record?.termsAndConditions ? <div className='terms-and-conditions'>
              <FontAwesomeIcon icon={faSquareInfo} fontSize={12} color='#4E5156'/>{record.termsAndConditions}
            </div> : <></>}
          </>) : <></>}
          {(record?.topicType === 'EVENT') ? (
            <div className='dates-and-code-wrapper'>
              <div className='dates-wrapper'>
                <div className='date'>
                  <FontAwesomeIcon icon={faCalendar} fontSize={12} color='#4E5156'/>
                  {record?.startDateAt ? moment(record.startDateAt).format('MMM DD, YYYY') : '-'}
                </div>
                <div>{'-'}</div>
                <div className='date'>
                  {record?.endDateAt ? moment(record.endDateAt).format('MMM DD, YYYY') : '-'}
                </div>
              </div>
              <div className='dates-wrapper'>
                <div className='date'>
                  <FontAwesomeIcon icon={faClock} fontSize={12} color='#4E5156'/>
                  {record?.startTimeAt ? moment(record.startTimeAt, 'HH:mm:ss').format('hh:mm A') : '-'}
                </div>
                <div>{'-'}</div>
                <div className='date'>
                  {record?.endTimeAt ? moment(record.endTimeAt, 'HH:mm:ss').format('hh:mm A') : '-'}
                </div>
              </div>
            </div>
          ) : (<></>)}
        </ImageWrapper>);
      },
    },
    {
      title: <div className='column-title'>TYPE</div>,
      dataIndex: 'content',
      key: 'type',
      width: 126,
      render: (_, record) => {
        return (<ImageWrapper style={{alignItems: 'center', borderRadius: '30px', border: '1px solid #D0D0D0', padding: '4px 10px'}}>
          {record?.topicType === 'STANDARD' ? (
            <><FontAwesomeIcon icon={faNewspaper} fontSize={14} color='#4E5156'/>Update</>
          ) : record?.topicType === 'EVENT' ? (
            <><FontAwesomeIcon icon={faCalendar} fontSize={14} color='#4E5156'/>Event</>
          ) : record?.topicType === 'OFFER' ? (
            <><FontAwesomeIcon icon={faCartShopping} fontSize={14} color='#4E5156'/>Offer</>
          ) : <></>}
        </ImageWrapper>);
      },
    },
    {
      title: <div className='column-title' style={{whiteSpace: 'nowrap'}}>DATE</div>,
      dataIndex: 'schedule_publishing_at',
      key: 'schedule_publishing_at',
      // width: 232,
      render: (_, record) => {
        return <>
          <div style={{width: '178px', position: 'relative'}}>
            <StylesScheduledFor style={{display: 'flex', alignItems: 'center'}}>
              {
                record?.status === 'Pending Review' ?
                  <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Created on&nbsp;{moment(record?.createdAt).format('DD MMM, YYYY')}</div>
                    <div style={{fontSize: '12px', fontWeight: '400'}}>Scheduled for&nbsp;{moment(record?.schedulePublishingAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.status === 'Unpublished' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Unpublished on&nbsp;{moment(record?.unpublishedAt).format('DD MMM, YYYY')}</div>
                    <div style={{fontSize: '12px', fontWeight: '400'}}>Scheduled for&nbsp;{moment(record?.schedulePublishingAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.status === 'Scheduled' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Scheduled for&nbsp;{moment(record?.schedulePublishingAt).format('DD MMM, YYYY')}</div>
                  </div> : record?.status === 'Publishing' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Post is being reviewed by Google</div>
                  </div> : record?.status === 'Rejected' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Rejected by Google</div>
                  </div> : record?.status === 'Published' ? <div>
                    <div style={{fontSize: '14px', fontWeight: '400'}}>Published on&nbsp;{moment(record?.publishedAt).format('DD MMM, YYYY')}</div>
                    {
                      record?.searchUrl ?
                        <p
                          onClick={() => openUrl(record?.searchUrl, '_blank')}
                          style={{
                            fontSize: '13px',
                            fontWeight: '400',
                            color: '#2D6CCA',
                            cursor: 'pointer',
                          }}
                        >View Post&nbsp;
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'#2D6CCA'} fontSize={12} />
                        </p> : <></>
                    }
                  </div> : <></>
              }
            </StylesScheduledFor>
          </div>
        </>;
      },
    },
    {
      title: (<div className='column-title'>
        <span>STATUS</span>
      </div>),
      dataIndex: 'status',
      key: 'status',
      width: 158,
      render: (_, record) => {
        const defaultStyle = {backgroundColor: '', textColor: '', icon: null, label: ''};
        const {backgroundColor, textColor, icon, label} = statusStyles[record?.status] || defaultStyle;
        return (
          <FreezeWrapper removeTooltip={record?.status === 'Published' || standaloneTool}>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu style={{borderRadius: 8}}>
                  <Menu.Item style={{textAlign: 'center', padding: 0}} onClick={()=>{
                    scheduledAndDraftPost(record?.id, record?.imageUrl, record?.schedulePublishingAt, record?.content, record?.issueType, record?.status);
                  }}>
                    <StatusDropdown backgroundColor='' textColor={statusStyles[record?.status === 'Scheduled' ? 'Pending Review': 'Scheduled']?.textColor} style={{width: '100%'}}>
                      <FontAwesomeIcon icon={statusStyles[record?.status === 'Scheduled' ? 'Pending Review': 'Scheduled']?.icon} color={statusStyles[record?.status === 'Scheduled' ? 'Pending Review': 'Scheduled']?.textColor} fontSize={14} style={{marginRight: '5px'}}/>
                      {record?.status === 'Scheduled' ? statusStyles['Pending Review'].label : 'Schedule'}
                    </StatusDropdown>
                  </Menu.Item>
                  <div style={{width: '100%', backgroundColor: 'grey', height: 1, margin: '2px 0px'}}></div>
                  <Menu.Item style={{textAlign: 'center', padding: 0}} onClick={()=>{
                    postNow(record);
                  }}>
                    <StatusDropdown backgroundColor='' textColor={statusStyles['Published']?.textColor} style={{width: '100%'}}>
                      <FontAwesomeIcon icon={statusStyles['Published']?.icon} color={statusStyles['Published']?.textColor} fontSize={14} style={{marginRight: '5px'}} />
                      Publish
                    </StatusDropdown>
                  </Menu.Item>
                </Menu>
              }
              disabled={['Published', 'Publishing', 'Rejected'].includes(record?.status)}
            >
              <StatusDropdown backgroundColor={backgroundColor} textColor={textColor}>
                <div className='status-text'>
                  {icon && (
                    <div style={{display: 'flex', alignItems: 'center', gap: 5, cursor: !['Published', 'Publishing', 'Rejected'].includes(record?.status) ? 'pointer': ''}}>
                      {
                        urlId === record?.id ? <Spin indicator={<LoadingOutlined style={{fontSize: 16, color: '#219843'}} spin />} />:
                          <FontAwesomeIcon icon={icon} color={textColor} fontSize={14} />
                      }
                      {label}
                      {!['Published', 'Publishing', 'Rejected'].includes(record?.status) && <FontAwesomeIcon icon={faSortDown} fontSize={12} color={textColor} />}
                    </div>
                  )}
                </div>
              </StatusDropdown>
            </Dropdown>
          </FreezeWrapper>
        );
      },
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 44,
      render: (_, record) => {
        return (<>
          {
            ['Published', 'Publishing'].includes(record?.status) ? <></> :
              <FreezeWrapper placement='topRight' removeTooltip={standaloneTool}>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={'Edit'}>
                  <FontAwesomeIcon style={{cursor: urlId !== -1 ? 'not-allowed' : 'pointer'}} icon={faPen} fontSize={12} color='#4E5156' onClick={() => {
                    if (urlId !== -1) {
                      return;
                    }
                    setRecordEditing(record);
                    setEditingPost(true);
                    setGeneratedBulkPost([record]);
                    setOpenGenerateGbpPostsModal(true);
                  }}/>
                </Tooltip>
              </FreezeWrapper>
          }
        </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'trashIcon',
      key: 'trashIcon',
      width: 44,
      // fixed: 'right' as 'right',
      render: (_, record) => {
        return (
          <FreezeWrapper placement='topRight' removeTooltip={record?.status === 'Published' || standaloneTool}>
            <Tooltip title={record?.status === 'Publishing' ? 'Please wait until the post gets published' : deletingPost === record?.id ? 'Deleting': 'Delete'}>
              <span>{deletingPost === record?.id ? <FontAwesomeIcon icon={faSpinnerThird} spin={deletingPost == record?.id} /> :
                <GbpDeleteIcon icon={faTrash} style={{cursor: (urlId !== -1 || record?.status === 'Publishing') ? 'not-allowed' : 'pointer'}} fontSize={14} color='#4E5156' onClick={async () => {
                  if (urlId !== -1 || record?.status === 'Publishing') {
                    return;
                  }
                  setOpenModal(true);
                  setCurrentRecord(record);
                // showConfirmationPopup(record);
                }} />}
              </span>
            </Tooltip>
          </FreezeWrapper>
        );
      },
    },
  ];


  return (
    <>
      <StyledIssuesTable
        loading={ottoUrlLoader && ottoIssueType === componentIssueType}
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={width < 1920 ? {x: 'auto'} : {}}
      />
      <PaginationStyled
        onChange={(page, pageSize) => {
          if (!standaloneTool) {
            setPageChanged(true);
            setIssueTable(url?.issueType);
          }
          !standaloneTool && saveOttoTablePageSize(componentIssueType, pageSize);
          setPageSize(pageSize);
          setPage(page);
          setGbpTableParams({page: page, pageSize: pageSize});
          handlePaginationChange([url?.issueType], page, pageSize);
        }}
        total={url?.issueTable?.count}
        pageSize={pageSize}
        current={page}
        showSizeChanger
        pageSizeOptions={['5', '10', '20', '50', '100']}
      />
      <DeleteConfirmationModal openModal={openModal} setOpenModal={setOpenModal} onDelete={onDelete} setDeleteItem={setDeletedItem} deleteItem={deletedItem} />
    </>
  );
});
