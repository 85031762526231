import React, {useState} from 'react';
import Fields from './fields';
import moment from 'moment';
import {Button, Form} from 'antd';
import {apiError} from '@/utils/api';
import {notification} from '@/utils/notification-v2';
import {useStore} from '@/store/root-store';
import {LargeBadge} from '@/components/common-components/components/badge';

const ModalContent = ({fieldsArr, form, onClose, initalValues}) => {
  const {ottoV2Store: {updateQandALocationSettings, getQandALocationSettings, selectedLocation, qAndASettings}} = useStore('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async values => {
    setLoading(true);
    try {
      const prePayload = {
        ...values,
        start_at: values.start_date_type === 'today' ? moment().format('YYYY-MM-DD') : moment(values?.start_at).format('YYYY-MM-DD'),
      };
      delete prePayload['start_date_type'];

      if (prePayload?.frequency_type === 'OPTIMIZED') {
        delete prePayload['frequency'];
      }

      await updateQandALocationSettings(selectedLocation?.id, prePayload);
      await getQandALocationSettings(selectedLocation?.id);
      notification.success('Success', 'Questions and Answers Settings updated successfully');
      onClose();
    } catch (error) {
      const errorMessage = apiError(error);
      notification.error('', errorMessage);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <div className='heading'>Automate GBP Q&As <LargeBadge alpha /></div>
      <div className='desc'>
        OTTO crafts and publishes compelling, SEO-driven questions and answers directly to your Google Business Profile. Each Q&A is designed to engage your audience, boost search visibility, and provide valuable insights to potential customers.
      </div>
      <div className='fields-container'>
        <Form
          form={form}
          onFinish={onSubmit}
          key={JSON.stringify(qAndASettings)}
          initialValues={initalValues()}
        >
          <Fields fieldsArr={fieldsArr} form={form} />
          <div className='footer'>
            <button className='cancel-button' onClick={e => {
              e.preventDefault();
              onClose();
            }}>Cancel</button>
            <Button className='update-button' htmlType='submit' loading={loading}>Update settings</Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ModalContent;
