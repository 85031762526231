import {sanitizeRegex, urlDomainRegex} from '@/constants/regex';

export function getSingleUrlParam(name: string): string | undefined {
  const params = new URLSearchParams(location.search);
  return params.get(name);
}

export function getSingleUrlParamHash(name: string): string | undefined {
  if (location.hash) {
    const params = new URLSearchParams(location.hash);
    return params.get(name);
  } else {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  }
}

export function updateQueryStringParameter(uri, key, value) {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

export function removeParameterFromUrl(url, parameter) {
  return url
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');
}


export const getPathname = () => {
  return location.pathname;
};

export const sanitizeUrl = (url: string): string => {
  return url?.replace(sanitizeRegex, '');
};

export const getKeywordFromUrl = (url: string | string[]): string => {
  const countyCode = (url.length === 3 && typeof url[url.length - 1] !== 'undefined') ? url[url.length - 1] : '';
  let keywordIndex = url.length - 2;
  if (countyCode == '') {
    keywordIndex = url.length - 1;
  }
  return url[keywordIndex];
};

export const getCountryFromUrl = (url: string | string[]): string => {
  return (url.length === 3 && typeof url[url.length - 1] !== 'undefined') ? url[url.length - 1] : '';
};

export const getDomain = (hostName: string) => {
  let domain = hostName;
  if (typeof domain !== 'object' && domain) {
    domain = domain?.replace('sc-domain:', '');
    domain = domain?.replace(/^http(s?):\/\//g, '');
    domain = domain?.split('/')[0];
    domain = domain?.replace(/\//g, '');
    domain = domain?.replace(/www./g, '');
  }
  return domain ? domain : '';
};
export const getSiteName = (hostName: string) => {
  let domain = hostName;
  if (domain) {
    domain = domain.replace('sc-domain:', '');
    domain = domain.replace(/^http(s?):\/\//g, '');
    domain = domain.split('/')[0];
    domain = domain.replace(/\//g, '');
    domain = domain.replace(/www./g, '');
    domain = domain.split('.')[0];
  }
  return domain ? domain : '';
};

export const getDomainWithSlash = (hostName: string) => {
  let domain = hostName;
  if (domain) {
    domain = domain.replace('sc-domain:', '');
    domain = domain.replace(/^http(s?):\/\//g, '');
    domain = domain.replace(/www./g, '');
  }
  return domain ? domain : '';
};

export const getHostnameAndDomain = (url: string) => {
  let result = url;
  if (result) {
    result = result.replace('sc-domain:', '');
    result = result.replace(/^http(s?):\/\//g, '');
    result = result.replace(/\//g, '');
  }
  return result ? result : '';
};


export const getProjectName = (property: string) => {
  return property ? (property.startsWith('sc-domain:') ? property.split('sc-domain:')[1] : property) : '';
};

export const updateParams = (queryParams, paramKey, paramValue) => {
  let params = '';
  Object.keys(queryParams).forEach(param => {
    if (param !== 'slug') {
      if (!params) {
        params = `${param}=${param == paramKey ? paramValue : queryParams[param]}`;
      } else {
        params += `&${param}=${param == paramKey ? paramValue : queryParams[param]}`;
      }
    }
  });
  return params;
};

export const removeProtocol = (url: string) => {
  let result = url;
  if (result) {
    result = result.replace('sc-domain:', '');
    result = result.replace(/^http(s?):\/\//g, '');
  }
  return result ? result : '';
};

export const removeProtocolAndPrefix = (url: string) => {
  let result = url;
  if (result) {
    result = result.replace('sc-domain:', '');
    result = result.replace(/^http(s?):\/\//g, '');
    result = result.replace(/www./g, '');
  }
  return result ? result : '';
};

export const getPathNameFromUrl = (url: string) => {
  if (url) {
    const path = new URL(url).pathname;
    return path.replace('/', '');
  } else {
    return '';
  }
};

export const getPageNameFromUrl = (url: string) => {
  if (url) {
    const path = new URL(url).pathname;
    return path.replaceAll('/', '');
  } else {
    return '';
  }
};
export const removeProtocolAndDomain = (hostName: string) => {
  let domain = hostName;
  if (domain) {
    domain = domain.replace('sc-domain:', '');
    domain = domain.replace(/^http(s?):\/\//g, '');
    const arr = domain?.split('/');
    const formatedArr = arr?.filter(item=> item?.length);
    domain = formatedArr?.length ? formatedArr[formatedArr?.length-1] : '';
  }
  return domain ? `/${domain}` : '';
};

export const addProtocolToDomain = (domain: string) => {
  if (!domain) return '';

  if (domain.startsWith('http://') || domain.startsWith('https://')) return domain;

  return `http://${domain}`;
};

export const addProtocolToDomainHttps = (domain: string) => {
  if (!domain) return '';

  if (domain.startsWith('http://') || domain.startsWith('https://')) return domain;

  return `https://${domain}`;
};

export const urlWithoutQueryParams = (url: string) => {
  if (url) {
    return url.split('?')[0];
  } else {
    return '';
  }
};


export const isKrPublicPage = () => {
  const checkUrl = location.pathname.match(/\/research\/keyword-researcher-old-public\/.*/g);
  return !!checkUrl;
};

export const isVerifyCustomerPublicPage = () => {
  const checkUrl = location.pathname.match(/\/verify-customer.*/g);
  return !!checkUrl;
};

export const isLocalSeoPage = () => {
  const checkUrl = location.pathname.match(/\/research\/local-seo-public\/.*/g);
  return !!checkUrl;
};

export const isLocalSeoPageV2 = () => {
  const checkUrl = location.pathname.match(/\/research\/local-seo-public\/.*/g);
  return !!checkUrl;
};

export const isSAPublicPage = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/list-public.*/g);
  return !!checkUrl;
};

export const isSAPublicDetailPage = () => {
  const checkUrl = location?.pathname?.match(/\/site-audit\/overview-public.*/g);
  return !!checkUrl;
};

export const isCompetitorPublicList = () => {
  const checkUrl = location.pathname.match(/\/research\/competitor-researcher-public.*/g);
  return !!checkUrl;
};
export const isOnPageAuditPublicList = () => {
  const checkUrl = location.pathname.match(/\/content\/onpage-audit-public.*/g);
  return !!checkUrl;
};
export const isContentPlannerPublicList = () => {
  const checkUrl = location.pathname.match(/\/content\/content-planner-public.*/g);
  return !!checkUrl;
};

export const isContentPlannerPublicDetail = () => {
  const checkUrl = location.pathname.match(/\/content\/content-planner\/public.*/g);
  return !!checkUrl;
};

export const isContentRewritingPublic = () => {
  const checkUrl = location.pathname.match(/\/content\/rewriting-tool-public.*/g);
  return !!checkUrl;
};

export const isCompetitorPublicPage = () => {
  const checkUrl = location.pathname.match(/\/research\/competitor-researcher-public\/.*/g);
  return !!checkUrl;
};

export const isbacklinkProfileAnalysisPublicPage = () => {
  const checkUrl = location.pathname.match(/\/research\/backlink-profile-analysis-public\/.*/g);
  return !!checkUrl;
};

export const isCaPublicPage = () => {
  const checkUrl = location.pathname.match(/\/content\/onpage-audit\/detail-public\/.*/g);
  return !!checkUrl;
};

export const isScaPublicPage = () => {
  const checkUrl = location.pathname.match(/\/content\/seo-content-assistant-public\/.*/g);
  return !!checkUrl;
};

export const isBrPublicPage = () => {
  const checkUrl = location.pathname.match(/\/research\/backlink-researcher-public\/.*/g);
  return !!checkUrl;
};

export const isCpPublicPage = () => {
  const checkUrl = location.pathname.match(/\/content\/content-planner\/public\/.*/g);
  return !!checkUrl;
};

export const isRanckTrackerPublic = () => {
  const checkUrl = location.pathname.match(/\/research\/keyword-rank-tracker-public\/.*/g);
  return !!checkUrl;
};

export const isCiPublicPage = () => {
  const checkUrl = location.pathname.match(/\/content\/metatags-generator\/public\.*/g);
  return !!checkUrl;
};

export const isPublicAuditPageExplorer = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/page-explorer-public.*/g);
  return !!checkUrl;
};

export const isPublicPageInsights = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/page-explorer-public\/.*/g);
  return !!checkUrl;
};

export const isPublicAuditOverview = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/overview-public.*/g);
  return !!checkUrl;
};

export const isPublicAuditIssuesPage = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/issues-page-old-public.*/g);
  return !!checkUrl;
};

export const isPublicAuditIssuesPageV2 = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/issues-page-public.*/g);
  return !!checkUrl;
};

export const isPublicSiteWidePage = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/sitewide-data-public.*/g);
  return !!checkUrl;
};

export const isPublicKeywordResearcherV2 = () => {
  const checkUrl = location.pathname.match(/\/research\/keyword-researcher-public.*/g);
  return !!checkUrl;
};
export const isPublicKRMagicV2 = () => {
  const checkUrl = location.pathname.match(/\/research\/keyword-researcher-magic-public\/.*/g) || location.pathname.match(/\/research\/keyword-magic-tool-public.*/g);
  return !!checkUrl;
};
export const isPublicKeywordMagicV2 = () => {
  const checkUrl = location.pathname.match(/\/research\/keyword-magic-tool-public.*/g) || location.pathname.match(/\/research\/keyword-researcher-magic-public\/.*/g);
  return !!checkUrl;
};

export const isPublicBacklinkResearcherList = () => {
  const checkUrl = location.pathname.match(/\/research\/backlink-researcher-public.*/g);
  return !!checkUrl;
};
export const isSiteExplorerPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/list-public.*/g);
  return !!checkUrl;
};
export const isContentGeneratorPublic = () => {
  const checkUrl = location.pathname.match(/\/content\/ai-content-generator-public.*/g);
  return !!checkUrl;
};
export const isSiteExplorerDetailsPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/detail-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerPublicPages = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/pages-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerSubDomainsPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/subdomains-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerBackLinksPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/backlinks-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerCompetitorsPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/competitors-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerPositionChangesPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/position-changes-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerGooglePagePublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/google-page-public\/.*/g);
  return !!checkUrl;
};

export const isSiteExplorerKeywordsPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/keywords-public\/.*/g);
  return !!checkUrl;
};

export const isSAPageExplorerPublic = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/page-explorer-public.*/g);
  return !!checkUrl;
};

export const isSASiteWideDataPublic = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/sitewide-data-public.*/g);
  return !!checkUrl;
};

export const isSAIssuesPagePublic = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/issues-page-public.*/g);
  return !!checkUrl;
};

export const isSAAlertsPagePublic = () => {
  const checkUrl = location.pathname.match(/\/site-audit\/alerts-page-public.*/g);
  return !!checkUrl;
};

export const isBulkReportPublicPage = () => {
  const checkUrl = location.pathname.match(/\/report-builder\/full-report-public.*/g);
  return !!checkUrl;
};

export const isOTTOV2PublicPage = () => {
  const checkUrl = location.pathname.match(/\/otto-page-v2\/public.*/g);
  return !!checkUrl;
};

// export const isBulkReportV2PublicPage = () => {
//   const checkUrl = location.pathname.match(/\/report-builder-v2\/full-report-public.*/g);
//   return !!checkUrl;
// };

export const isSiteExplorerFacebookAdsPublic = () => {
  const checkUrl = location.pathname.match(/\/site-explorer\/facebook-ads-public\/.*/g);
  return !!checkUrl;
};

export const isDomainAnalyzerViewReportPublic = () => {
  const checkUrl = location.href.match(/\/go\/view-report-public\?.*/);
  return !!checkUrl;
};

export const isNavbarRemoveOnPublicPages = () => {
  return isContentPlannerPublicDetail() || isSiteExplorerPublicPages() ||
  isSiteExplorerCompetitorsPublic() || isSiteExplorerBackLinksPublic() ||
  isSiteExplorerSubDomainsPublic() || isBrPublicPage() ||
  isSiteExplorerDetailsPublic() || isPublicKeywordResearcherV2() ||
  isPublicKRMagicV2() || isSAPublicPage() ||
  isCompetitorPublicList() || isCompetitorPublicPage() ||
    isLocalSeoPage() || isSAPublicDetailPage() || isLocalSeoPageV2() ||
    isSiteExplorerPositionChangesPublic() || isSiteExplorerGooglePagePublic() ||
    isSiteExplorerKeywordsPublic() || isSAPageExplorerPublic() ||
    isSASiteWideDataPublic() || isSAIssuesPagePublic() ||
    isSAAlertsPagePublic() ||
    isBulkReportPublicPage() ||
    isOTTOV2PublicPage() ||
    // isBulkReportV2PublicPage() ||
    isVerifyCustomerPublicPage() ||
    isCaPublicPage() ||
    isRanckTrackerPublic() ||
    isScaPublicPage() ||
    isSiteExplorerPublic() ||
    isSiteExplorerFacebookAdsPublic() ||
    isDomainAnalyzerViewReportPublic();
};

export const isSidebarRemoveOnPublicPages = () => {
  return isContentPlannerPublicDetail() ||
  isCaPublicPage() ||
  isBrPublicPage() ||
  isLocalSeoPageV2() ||
  isBulkReportPublicPage() ||
  isOTTOV2PublicPage() ||
  // isBulkReportV2PublicPage() ||
  isVerifyCustomerPublicPage() ||
  isPublicKeywordResearcherV2() ||
  isPublicKRMagicV2() ||
  isCompetitorPublicList() ||
  isCompetitorPublicPage() ||
  isLocalSeoPage() ||
  isRanckTrackerPublic() ||
  isScaPublicPage();
};

export const removeDomainFromUrl = (url: string) => {
  try {
    if (url) {
      const path = new URL(url).pathname;
      return path;
    }
  } catch (error) {
    return '';
  }
};

export const removeTrailingSlash = site => {
  return site?.replace(/\/$/, '');
};

export const copyAndModifyQueryParams = updates => {
  // Get the current URL
  let currentURL = window.location.href;

  // Parse the current URL to extract query parameters
  const url = new URL(currentURL);
  const queryParams = new URLSearchParams(url.search);

  // Copy query parameters from the current URL to the new URL
  currentURL += url.search;

  // Update specific query parameters or remove them if their value is empty, null, or undefined
  updates?.forEach(function(pair) {
    if (pair.value !== null && pair.value !== undefined && pair.value !== '' && pair.value !== 'null' && pair.value !== 'undefined') {
      queryParams.set(pair.key, pair.value);
    } else {
      queryParams.delete(pair.key);
    }
  });

  // Reconstruct the URL with updated query parameters
  url.search = queryParams.toString();
  currentURL = url.toString();
  return currentURL;
};

export const isWhitelabelShare = () => {
  return !window.origin.includes('linkgraph') && isNavbarRemoveOnPublicPages();
};

export const validateDomain = (domain: string) => {
  if (!domain?.trim()) return true;
  // A simple regex pattern to validate domain (This doesn't account for every possible domain but should suffice for basic validations)
  // eslint-disable-next-line no-useless-escape
  const pattern = /^(https?:\/\/)?(www\.)?([-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))?$/;
  return pattern.test(domain);
};

export const addDefaultSrc = e => {
  e.target.src = '/img/project-no-screenshot.png';
};

export const isValidDomain = domain => {
  // Define a regular expression pattern for a valid domain
  // This pattern checks for letters, numbers, hyphens, and periods in the domain
  // It also ensures that the domain has at least one period (.) and no consecutive periods (..)
  // const pattern = /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*$/;

  // Use the test() method of the regular expression to check if the string matches the pattern
  return domain?.match(urlDomainRegex);
};

export const findValidDomain = data => {
  if (typeof data === 'object' && data !== null) {
    // Check if the object has a "domain" property and if it's a valid domain
    if (Object.prototype.hasOwnProperty.call(data, 'domain') && isValidDomain(data.domain)) {
      return addProtocolToDomain(data.domain); // Found a valid domain, return it
    } else {
      // Traverse the object recursively
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const result = findValidDomain(data[key]);
          if (result !== null) {
            return addProtocolToDomain(result); // Stop and return the domain if found
          }
        }
      }
    }
  } else if (typeof data === 'string' && isValidDomain(data)) {
    // If data is a string and a valid domain, return it
    return data;
  }
  return null; // No valid domain found in this object
};

export const removeSpaces = url => {
  return url?.replace(/\s+/g, '');
};

export const getCorrectUrl = (url, domain) => {
  const regex = /^(https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?)$/;
  if (!url) {
    return addProtocolToDomain(domain);
  } else if (regex.test(url)) {
    return url;
  } else if (url[0] === '/') {
    return `${addProtocolToDomain(domain)}${url}`;
  } else {
    return `${addProtocolToDomain(domain)}/${url}`;
  }
};

export const getDomainPartFromString = str => {
  // Use a regular expression to find the URL in the string
  const urlPattern = /(https?:\/\/[^/]+)(\/|$)/;
  const match = str.match(urlPattern);
  // If a match is found, return the domain part of the URL
  if (match) {
    return match[1];
  } else {
    return '';
  }
};

export const urlPrefixesWithWww = ['http://www.', 'https://www.', 'www.'];
