import {Modal, Button} from '@/components/common-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark, faMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons';
import ButtonComponent from '@/components/common-components/components/button-component';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Input, Table} from 'antd';
import {faCheck, faPlus, faListTree, faPen, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {useStore} from '@/store/root-store';
import {getCorrectUrl, getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {TopupModal} from '../../../Modals/topupModal';
import {faCoins} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  setOpenCreateTopicalMap: (value: boolean) => void;
  setOpenTopicalMapModal: (value: boolean) => void;
  setTopicalMapName: (value: string) => void;
}

export const CreateNewTopicalMap: React.FC<Props> = observer(({openModal, setOpenModal, setOpenCreateTopicalMap, setOpenTopicalMapModal, setTopicalMapName}) => {
  const {ottoV2Store: {
    loadIssueTableData,
    createOttoTopicalMap,
    creatingTopicalMap,
    getOttoTopicalMapsData,
    loadingTopicalMapsList,
    topicalMapsList,
    updateTopicalMapKeyword,
    getOttoUrls,
    quotaAllocations,
  },
  settings: {customer: {profile: {quotaUtilization, nextPaymentAt, isLinkgraph}}},
  } = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [loadingId, setLoadingId] = useState(-1);
  const [createdCount, setCreatedCount] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [editKeyword, setEditKeyword] = useState(-1);
  const [updatedKeyword, setUpdatedKeyword] = useState<string>('');
  const [updatingIdx, setUpdatingIdx] = useState(-1);
  const domainName = getSingleUrlParam('domain');
  const loadTopicalMApsData = async () => {
    await getOttoTopicalMapsData(uuid);
  };
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    overAllQuota: 0,
    nextPaymentAt: null,
    step: 0,
    multiplesOfStep: false,
    unitIsMultipleOfStep: false,
    wholeCredits: false,
  });

  useEffect(() => {
    if (topicalMapsList?.length) {
      setTableData(topicalMapsList);
      setCreatedCount(topicalMapsList?.filter(obj => obj?.topicalMapId).length);
    }
  }, [JSON.stringify(topicalMapsList)]);

  useEffect(() => {
    loadTopicalMApsData();
  }, []);

  const topicalMaps = getOttoUrls.find(url => url.issueType === 'topical_maps_and_supplemental_content')?.issueTable?.results;

  const onCreateMap = async (url, keyword, index) => {
    setLoadingId(index);
    const payload = {
      content_page: url,
      content_topic: keyword,
      otto_project: uuid,
      clusters_count: 10,
      keywords_count: 10,
      titles_count: 1,
      language_code: 'en',
    };
    const params = {
      uuid,
      issue_type: 'topical_maps_and_supplemental_content',
      page_size: 10,
      page: 1,
      is_loading: false,
    };
    await createOttoTopicalMap(payload);
    await getOttoTopicalMapsData(uuid);
    await loadIssueTableData(params, false);
    setLoadingId(-1);
  };
  const onUpdateKeyword = async (url: string, index: number) => {
    try {
      setUpdatingIdx(index);
      await updateTopicalMapKeyword(uuid, {
        url,
        keyword: updatedKeyword,
      });
      getOttoTopicalMapsData(uuid);
      setEditKeyword(-1);
      setUpdatingIdx(-1);
      setUpdatedKeyword('');
    } catch (error) {
      setUpdatingIdx(-1);
    }
  };
  const tableColumn = [
    {
      title: 'PAGE URL',
      key: 'pageUrl',
      dataIndex: 'pageUrl',
      width: '440px',
      render: (_, record) => {
        const pageUrl = getCorrectUrl(record?.url, domainName);
        return <UrlWrapper onClick={() => openUrl(pageUrl, '_blank')}>{record?.url || `${domainName}` || '-'}</UrlWrapper>;
      },
    },
    {
      title: 'KEYWORD',
      key: 'keyword',
      dataIndex: 'keyword',
      render: (_, record, index) => {
        return editKeyword == index ? (
          <StyledInput placeholder='Enter Keyword...' value={updatedKeyword} onChange={e => setUpdatedKeyword(e.target.value)} />
        ) : (
          <div style={{display: 'flex', alignItems: 'center', gap: 8}}>
            {record?.keyword}
            {!record?.topicalMapId && <FontAwesomeIcon icon={faPen} fontSize={12} color='#a3a4a4' style={{cursor: 'pointer'}} onClick={() => {
              setEditKeyword(index);
              setUpdatedKeyword(record?.keyword);
            }} />}
          </div>
        );
      },
    },
    {
      title: 'TOPICAL MAP',
      key: 'topicalMap',
      dataIndex: 'topicalMap',
      width: '160px',
      render: (_, record, index) => {
        const taskStatus = topicalMaps.find(map => map?.contentPage === record?.url)?.taskStatus ?? 'SUCCESS';
        return (
          <>
            {loadingId === index || taskStatus !== 'SUCCESS' ? (
              <GeneratingTag>
                <RingLoaderV2 color='#4E5156' height='20px' width='20px' fontSize={18} align='center'/>Generating ...
              </GeneratingTag>
            ) : record?.topicalMapId ?
              (
                <CreatedWrapper>
                  <CreatedTag><FontAwesomeIcon icon={faCheck} color='#2AC155' fontSize={14}/> Created</CreatedTag>
                  <div onClick={() => {
                    setTopicalMapName(record?.keyword);
                    setOpenTopicalMapModal(true);
                  }} className='view-btn'>View</div>
                </CreatedWrapper>
              ) : editKeyword == index ? (
                <div style={{display: 'flex', gap: '10px', width: '100%'}}>
                  <ButtonStyled
                    disabled={updatingIdx == index || updatedKeyword?.length < 1}
                    loading={updatingIdx == index}
                    background='#2ac155'
                    onClick={() => onUpdateKeyword(record?.url, index)}
                    style={{display: 'flex', alignItems: 'center', gap: 2}}
                  >
                  Update
                  </ButtonStyled>
                  <CloseButton>
                    <FontAwesomeIcon icon={faTimes} fontSize={20} color='white' onClick={() => {
                      setEditKeyword(-1);
                      setUpdatedKeyword('');
                    }} />
                  </CloseButton>
                </div>
              ) :
                (
                  <ButtonStyled onClick={() => onCreateMap(record?.url, record?.keyword, index)} buttonType='blue-button' type='button'>
                    <FontAwesomeIcon icon={faListTree} fontSize={14} color='#fff'/>Create map
                  </ButtonStyled>
                )
            }
          </>);
      },
    },
  ];
  const onSearch = value => {
    if (value) {
      const filteredDataSource = topicalMapsList?.filter(record => record?.url?.toLowerCase().includes(value?.toLowerCase()));
      if (filteredDataSource?.length) {
        setTableData(filteredDataSource);
      } else {
        setTableData([]);
      }
    } else {
      setTableData(topicalMapsList);
    }
  };

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  return (
    <StyledTopicalMapsModal
      width={'970px'}
      zIndex={899}
      closable={true}
      onClose={() => {
        setOpenModal(false);
      }}
      closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark}/></div>}
      visible={openModal}
    >
      <StyledModalHeader>
        <div className='header-text'>
          <div className='modal-heading'>Create New Topical Maps</div>
          <div className='modal-description'>Create Topical Maps based on the accurate keyword data coming from the Google Search Console.</div>
        </div>
        <div>
          <QuotaInfoDetail
            isCenter
            consumedData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed}
            totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total}
            heading={'AI Generation'}
            description={'Quota Utilization AI Generation'}
            showCta
            ctaLabel={'Top up'}
            ctaIcon={<FontAwesomeIcon icon={faCoins} color='#2AC155' style={{marginRight: '5px'}} />}
            varient={'green'}
            customCta={() => {
              setTopUpBulkVisibility({
                visibility: true,
                price: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.pricePerUnit * 500 || 0,
                title: 'AI Generation Quota',
                options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
                recurring: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.topOptions[0],
                quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.quotaKey,
                desc: 'per 500 points',
                buttonTxt: 'AI Generation',
                overAllQuota: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
                nextPaymentAt,
                step: 500,
                multiplesOfStep: true,
                unitIsMultipleOfStep: true,
                total: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
                consumed: quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
                wholeCredits: true,
              });
            }}
          />
        </div>
      </StyledModalHeader>
      <ContentWrapper>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
            <UrlFilterInput
              style={{maxWidth: '360px'}}
              placeholder={'Search'}
              prefix={<FontAwesomeIcon icon={faMagnifyingGlass} color='#A3A4A4'/>}
              onChange={e => onSearch(e?.target?.value)}
            />
            <div style={{display: 'flex', alignItems: 'center', gap: '24px'}}>
              <CountWrapper>Topical Maps Created:<div>
                {loadingTopicalMapsList || creatingTopicalMap ?
                  <RingLoaderV2 color='#2D6CCA' height='20px' width='20px' fontSize={18} align='center'/> :
                  createdCount}
              </div></CountWrapper>
              <CountWrapper>GSC Pages:<div>{loadingTopicalMapsList ?
                <RingLoaderV2 color='#2D6CCA' height='20px' width='20px' fontSize={18} align='center'/> :
                topicalMapsList?.length}</div></CountWrapper>
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '6px', color: '#2D6CCA', cursor: 'pointer'}} onClick={() => {
            setOpenModal(false);
            setOpenCreateTopicalMap(true);
          }}>
            <FontAwesomeIcon icon={faPlus} color='#2D6CCA' fontSize={14}/> Add Page
          </div>
        </div>
        <StyledIssuesTable
          loading={loadingTopicalMapsList}
          columns={tableColumn}
          dataSource={[...tableData]}
          scroll={{y: 370}}
          onChange={() => setEditKeyword(-1)}
        />
      </ContentWrapper>
      <StyledModalFooter>
        <ButtonComponent
          color='gray'
          style='solid'
          type='button'
          className='close-button'
          onClick={() => setOpenModal(false)}
          disabled={false}
        >
          Close
        </ButtonComponent>
      </StyledModalFooter>
      <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
    </StyledTopicalMapsModal>
  );
});

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8 !important;    
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .header-text {
    .modal-heading {
      font-family: 'Inter', sans-serif;
      font-size: 24px;
      font-weight: 600;
      color: #121212;
    }
    .modal-description {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #4E5156;
    }
  }
`;
const ContentWrapper = styled.div`
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  background: #FFFFFF;
  height: 540;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 16px;
`;
const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .close-button {
    background-color: #E8E8E8 !important;
    span {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #121212;
    }
  }
`;
const UrlFilterInput = styled(Input)`
  max-width: 200px !important;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
`;
const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4E5156;
  div {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #121212;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  height: 26px;
  border-radius: 6px !important;
  border: 1px solid #CDCDCD;
  &:hover {
    border-color: #CDCDCD !important;
  }
  &:focus {
    border-color: #CDCDCD !important;
    box-shadow: none;
  }
  &::placeholder {
    font-size: 13px;
  }
`;
const StyledIssuesTable = styled(Table)<{isInPopOver?: boolean}>`
  margin-top: 14px;
  border-radius: ${p => p.isInPopOver ? '6px' : ''};
  .ant-spin-nested-loading {
    .ant-spin-container {
    .ant-table {
      .ant-table-container {
        .ant-table-content {
            height: ${p => p.isInPopOver ? '300px' : ''};
            overflow: ${p => p.isInPopOver ? 'auto' : ''};
            &::-webkit-scrollbar {
              height: 5px;
              width: 5px;
            }
          
            &::-webkit-scrollbar-thumb {
              background: rgba(138,138,138,0.32);
            }
          
            &::-webkit-scrollbar-track {
              background: rgba(138,138,138,0.12);
            }
          }
          .ant-table-thead {
              tr {
                th {
                  &.radish-column {
                    background-color: #feeded;
                  }
                  &.greenish-column {
                    background-color: #E9F8EE;
                  }
                  &:nth-child(1) {
                    cursor: pointer;
                    svg {
                      vertical-align: 0px;
                      margin-left: 5px;
                    }
                  } 
                  .column-title {
                    font-family: 'Inter', sans-serif;
                    color: #121212;
                    font-size: 12px;
                    font-weight: 600;
                  }
                  border-bottom: 0px;
                  background-color: #F2F2F5;
                  padding: 13px 16px;
                  &:nth-child(1) {
                    border-radius: 6px 0px 0px 6px;
                  }
                  &:nth-last-child(1) {
                    border-radius: 0px 6px 6px 0px;
                  }

                  &::before {
                    display: none;
                  }
                } 
              }
            }
          .ant-table-body {
            .ant-table-row {
                .ant-table-cell {
                  padding: 6px 16px !important;
                  border-bottom: none !important;
                }
              }
            ::-webkit-scrollbar {
              width: 5px;
              height: 5px;
            }

            ::-webkit-scrollbar-track {
              background: #F9F9FB;
            }

            ::-webkit-scrollbar-thumb {
              background: #4E515680;
              border-radius: 5px;
            }
          } 
      }
    }
    .ant-pagination {
      align-items: center;
      .ant-pagination-prev, .ant-pagination-next {
        display: flex;
        align-items: center;
        .ant-pagination-item-link {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none !important;
          background: #f7f7f8 !important;
          width: 25px;
          height: 25px;
          border-radius: 4px !important;
        }
      }
      .ant-pagination-options {
        height: 25px !important;
        .ant-select {
          background: #f7f7f8 !important;
          border: 1px solid #e8e8e8 !important;
          border-radius: 4px !important;
          height: 28px;
          justify-content: center;
          margin-bottom: 0;
          padding: 0 5px;
          z-index: 1 !important;
          .ant-select-selector {
            background-color: transparent !important;
            border: 0 !important;
            height: 1.75em !important;
            align-items: center;
          }
        }
      }
      .ant-pagination-item {
        min-width: 25px !important;
        height: 25px !important;
        background:#f7f7f8 !important;
        border: 1px solid #e8e8e8 !important;
        border-radius: 4px !important;
        box-sizing: border-box !important;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;

        &.ant-pagination-item-active {
          background: #2d2f34 !important;
          a {
            color: #fff !important;
          }
        }
        a {
          color: #121212 !important;
          text-decoration: none !important;
        }
      }
    }
  }
  }
`;
const CloseButton = styled.div`
  width: 36px;
  min-width: 25px;
  min-height: 100%;
  border-radius: 4px;
  background-color: #4E5156;
  align-items: center;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
const UrlWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #2D6CCA;
  cursor: pointer;
`;
const CreatedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .view-btn {
    font-family: 'Inter', sans-serif;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #2D6CCA;
  }
`;
const CreatedTag = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2AC155;
`;
const GeneratingTag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4E5156;
`;
const ButtonStyled = styled(Button)<{background?: string}>`
  background-color:'#2D6CCA' !important;
  border: '#2D6CCA' !important;
  border-radius: 6px !important;
  padding: 4px 12px !important;
  background: ${p => p?.background} !important;
  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: '#fff';
  }
`;
