import {cast, flow, types} from 'mobx-state-tree';
import {initDetailsStore, DetailsStore} from './detail';
import {REPORT_BUILDER_API} from '@/api/report-builder';
import {toJS} from 'mobx';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import {apiError} from '@/utils/api';
import {isArray} from 'lodash';


const reportsListModel = types.model({
  created: types.maybeNull(types.string),
  dateRange: types.maybeNull(types.string),
  dateRangeEnd: types.maybeNull(types.string),
  dateRangeStart: types.maybeNull(types.string),
  domain: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  location: types.maybeNull(types.string),
  modified: types.maybeNull(types.string),
  reportsData: types.maybeNull(types.frozen({})),
  projectId: types.maybeNull(types.number),
  emailEnabled: types.maybeNull(types.boolean),
  emailFreqDays: types.maybeNull(types.number),
  emailSelectedDay: types.maybeNull(types.number),
  emailRecipients: types.maybeNull(types.array(types.string)),
  layout: types.maybeNull(types.frozen([])),
  layoutType: types.maybeNull(types.string),
  notionPageId: types.maybeNull(types.string),
  customerClientId: types.maybeNull(types.number),
  ottoProjectUuid: types.maybeNull(types.string),
  isAiSummaryEnabled: types.maybeNull(types.boolean),
});
// const reportsDropdownModel = types.model({
//   assignedUsers: types.maybeNull(types.frozen([])),
//   customReportApps: types.maybeNull(types.frozen([])),
//   competitors: types.maybeNull(types.array(types.string)),
//   keywords: types.maybeNull(types.array(types.string)),
//   customReportId: types.maybeNull(types.number),
//   id: types.maybeNull(types.number),
//   domainUrl: types.maybeNull(types.string),
// });
export const ReportBuilderStore = types.model({
  loadingList: types.maybeNull(types.boolean),
  loadingCreateProject: types.maybeNull(types.boolean),
  isError: types.boolean,
  details: DetailsStore,
  reportsProjectsList: types.maybeNull(types.array(reportsListModel)),
  reportBuilderIdAndDomain: types.maybeNull(types.string),
  manageDomainName: types.maybeNull(types.string),
  selectedCountry: types.maybeNull(types.string),
  isOpenModal: types.boolean,
  isLocalSEOSave: types.boolean,
}).views(self => ({
  get getReportsProjectsList() {
    return toJS(self.reportsProjectsList);
  },
})).actions(self => {
  const getPublicHash = ()=> getSingleUrlParam('public_hash');

  const handleDeleteProject = flow(function* (id) {
    try {
      yield REPORT_BUILDER_API.deleteProject(id);
      notification.success('Success', 'Report deleted successfully.');
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const loadReportBuilderProjectsList = flow(function* (noLoading?: boolean) {
    if (getPublicHash()) return;
    if (!noLoading) {
      self.loadingList = true;
    }
    try {
      let response = yield REPORT_BUILDER_API.loadProjectsList();
      response = response?.map(res => {
        if (res?.reportsData?.gmb?.gmbIds && typeof(res?.reportsData?.gmb?.gmbIds) == 'number') {
          res.reportsData.gmb.gmbIds = [res?.reportsData?.gmb?.gmbIds];
        }
        return res;
      });
      if (response.reportsData?.localSeo && !isArray(response.reportsData?.localSeo.localSeoIds)) {
        response.reportsData.localSeo.localSeoIds = [response.reportsData?.localSeo?.localSeoIds];
      }
      if (response.reportsData?.gmb && !isArray(response.reportsData.gmb?.gmbIds)) {
        response.reportsData.gmb.gmbIds = [response.reportsData.gmb?.gmbIds];
      }
      response.layout = response.layout?.map(item=> {
        if (item.name == 'Map' && !item.index) {
          return {...item, index: response.reportsData.localSeo.localSeoIds[0]};
        } else return item;
      });
      self.reportsProjectsList = cast(response);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    } finally {
      self.loadingList = false;
    }
  });

  const updateReportBuilderProjectLayout = flow(function* (data, id, rb) {
    if (getPublicHash()) return;
    try {
      const seoDetail = !data?.local_seo_ids?.length ? {local_seo_keyword: data?.seoKeyword, local_seo_ids: data?.localSeoIds} : {};
      const dates = data.dateRangeStart ? {date_range_end: data.dateRangeEnd, date_range_start: data.dateRangeStart} : {date_range: data?.dateRange};
      const payload = {
        domain: data?.value,
        location: data?.location,
        local_seo_date: data?.seoDate,
        ga_property_id: data?.gaId,
        gsc_property_url: data?.gscId,
        rank_tracker_id: data?.rankId,
        backlinks_target: data?.backlinksId,
        ga_sections: data?.gaSections?.join(','),
        gsc_sections: data?.gscSections?.join(','),
        rank_tracker_sections: data?.krtSections?.join(','),
        backlinks_sections: data?.backlinksSections?.join(','),
        local_seo_sections: data?.seoSections?.join(','),
        hide_empty_sections: data?.hideEmptySections ?? true,
        gmb_ids: data?.gmb_ids,
        ...(data?.customerClientId && {customer_client_id: data.customerClientId}),
        gmb_sections: data?.gmbSections?.join(','),
        layout_type: data.layoutType || 'custom',
        layout: data.layout || self.reportsProjectsList?.find(item=> item.id == id)?.layout || [],
        ...dates,
        ...seoDetail,
        custom_layout_id: data.customLayoutId,
        is_ai_summary_enabled: data.isAiSummaryEnabled || false,
      };

      const response = yield REPORT_BUILDER_API.editProjectReport(id, payload);
      if (response) {
        self.isError= false;
        !rb && notification.success('Report layout updated', `Configuration for ${response?.domain} was successfully updated.`, 'Got it!');
        // loadReportBuilderProjectsList(true);
      }
      return toJS(response);
    } catch (e) {
      self.isError= true;
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    } finally {
      self.loadingCreateProject = false;
      self.isLocalSEOSave = false;
    }
  });

  const editProjectReport = flow(function* (data, isSaveConfig, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingCreateProject = true;
    }
    const seoDetail = !data?.local_seo_ids?.length ? {local_seo_keyword: data?.seoKeyword, local_seo_ids: data?.localSeoIds} : {};
    const payload ={
      domain: data?.value,
      location: data?.location,
      date_range: data?.dateRange,
      date_range_end: data.dateRangeEnd,
      date_range_start: data.dateRangeStart,
      local_seo_date: data?.seoDate,
      ga_property_id: data?.gaId,
      gsc_property_url: data?.gscId,
      rank_tracker_id: data?.rankId,
      backlinks_target: data?.backlinksId,
      backlinks_property_name: data?.backlinksPropertyName,
      ...(data?.fbId && {facebook_ads_account_id: data?.fbId}),
      otto_project_uuid: data?.ottoId ?? null,
      ...(data?.glId && {google_ads_account_id: data?.glId}),
      ga_sections: data?.gaSections?.join(','),
      gsc_sections: data?.gscSections?.join(','),
      rank_tracker_sections: data?.krtSections?.join(','),
      backlinks_sections: data?.backlinksSections?.join(','),
      local_seo_sections: data?.seoSections?.join(','),
      ...(data?.customerClientId && {customer_client_id: data.customerClientId}),
      ...(data?.local_seo_ids?.length && {local_seo_ids: data?.local_seo_ids}),
      ...(data?.gmb_ids?.length && {gmb_ids: data?.gmb_ids}),
      ...(data?.gmb_sections_map && {gmb_sections_map: data?.gmb_sections_map}),
      layout_type: data.layoutType || self.reportsProjectsList.find(item=> item.id == data?.id).layoutType || 'custom',
      layout: data.layout,
      ...seoDetail,
      is_ai_summary_enabled: data.isAiSummaryEnabled || false,
    };
    try {
      const response = yield REPORT_BUILDER_API.editProjectReport(data?.id, payload);
      if (response && !isSaveConfig) {
        self.isError= false;
        notification.success('Report configuration updated', `Configuration for ${response?.domain} was successfully updated.`, 'Got it!');
        loadReportBuilderProjectsList(true);
      }
    } catch (e) {
      self.isError= true;
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    } finally {
      self.loadingCreateProject = false;
    }
  });
  const bulkUpdateEmailSettings = flow(function* (data) {
    self.loadingCreateProject = true;
    const payload = data.map(report => ({
      id: report?.id,
      email_enabled: report?.emailEnabled||false,
      email_freq_days: report?.emailFreqDays||7,
      email_recipients: report?.emailRecipients || [],
      email_selected_day: (report?.emailFreqDays == 90 && report?.emailSelectedDay == 31 ? 99 : report?.emailSelectedDay) || 1,
    }));
    try {
      yield REPORT_BUILDER_API.bulkUpdateEmailSettings(payload);
      self.isError= false;
      notification.success('Automated email reports setting updated successfully.', 'Got it!');
      loadReportBuilderProjectsList(false);
    } catch (e) {
      self.isError= true;
      return Promise.reject(e);
    } finally {
      self.loadingCreateProject = false;
    }
  });
  const createNewProject = flow(function* (data, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingCreateProject = true;
    }
    const seoDetail = !data?.local_seo_ids?.length ? {local_seo_keyword: data?.seoKeyword, local_seo_ids: data?.localSeoIds} : {};
    const payload ={
      domain: data?.value,
      location: data?.location,
      ...(data?.fbId && {facebook_ads_account_id: data?.fbId}),
      ...(data?.glId && {google_ads_account_id: data?.glId}),
      date_range: data?.dateRange,
      date_range_end: data.dateRangeEnd,
      date_range_start: data.dateRangeStart,
      ga_property_id: data?.gaId,
      gsc_property_url: data?.gscId,
      rank_tracker_id: data?.rankId,
      otto_project_uuid: data?.ottoId ?? null,
      backlinks_target: data?.backlinksId,
      hide_empty_sections: true,
      layout_type: data.layoutType || 'custom',
      ga_sections: data?.gaSections?.join(','),
      gsc_sections: data?.gscSections?.join(','),
      rank_tracker_sections: data?.krtSections?.join(','),
      backlinks_sections: data?.backlinksSections?.join(','),
      ...(data?.customerClientId && {customer_client_id: data.customerClientId}),
      ...(data?.local_seo_ids?.length && {local_seo_ids: data?.local_seo_ids}),
      // ...(data?.seo_sections_map && {seo_sections_map: data?.seo_sections_map}),
      layout: data.layout,
      ...(data?.gmb_ids?.length && {gmb_ids: data?.gmb_ids}),
      ...(data?.gmb_sections_map && {gmb_sections_map: data?.gmb_sections_map}),
      ...seoDetail,
      is_ai_summary_enabled: data.isAiSummaryEnabled || false,
    };
    try {
      const response = yield REPORT_BUILDER_API.createNewProject(payload);
      if (response) {
        self.isError= false;
        notification.success('Report configuration created', `Configuration for ${response?.domain} was successfullly created.`, 'Got it!');
        loadReportBuilderProjectsList(true);
      }
      return response;
    } catch (e) {
      self.isError= true;
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage);
      return Promise.reject(e);
    } finally {
      self.loadingCreateProject = false;
    }
  });

  const setLoadingList = val => {
    self.loadingList = val;
  };
  const setIsError = value => {
    self.isError = value;
  };
  const setIsOpenModal = value => {
    self.isOpenModal = value;
  };
  const setreportBuilderIdAndDomain = val => {
    self.reportBuilderIdAndDomain = val;
  };
  const setManageDomainName = value => {
    self.manageDomainName = value.manageDomainName;
    self.selectedCountry = value.countryCode;
  };

  const setIsLocalSEOSave = value => {
    self.isLocalSEOSave = value;
  };

  return {
    setLoadingList,
    loadReportBuilderProjectsList,
    handleDeleteProject,
    createNewProject,
    editProjectReport,
    updateReportBuilderProjectLayout,
    setIsError,
    setreportBuilderIdAndDomain,
    bulkUpdateEmailSettings,
    setManageDomainName,
    setIsOpenModal,
    setIsLocalSEOSave,
  };
});

export const initReportBuilderStore = () => {
  return ReportBuilderStore.create({
    loadingList: false,
    loadingCreateProject: false,
    isError: false,
    details: initDetailsStore(),
    manageDomainName: '',
    selectedCountry: '',
    isOpenModal: false,
    isLocalSEOSave: false,
  });
};
