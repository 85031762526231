import React from 'react';
import {Accept, ViewContentContainer} from './pressReleaseStyles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCheck, faHourglassStart} from '@fortawesome/pro-duotone-svg-icons';
import {Button, Checkbox} from 'antd';

const ReviewContent = ({contentLink, acceptContent, setAcceptContent, generating}) => {
  return (
    <div>
      <ViewContentContainer background={generating ? 'rgba(255, 133, 54, 0.15)' : 'rgba(42, 193, 85, 0.1)'} height={generating ? '' : '126px'}>
        {generating ? <FontAwesomeIcon icon={faHourglassStart} fontSize={16} color='#FF8536' /> : <FontAwesomeIcon icon={faFileCheck} color='#18923B' />}
        <div>
          <div className='title'>{generating ? 'Content Generating...' : 'Content Successfully Generated'}</div>
          <div className='desc'>{generating ? '' : 'You can to review the AI generated content and modify it as necessary.'}</div>
          {!generating ? <Button
            className='view-content'
            onClick={contentLink ? () => window.open(contentLink, '_blank', 'noopener,noreferrer') : () => {}}>
              View Content
          </Button> : ''}
        </div>
      </ViewContentContainer>
      {!generating ? <Accept>
        <Checkbox checked={acceptContent} onChange={e => setAcceptContent(e.target.checked)} />
        I reviewed & accept the content.
      </Accept> : ''}
    </div>
  );
};

export default ReviewContent;
