import {Tooltip, Spin, Collapse, Dropdown, Menu} from 'antd';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare, faChevronRight, faListTree, faRotate, faTrash} from '@fortawesome/pro-regular-svg-icons';
import {faChevronLeft, faGear, faPen, faTrashXmark, faXmark, faWandMagicSparkles, faArrowUpFromLine, faFileLines, faCircleCheck} from '@fortawesome/pro-solid-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam, getCorrectUrl} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {openUrl} from '@/utils/router';
import {PAGES_API} from '@/api/content-optimizer';
import {PRO_PLAN_ID} from '@/constants';
import {apiError} from '@/utils/api';
import {RingLoaderV2} from '@/components/common-components/components/skeleton/ring-loader-v2';
import {toJS} from 'mobx';
import {getFilteredDataForOtto} from '../tableIssuesCollapse';
import {BackButton, BulkArticleHeader, DeleteButton, GenerateArticleMenu, ModalWrapper, PluginButton, PurpleCheckbox, StyledIssuesCollapse, StyledIssuesTable, TopicalMapStepsWrapper, ViewButton, Wrapper} from '../../style';
import {Button} from '@/components/common-components';
import {Modal} from '@/components/common-components/components';
import {CreateNewTopicalMap} from './CreateNewTopicalMap';
import {TableTopBar} from '../tableTopBar';
// import {convertToTransparent} from '@/utils/colors';
import {WpInstructionsModal} from '../../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/WpInstructionsModal';
import {TopicalMapModal} from './TopicalMapModal';
import {OneClickPublisher} from '../../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/OneClickPublisher';
import {timeNotification} from '@/utils/notification-timer';
import FolderSettings from '../../../landing-page-optimizer/blocks/hero/folder-settings';
import {StyledSpin} from '../../Utils/styledSpin';
import {getOttoTablePageSize} from '@/api/common-utils';
import {saveOttoTablePageSize} from '@/api/common-utils';
import FreezeWrapper from '../freezTooltip';
import {useRouter} from 'next/router';
import {DeleteRapper} from './TopicalMapModal/topicalMapModalTableContent';

const tabsTitle = [
  'Generate content',
  'Publish',
  'View in WordPress',
];
interface Props {
  setOpenCreateTopicalMap: (value: boolean) => void;
  openCreateNewTopicalMapModal: boolean;
  setOpenCreateNewTopicalMapModal: (value: boolean) => void;
  componentIssueType: string;
  setPageChanged: (value: boolean) => void;
  setIssueTable: (value: string) => void;
  issueTable: string;
  showGenerateBulkHeader: boolean;
  setShowGenerateBulkHeader: Dispatch<SetStateAction<boolean>>;
}

export const TopicalMapsAndSupplementalContent = observer(({setOpenCreateTopicalMap, openCreateNewTopicalMapModal, setOpenCreateNewTopicalMapModal, componentIssueType, setPageChanged, setIssueTable, issueTable, showGenerateBulkHeader, setShowGenerateBulkHeader}: Props) => {
  const {ottoV2Store: {
    getOttoUrls,
    getOttoV2Project,
    loadIssueTableData,
    storeSelectedTitles,
    topicalMapUpdateArticle,
    issueTypeArray,
    ottoUrlLoader,
    storingSelectedTitles,
    deleteOttoTopicalMap,
    deletingTopicalMap,
    ottoSearchTerm,
    createTopicalMapBulkArticles,
    loadOttoV2Project,
    setIsDeploying,
  }, settings: {customer: {profile: {address, plan}, getCustomerQuota}},
  contentOptimizer: {
    pages: {loadPages, incrementInTotalArticle, setActiveProjectId, getAllProjects},
    aiOutline: {setTopUpBulkVisibility, setAiWriter, aiWriterController, isEdit, setIsEdit, setOpenUpdateProjectModal, openUpdateProjectModal},
    wpPublish: {wpArray, setShowInstructionsModal, fetchWpPublishData, showInstructionsModal}},
  gsc: {googleAnalytics: {setGaDataModal, gaDataModal}, criteria},
  siteAuditorV2: {setGscOpenModalValue, siteAuditSelectedProperty},
  } = useStore('');
  const projectId = (siteAuditSelectedProperty ? siteAuditSelectedProperty : (localStorage.getItem('lastPropertySiteAudit') || getOttoV2Project?.siteAuditId))?.toString();
  const router = useRouter();

  const uuid = getSingleUrlParam('uuid');
  const [ottoUrls, setOttoUrls] = useState([]);
  const domainName = getOttoV2Project?.hostname || getSingleUrlParam('domain');

  const defaultCounty = {
    countryCode: 'US',
    location: 'United States',
    locationId: 2840,
  };

  const [selectedCountry, setSelectedCountry] = useState(defaultCounty);
  const [topicalMapArticle, setTopicalMapArticle] = useState([]);
  const [updatingStatus, setUpdatingStatus] = useState('');
  const [topicalMapName, setTopicalMapName] = useState('');
  const [openTopicalMapModal, setOpenTopicalMapModal] = useState(false);
  const [errorId, setErrorId] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [deleteDataId, setDeleteDataId] = useState(null);
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isTableOpen, setIsTableOpen] = useState<any>('open');
  const [tableData, setTableData] = useState([]);
  const [isActiveFilter, setIsActiveFilter] = useState('all');
  const [selectedArticlesToGenerate, setSelectedArticlesToGenerate] = useState([]);
  const [generatingBulkArticles, setGeneratingBulkArticles] = useState(false);
  const [pageSize, setPageSize] = useState(() => getOttoTablePageSize(componentIssueType));
  const [deleteId, setDeleteId] = useState<any>('');


  const supportingPages = tableData?.flatMap(record => record?.formattedData?.length && record?.formattedData?.flatMap(item =>
    item.keywords.filter(keyword =>
      keyword.titles[0].isSelected === true)?.map(keyword => ({...keyword, id: record.id})),
  ));
  const supportingPagesWithoutArticle = supportingPages?.filter(page => !page?.titles[0]?.pageUuid);

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    if (getOttoUrls) {
      setOttoUrls(getOttoUrls);
    }
  }, [getOttoUrls]);

  useEffect(() => {
    const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
    const url = filteredOttoUrls?.find(url => url?.issueType === componentIssueType);
    const data = url?.issueTable?.results ?? [];
    const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
    const filterFormatedData = ottoSearchTerm?.trim() ? filteredData?.filter(url => url.contentPage?.includes(ottoSearchTerm) ? true : false) : filteredData;
    setTableData(filterFormatedData);
  }, [ottoUrls, ottoSearchTerm]);

  useEffect(() => {
    if (address?.defaultLocation) {
      setSelectedCountry({
        countryCode: address?.defaultLocation,
        location: address?.countryName,
        locationId: address?.locationId,
      });
    }
  }, [address?.defaultLocation]);

  const deleteArticleIdea = async (contentTopic, topicalMapArticleDataParam) => {
    setOpenModal(false);
    try {
      const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === contentTopic);
      setDeleteId(selectedArticle?.titleUuid);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      const payload = {
        id: selectedArticle?.id,
        titleUuid: selectedArticle?.titleUuid,
        shouldDelete: true,
        publicShareHash: getOttoV2Project?.publicShareHash,
      };
      await topicalMapUpdateArticle(payload);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      await loadIssueTableData(params, false, true);
      notificationV2.success('', 'Article deleted successfully', 'Close');
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
    } catch (e) {
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
      const errorMessage = apiError(e);
      notification.error('', errorMessage);
    }
  };

  const updateTopicalMapArticle = async articleData => {
    const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
    await topicalMapUpdateArticle({
      id: articleData?.id,
      titleUuid: articleData?.titleUuid,
      pageUuid: articleData?.pageUuid,
    });
    const params = {
      uuid,
      issue_type: componentIssueType,
      page_size: filter?.length ? filter[0].pageSize : 10,
      page: filter?.length ? filter[0].page : 1,
      is_loading: false,
    };
    await loadIssueTableData(params, false, true);
  };

  const groupById = data => {
    const result = {};
    data?.forEach(item => {
      if (!result[item.id]) {
        result[item.id] = [];
      }
      result[item.id].push(item.titleUuid);
    });
    return result;
  };

  const onGenerateBulkArticles = async (articles: any[], singleArticle?: boolean) => {
    try {
      setGeneratingBulkArticles(true);
      const articleGroup = groupById(articles);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      for (let index = 0; index < Object.keys(articleGroup).length; index++) {
        const key = Object.keys(articleGroup)[index];
        const uuids = articleGroup[key];
        const payload = {
          'uuids': uuids,
        };
        await createTopicalMapBulkArticles(key, payload, singleArticle);
      }
      setTimeout( async ()=> {
        const params = {
          uuid,
          issue_type: componentIssueType,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
        };
        setDeleteModalData(null);
        await loadIssueTableData(params, false, true);
        setSelectedArticlesToGenerate([]);
        setShowGenerateBulkHeader(false);
        setGeneratingBulkArticles(false);
        getCustomerQuota();
        incrementInTotalArticle();
        notificationV2.success('Success', `Article${singleArticle ? '' : 's'} created successfully.`);
      }, 1000);
    } catch (e) {
      setGeneratingBulkArticles(false);
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error(`Could not generate ${singleArticle ? 'article' : 'bulk articles'}`, e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notification.error('', errorMessage);
      }
    }
  };

  const onGenerateOutline = async (item: any, record: any) => {
    const alreadyExist = topicalMapArticle.some(i => i?.title === item?.name);
    if (!alreadyExist) {
      const newArticle = {
        id: record?.id,
        titleUuid: item?.titleUuid,
        title: item?.name,
        isLoading: true,
        pageUuid: '',
      };
      setTopicalMapArticle(prev => [...prev, newArticle]);
    } else {
      setTopicalMapArticle(prev => prev.map(i => {
        if (i?.title === item?.name) {
          return {...i, isLoading: true};
        }
        return i;
      }));
    }
    const topicalMapArticleDataParam = [...topicalMapArticle, {
      id: record?.id,
      titleUuid: item?.titleUuid,
      title: item?.name,
      isLoading: true,
      pageUuid: '',
    }];
    const payload = {
      'should_generate_outline': true,
      'keywords_list': [item?.name],
      'location': 'United States',
      'location_id': 2840,
      ...(selectedCountry?.location && {location: selectedCountry?.location}),
      ...(selectedCountry?.locationId && {location_id: selectedCountry?.locationId}),
    };
    try {
      const res = await PAGES_API.generateOutlineWithoutContent(payload);
      setTimeout( async ()=> {
        if (res?.uuid) {
          await loadPages(false, record?.project, false);
          getCustomerQuota();
          incrementInTotalArticle();
          const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === item?.name);
          const articleData = {...selectedArticle, pageUuid: res?.uuid};
          if (articleData?.pageUuid) {
            await updateTopicalMapArticle(articleData);
            setTopicalMapArticle(prev => {
              const updatedData = prev.map(i => {
                if (i?.title === item?.name) {
                  return {...i, pageUuid: res?.uuid, isLoading: res?.aiInProgress};
                }
                return i;
              });
              return updatedData;
            });
            notificationV2.success('Success', 'Outline generated successfully.');
          }
        }
      }, 1000);
    } catch (e) {
      setTopicalMapArticle(prev => {
        const updatedData = prev.map(i => {
          if (i?.title === item?.name) {
            return {...i, isLoading: false};
          }
          return i;
        });
        return updatedData;
      });
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error('Could not generate Outline', e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notification.error('', errorMessage);
      }
    }
  };

  const removeTitles = async (id, list, record, index) => {
    try {
      setUpdatingStatus(`${id},${index}`);
      const onDelayEnd = async () => {
        const filter = ottoUrls?.filter(d => d?.issueType === componentIssueType);
        await storeSelectedTitles({id, uuids: list?.filter(item => item?.name !== record?.name)?.map(item => item?.titles[0]?.titleUuid)});
        const params = {
          uuid,
          issue_type: componentIssueType,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
        };
        setDeleteModalData(null);
        await loadIssueTableData(params);
        notification.success('Selected titles status updated', 2);
        setUpdatingStatus('');
        setOpenTopicalMapModal(false);
      };
      setOpenModal(false);
      const handleUndo = () => {
        setUpdatingStatus('');
        setDeleteModalData(null);
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Issue deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      setUpdatingStatus('');
    } catch (error) {
      setOpenModal(false);
      setDeleteModalData(null);
      setUpdatingStatus('');
      notification.error('Something went wrong, please try again', '', 2);
    }
  };

  const handleSettingModalOpen = async (record, id) => {
    if (id == 0) return;
    fetchWpPublishData(false);
    setOpenUpdateProjectModal(true);
    setIsEdit(true);
    setAiWriter({
      ...toJS(aiWriterController ?? []),
      templateUsed: 'complete-seo-article',
    });
    if (id) setActiveProjectId(id);
  };
  const onDeleteTopicalMap = async id => {
    try {
      setUpdatingStatus(`${id}-deleting`);
      setDeleteDataId(id);
      const onDelayEnd = async () => {
        const filter = ottoUrls.filter(d => d.issueType === componentIssueType);
        await deleteOttoTopicalMap(id);
        setIsDeploying(true);
        if (uuid) {
          await loadOttoV2Project(uuid, true);
        }
        const params = {
          uuid,
          issue_type: componentIssueType,
          page_size: filter?.length ? filter[0].pageSize : 10,
          page: filter?.length ? filter[0].page : 1,
          is_loading: false,
        };
        setOpenModal(false);
        await loadIssueTableData(params, true);
        notification.success('Topical map deleted successfully', 2);
        setDeleteModalData(null);
        setUpdatingStatus('');
      };
      setOpenModal(false);
      const handleUndo = () => {
        setDeleteDataId(null);
        setUpdatingStatus('');
      };
      timeNotification('#FF0000', faTrashXmark, 4, `Issue deleted successfully`, ()=>handleUndo(), '#FF0000', onDelayEnd);
      setUpdatingStatus('');
    } catch (error) {
      setOpenModal(false);
      setDeleteModalData(null);
      setUpdatingStatus('');
      notification.error('Something went wrong, please try again', '', 2);
    }
  };

  const handleOpenGSCModal = isGaObject => {
    const connectedData = isGaObject?.connectedData;
    criteria.updateCriteria({
      ...criteria,
      property: connectedData?.gscDetails?.propertyUrl || '',
    });
    setGscOpenModalValue(true, 'GSC Setting', true, projectId, false);
    setGaDataModal({
      ...gaDataModal,
      visible: true,
      siteUrl: connectedData?.gscDetails?.propertyUrl || '',
      gaConnected: isGaObject?.isGscConnected,
      settingId: null,
      settingsEnabled: false,
      hasSiteGaCredentials: null,
      saProjectId: projectId,
      countryCode: connectedData?.gscDetails?.countryCode || '',
    });
  };
  // const generateColor = si => {
  //   if (si === 'informational') {
  //     return '#34AEF3';
  //   } else if (si === 'commercial') {
  //     return '#F1AA3E';
  //   } else if (si === 'transactional') {
  //     return '#1F9068';
  //   } else if (si === 'navigational') {
  //     return '#B98CE4';
  //   }
  // };

  const selectUnSelectArticleToGenerate = (id: string, titleUuid: string) => {
    if (selectedArticlesToGenerate.find(article => article?.titleUuid === titleUuid)?.titleUuid) {
      setSelectedArticlesToGenerate(selectedArticlesToGenerate.filter(article => article?.titleUuid !==titleUuid));
    } else {
      setSelectedArticlesToGenerate([...selectedArticlesToGenerate, {id, titleUuid}]);
    }
  };

  const selectAllArticleToGenerate = () => {
    if (generatingBulkArticles) {
      return;
    }
    setSelectedArticlesToGenerate(supportingPagesWithoutArticle.map(article => ({id: article?.id, titleUuid: article?.titles[0]?.titleUuid})));
  };

  const deselectAllArticleToGenerate = () => {
    if (generatingBulkArticles) {
      return;
    }
    setSelectedArticlesToGenerate([]);
  };

  const columns = [
    {
      title: <div className='column-title'>PILLAR PAGE & KEYWORD</div>,
      dataIndex: 'pillarPage',
      key: 'pillarPage',
      style: {position: 'relative'},
      className: 'text-align-top',
      width: 300,
      render: (_, record) => {
        const pageUrl = getCorrectUrl(record?.contentPage, domainName);
        return (
          <div style={{maxWidth: 250}}>
            <div onClick={() => openUrl(pageUrl, '_blank')} style={{fontSize: '13px', color: '#2D6CCA', cursor: 'pointer'}}>{record?.contentPage || `${domainName}` || '-'}</div>
            <div style={{color: '#4E5156', fontSize: '12px'}}>{record?.contentTopic}</div>
          </div>
        );
      },
    },
    {
      title: <div className='column-title' style={{paddingLeft: '8px'}}>SUPPORTING PAGE TITLE & KEYWORD</div>,
      dataIndex: 'supportingKeyword',
      key: 'supportingKeyword',
      className: 'greenish-column',
      width: 700,
      render: (_, record) => {
        const selectedTitles = record?.formattedData?.length && record?.formattedData?.flatMap(item =>
          item?.keywords?.flatMap(keyword => keyword?.titles?.filter(title => title.isSelected === true)),
        );
        return (
          <>
            {record?.taskStatus === 'SUCCESS' ?
              <StyledTopButtons arrayLength={selectedTitles?.length}>
                <ButtonStyled
                  background='#FFFFFF'
                  color='#4E5156'
                  border='1px solid #D2D2D2'
                  onClick={() => {
                    setTopicalMapName(record?.contentTopic);
                    setOpenTopicalMapModal(true);
                  }} buttonType='transparent-with-border'>
                  <FontAwesomeIcon icon={faPen} fontSize={14} color='#4E5156'/>Edit Topical Map
                </ButtonStyled>
                <Tooltip overlayInnerStyle={{background: '#121212'}} title={!record?.project && 'No project available for this topical map'} placement={'right'}>
                  <ButtonStyled
                    background='#FFFFFF'
                    color='#4E5156'
                    border='1px solid #D2D2D2'
                    onClick={()=> !record?.project ? {} : handleSettingModalOpen(record, record?.project)}>
                    <FontAwesomeIcon icon={faGear} color='#4E5156' fontSize={16}/>AI Settings
                  </ButtonStyled>
                </Tooltip>
                <ButtonStyled
                  background='#FFFFFF'
                  color='#4E5156'
                  border='1px solid #D2D2D2'
                  style={{padding: '8px 10px'}}
                >
                  {updatingStatus === `${record?.id}-deleting` ?
                    <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#4E5156'}} spin />} /> :
                    <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}>
                      <Tooltip title={updatingStatus?.length ? '' : 'Delete'}>
                        <FontAwesomeIcon
                          style={{cursor: (updatingStatus?.length && updatingStatus !== `${record?.id}-deleting`) ? 'not-allowed' : 'pointer'}}
                          icon={faTrash}
                          fontSize={16}
                          color='#4E5156'
                          onClick={() => {
                            if ((updatingStatus?.length && updatingStatus !== `${record?.id}-deleting`)) {
                              return;
                            } else {
                              setDeleteModalData({id: record?.id, method: 'delete'});
                              setOpenModal(true);
                            }
                          }}
                        />
                      </Tooltip>
                    </FreezeWrapper>}
                </ButtonStyled>
              </StyledTopButtons> :
              <GeneratingTag>
                <RingLoaderV2 color='#4E5156' height='20px' width='20px' fontSize={18} align='center'/>Generating ...
              </GeneratingTag>}
            {selectedTitles?.length ? selectedTitles?.map((item, idx) => {
              const isLoading = !!topicalMapArticle?.filter(i => i?.title === item?.name)?.length && topicalMapArticle?.find(i => i?.title === item?.name)?.isLoading;
              const isGenerating = selectedArticlesToGenerate.filter(article => article?.titleUuid === item?.titleUuid)?.length && generatingBulkArticles || (!item?.pageUuid && item?.aiGenerationStatus === 'PENDING') || isLoading;
              return (
                <ItemWrapper key={idx} itemIndex={idx} totalItems={selectedTitles?.length || 0}>
                  <ContentWrapper itemIndex={idx} totalItems={selectedTitles?.length || 0}>
                    <div className='wrapper'>
                      <div style={{display: 'flex'}}>
                        <StyledItemData style={{width: '405px'}} itemIndex={idx} totalItems={selectedTitles?.length || 0} className='supporting-title-keywords-content'>
                          {idx === 0 && <FontAwesomeIcon icon={faChevronLeft} fontSize={14} color='#4E5156' className='first-item-arrow-topical'/>}
                          {(!item?.pageUuid && showGenerateBulkHeader) && (
                            <div style={{display: 'inline-block', marginRight: '4px'}}>
                              <PurpleCheckbox checked={selectedArticlesToGenerate.find(article => article?.titleUuid === item?.titleUuid)} onClick={() => selectUnSelectArticleToGenerate(record?.id, item?.titleUuid)}/>
                            </div>)}
                          {item?.name}
                          <div className='keywords' style={{paddingLeft: (!item?.pageUuid && showGenerateBulkHeader) && '20px'}}>
                            {item?.name}
                          </div>
                          {!item?.pageUuid && showGenerateBulkHeader ? '' : <div style={{display: 'flex', alignItems: 'center', gap: '20px', width: 'fit-content'}}>
                            <TopicalMapStepsWrapper>
                              <div className='steps'>
                                {tabsTitle?.map((title, index) => {
                                  return (<div
                                    key={index}
                                    className={`section ${
                                      (index === 0) ? 'active-section ' :
                                        (index === 1 && !isLoading && item?.pageUuid) ? 'active-section ' :
                                          (index === 2 && !isLoading && item?.wpPublishedAtUrl) ? 'active-section ' : ''
                                    } ${(index !== 0) ? '' : ' restricted'}`}
                                    onClick={() => {}}
                                  >
                                    <div className={`step ${(index === 0 && !isLoading && item?.pageUuid) ? 'active' : ''}`}>
                                      {index === 0 ?
                                        isGenerating ?
                                          <ViewButton style={{color: '#7F4EAD', backgroundColor: 'rgba(127, 78, 173, 0.15)', width: '162px'}}>
                                            <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#7F4EAD'}} spin />} />Generating...
                                          </ViewButton> :
                                          item?.pageUuid ? <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                            <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />
                                            {/* <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                              {item?.searchIntent?.length && item?.searchIntent?.map(si => (
                                                <StyledTag key={si} style={{color: generateColor(si.toLowerCase()), backgroundColor: convertToTransparent(generateColor(si.toLowerCase()), '10%')}}>
                                                  {si === 'informational' ? 'I' : si === 'commercial' ? 'C' : si === 'transactional' ? 'T' : si === 'navigational' ? 'N' : ''}
                                                </StyledTag>
                                              ))}
                                            </div> */}
                                            <GenerateButton
                                              style={{color: '#4E5156', width: '84px'}}
                                              onClick={() => openUrl(`/content/seo-content-assistant/${item?.pageUuid}`, '_blank')}>View content</GenerateButton>
                                          </div> : <FreezeWrapper removeTooltip={!router?.pathname?.includes('otto-page-v2')}><Dropdown trigger={['click']}
                                            overlayStyle={{top: '974px', left: '972px'}}
                                            overlay={<GenerateArticleMenu>
                                              <Menu.Item className='menu-item'
                                                onClick={() => {
                                                  selectUnSelectArticleToGenerate(record?.id, item?.titleUuid);
                                                  onGenerateBulkArticles([{id: record?.id, titleUuid: item?.titleUuid}], true);
                                                }}>
                                                <FontAwesomeIcon icon={faFileLines} color='#121212' fontSize={14}/>Full Article with AI
                                              </Menu.Item>
                                              <Menu.Item className='menu-item'
                                                onClick={() => onGenerateOutline(item, record)}><FontAwesomeIcon icon={faListTree} color='#121212' fontSize={14}/>Headings Outline with AI</Menu.Item>
                                            </GenerateArticleMenu>}>

                                            <ViewButton style={{color: '#7F4EAD', backgroundColor: 'rgba(127, 78, 173, 0.15)', width: '162px', maxHeight: '27px', cursor: 'pointer', padding: '5px 12px'}}>
                                              <FontAwesomeIcon icon={faWandMagicSparkles} color='#7F4EAD' fontSize={14} />{title}
                                            </ViewButton>
                                          </Dropdown> </FreezeWrapper>: index === 1 ? (<FreezeWrapper>
                                          {!isLoading && item?.pageUuid && item?.wpPublishedAtUrl ? (
                                            <>
                                              <OneClickPublisher errorId={errorId} setErrorId={setErrorId} topicalMapName={item?.wpPublishedAtUrl} titleUuid={`${item?.titleUuid}-modal`} uuid={item?.pageUuid}/>
                                            </>
                                          ) : (
                                            <>
                                              {
                                                !isLoading && item?.pageUuid ?
                                                  !wpArray?.length ? (
                                                    <PluginButton onClick={() => setShowInstructionsModal(true)}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#7F4EAD'} fontSize={14} />{title}</PluginButton>
                                                  ) : (
                                                    <OneClickPublisher errorId={errorId} setErrorId={setErrorId} topicalMapName={item?.wpPublishedAtUrl} titleUuid={`${item?.titleUuid}-modal`} uuid={item?.pageUuid}/>
                                                  ):
                                                  <PluginButton style={{cursor: 'not-allowed'}}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#A3A4A4'} fontSize={14} />{title}</PluginButton>
                                              }
                                            </>
                                          )}
                                        </FreezeWrapper>) : (<FreezeWrapper removeTooltip={item?.pageUuid && item?.wpPublishedAtUrl}><ViewButton
                                          onClick={() => {
                                            if (!isLoading && item?.pageUuid && item?.wpPublishedAtUrl) {
                                              openUrl(item?.wpPublishedAtUrl, '_blank');
                                            } else {
                                              return;
                                            }
                                          }
                                          }
                                          style={{color: !isLoading && item?.pageUuid && item?.wpPublishedAtUrl ? '#7F4EAD' : '#A3A4A4',
                                            backgroundColor: !isLoading && item?.pageUuid && item?.wpPublishedAtUrl ? 'rgba(127, 78, 173, 0.15)' : 'rgba(127, 78, 173, 0)',
                                            width: '162px',
                                            maxHeight: '27px',
                                            padding: !isLoading && item?.pageUuid && item?.wpPublishedAtUrl ? '5px 6px' : '8px',
                                            cursor: (!isLoading && item?.pageUuid && item?.wpPublishedAtUrl) ? 'pointer' : 'not-allowed',
                                          }}>
                                          {!isLoading && item?.pageUuid && item?.wpPublishedAtUrl ? (<>
                                            <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />{title}
                                          </>) : (<>
                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#A3A4A4' fontSize={14} />{title}
                                          </>)}
                                        </ViewButton></FreezeWrapper>)}
                                    </div>
                                    {index !== tabsTitle.length -1 && <FontAwesomeIcon icon={faChevronRight} color='#A3A4A4' fontSize={14} />}
                                  </div>);
                                })}
                              </div>
                            </TopicalMapStepsWrapper>
                            <DeleteRapper style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '6px'}} isGenerating={isGenerating}>
                              {item?.titleUuid === deleteId ? (
                                <StyledSpin color='#7F4EAD' />
                              ) : (
                                <FreezeWrapper removeTooltip={!isLoading && item?.pageUuid && item?.wpPublishedAtUrl}>
                                  <Tooltip title={isGenerating ? `Can't delete while generating` : ''}>
                                    <FontAwesomeIcon
                                      icon={faTrash}
                                      className='trash'
                                      fontSize={16}
                                      onClick={() => {
                                        if (isGenerating) return;
                                        setOpenModal(true);
                                        setDeleteModalData({id: record?.id, name: item?.name, titleUuid: item?.titleUuid, method: 'delete-idea'});
                                      }}
                                    />
                                  </Tooltip>
                                </FreezeWrapper>
                              )}
                            </DeleteRapper>
                          </div>}
                        </StyledItemData>
                      </div>
                    </div>
                  </ContentWrapper>
                </ItemWrapper>
              );
            }) : ''}
          </>
        );
      },
    },
  ];

  const filteredOttoUrls = ottoUrls ? ottoUrls?.filter(url => issueTypeArray.includes(url?.issueType)) : [];
  const url = filteredOttoUrls.find(url => url?.issueType === componentIssueType);
  const data = url?.issueTable?.results ?? [];
  const filteredData = getFilteredDataForOtto(data, url?.issueType, false);
  const filterFormatedData = ottoSearchTerm.trim() ? filteredData?.filter(url => url.contentPage?.includes(ottoSearchTerm) ? true : false) : filteredData;

  useEffect(() => {
    if (filterFormatedData && deleteDataId == null) {
      setTableData(filterFormatedData);
    } else {
      const newData = filterFormatedData.filter(val=> val.id !== deleteDataId);
      setTableData(newData);
    }
  }, [deleteDataId]);

  return (
    <>
      {
        !filteredData.length && !getOttoV2Project?.connectedData?.isGscConnected ?
          <TopicalMapContainer>
            <div className='connect-gsc'>
              <div className='title'>Create topical maps from GSC Data</div>
              <div className='description'>Create Topical Maps based on the accurate keyword data coming from the Google Search Console.</div>
              <Button className='button' onClick={() => handleOpenGSCModal(getOttoV2Project)}>Connect Google Search Console</Button>
            </div>
            <div className='create-tm'>
              <div className='title'>Add a Pillar Page & Target Keyword</div>
              <div className='description'>Alternatively you can provide pairs of Pillar Pages and Target Keywords to create detailed topical maps on the subject.</div>
              <FreezeWrapper>
                <Button className='button' onClick={() => setOpenCreateTopicalMap(true)}>Create Topical Map</Button>
              </FreezeWrapper>
            </div>
          </TopicalMapContainer> :
          <StyledIssuesCollapse
            ghost
            expandIconPosition='right'
            defaultActiveKey={[`${isTableOpen}`]}
            activeKey={[`${isTableOpen}`]}
            onChange={() => {
              if (isTableOpen === 'close') {
                setIsTableOpen('open');
                setIsOpenSearch(false);
              } else {
                setIsTableOpen('close');
                setIsOpenSearch(false);
              }
            }}
          >
            <TableTopBar
              componentIssueType={componentIssueType}
              setPageChanged={setPageChanged}
              setIssueTable={setIssueTable}
              issueTable={issueTable}
              setSearchText={setSearchText}
              searchText={searchText}
              setIsOpenSearch={setIsOpenSearch}
              isOpenSearch={isOpenSearch}
              setIsTableOpen={setIsTableOpen}
              isTableOpen={isTableOpen}
              setIsActiveFilter={setIsActiveFilter}
              setOpenCreateNewTopicalMapModal={setOpenCreateNewTopicalMapModal}
              setOpenCreateTopicalMap={setOpenCreateTopicalMap}
              isActiveFilter={isActiveFilter} />
            {showGenerateBulkHeader ? <BulkArticleHeader>
              <div className='left-wrapper'>
                <div className='selected-count'>Selected: <div>{selectedArticlesToGenerate?.length} Article{`${selectedArticlesToGenerate?.length > 1 ? 's': ''}`}</div></div>
                <div className='action-buttons'>
                  <div className='select-all'
                    style={{cursor: generatingBulkArticles && 'not-allowed'}}
                    onClick={selectAllArticleToGenerate}>Select {supportingPagesWithoutArticle.length > 1 ? `all ${supportingPagesWithoutArticle.length}`: supportingPagesWithoutArticle.length}
                  </div>
                  <div className='deselect-all'
                    style={{cursor: generatingBulkArticles && 'not-allowed'}}
                    onClick={deselectAllArticleToGenerate}>Deselect  {supportingPagesWithoutArticle.length > 1 ? 'all': ''}
                  </div>
                </div>
              </div>
              <div className='right-wrapper'>
                {
                  selectedArticlesToGenerate?.length ?
                    <ViewButton
                      style={{color: '#fff', backgroundColor: '#7F4EAD', width: 'auto', cursor: generatingBulkArticles && 'not-allowed'}}
                      onClick={()=> generatingBulkArticles ? {} : onGenerateBulkArticles(selectedArticlesToGenerate)}>
                      <FontAwesomeIcon icon={faRotate} color='#FFFFFF' fontSize={16}/>Generate {selectedArticlesToGenerate?.length} Article{`${selectedArticlesToGenerate?.length > 1 ? 's': ''}`}
                      {generatingBulkArticles && <StyledSpin color='#FFFFFF'/>}
                    </ViewButton> : <></>
                }
                <div className='cross-icon'>
                  <FontAwesomeIcon icon={faXmark} color='#4E5156' fontSize={16} onClick={() => setShowGenerateBulkHeader(false)}/>
                </div>
              </div>
            </BulkArticleHeader> : <></>}
            <Collapse.Panel key='open' header={<></>}>
              <StyledIssuesTable
                loading={ottoUrlLoader}
                columns={columns}
                dataSource={tableData}
                scroll={{x: 850}}
                pagination={{
                  onChange: (page, pageSize) => {
                    saveOttoTablePageSize(componentIssueType, pageSize);
                    setPageSize(pageSize);
                  },
                  pageSize: pageSize,
                  showSizeChanger: true,
                  pageSizeOptions: ['5', '10', '20', '50', '100'],
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} results shown`,
                }}
              />
            </Collapse.Panel>
          </StyledIssuesCollapse>
      }
      <FolderSettings
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        visible={openUpdateProjectModal}
        setVisible={setOpenUpdateProjectModal}
      />
      {
        openCreateNewTopicalMapModal && <CreateNewTopicalMap
          openModal={openCreateNewTopicalMapModal}
          setOpenModal={setOpenCreateNewTopicalMapModal}
          setOpenCreateTopicalMap={setOpenCreateTopicalMap}
          setOpenTopicalMapModal={setOpenTopicalMapModal}
          setTopicalMapName={setTopicalMapName}
        />
      }
      {
        openTopicalMapModal && <TopicalMapModal
          openTopicalMapModal={openTopicalMapModal}
          setOpenTopicalMapModal={setOpenTopicalMapModal}
          topicalMapName={topicalMapName}
          componentIssueType={componentIssueType}
        />
      }
      {
        showInstructionsModal && <WpInstructionsModal />
      }
      {openModal && <Wrapper>
        <Modal
          onClose={() => {
            setOpenModal(false);
            setDeleteModalData(null);
          }}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this record? </p>
            <p className={'warningText'}>This action cannot be undone.</p>

            <DeleteButton
              buttonType='transparent' size='lg'
              loading={storingSelectedTitles || deletingTopicalMap}
              onClick={async () => {
                if ('method' in deleteModalData && deleteModalData?.method === 'delete') {
                  onDeleteTopicalMap(deleteModalData?.id);
                } else if ('method' in deleteModalData && deleteModalData?.method === 'delete-idea') {
                  deleteArticleIdea(deleteModalData?.name, [...topicalMapArticle, {
                    id: deleteModalData?.id,
                    titleUuid: deleteModalData?.titleUuid,
                    title: deleteModalData?.name,
                    isLoading: true,
                    pageUuid: '',
                  }]);
                } else {
                  removeTitles(deleteModalData?.id, deleteModalData?.titles, deleteModalData?.item, deleteModalData?.index);
                }
              }}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => {
              setOpenModal(false);
              setDeleteModalData(null);
            }}
            buttonType='transparent'
            size='lg'>
            Go Back
            </BackButton>

          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});

const generateLinkConnectionHeight = (index, total) => {
  let output = '50%';

  if (total > 1) {
    output = '95%';
  }

  if (index == 0 && index == total - 1) {
    output = '60%';
  }

  if (index == total - 1) {
    output = '10%';
  }

  if (index === 0) {
    output = '91%';
  }

  return output;
};


const generateH3Margin = index => {
  let output = '0px';

  if (index == 0 ) {
    output = '10px 0 0 0';
  }

  return output;
};

const generateH2BorderRadius = (index, total, subHeading) => {
  let output = '0px';

  if (subHeading) {
    output = '6px';
  } else {
    if (index == 0 ) {
      output = '6px 6px 0px 0px';
    }
    if (index == total - 1) {
      output = '0px 0px 6px 6px';
    }
    if (total == 1) {
      output = '6px';
    }
  }
  return output;
};

const generateH3Border = (index, total, subHeading) => {
  const borderLeft = '1px solid #E8E8E8';
  const borderRight = '1px solid #E8E8E8';
  let borderTop = '1px solid transparent';
  const borderBottom = '1px solid #E8E8E8';

  if (!subHeading) {
    if (index == 0) {
      borderTop = '1px solid #E8E8E8';
    }

    if (index == total - 1 && total > 2) {
      borderTop = '1px solid transparent';
    }
  }

  return {borderTop, borderRight, borderBottom, borderLeft};
};

export const ItemWrapper = styled.div<{itemIndex: number; totalItems: number}>`
  display: flex;
  position: relative;

  .customConnection {
    border-right: 3px dashed rgba(78, 81, 86, 50%);
    color: transparent;
    height: ${p => generateLinkConnectionHeight(p.itemIndex, p.totalItems)} !important;
  }
  .keywords {
    max-width: 390px;
    flex-wrap: wrap;
    color: #4E5156;
    display: flex;
    gap: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
  .deletedItem {
    &::after {
      display: none !important;
    }
  }
`;

const ContentWrapper = styled.div<{itemIndex: number; totalItems: number}>`
  /* padding-bottom: 16px; */
  .wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .connection {
      width: 16px;
      height: 100% !important;
      border-top: 3px dashed rgba(78, 81, 86, 50%);
    }
  }

  .trash-icon {
    padding-right: 15px;
    color: #a3a4a4;
    margin-top: 7px;

    &:hover {
      color: #ff6262;
    }
  }

  .customLargeInput {
    display: flex;
    justify-content: space-between;
    background: white;
    padding-left: 10px !important;
    color: #121212 !important;
    font-size: 11px !important;
    width: 327px;
    margin: ${p => generateH3Margin(p.itemIndex)};
    border-radius: ${p => generateH2BorderRadius(p.itemIndex, p.totalItems, false)} !important;    
    border-top: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderTop} !important;
    border-right: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderRight} !important;
    border-bottom: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderBottom} !important;
    border-left: ${p => generateH3Border(p.itemIndex, p.totalItems, false).borderLeft} !important;

    margin-left: 10px !important;

    .suffix {
      font-weight: 500 !important;
      color: #a3a4a4;
      font-size: 11px;
      line-height: 1.5715;
      margin-top: 7px;
    }

    .custom-input {
      font-size: 11px !important;
      font-weight: 500 !important;
      line-height: 2.08 !important;
      border: none;

      :focus {
        box-shadow: unset;
      }
    }
    .ant-input-prefix {
      font-size: 12px !important;
      font-weight: 500 !important;
      color: #a3a4a4;
    }
  }
`;

export const StyledItemData = styled.div<{itemIndex: number; totalItems: number}>`
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #121212;
  position: relative;
  height: 100%;
  margin-left: 8px;
  padding-bottom: 16px;

  .first-item-arrow {
    position: absolute;
    left: -56px;
    top: 3px;
  }
  .first-item-arrow-topical {
    position: absolute;
    left: -48px;
    top: -35px;
  }
  
  .link-wrapper {
    position: absolute;
    left: -36px;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: #e8e8e8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    z-index: 1;
  }
  &::before {
    display: ${p => ((p.totalItems > 1) && (p.itemIndex === p.totalItems - 1)) && 'none'};
    content: '';
    position: absolute;
    width: 1px;
    top: ${p => p.itemIndex === 0 ? '5px' : '11px'};
    bottom: ${p => ((p.totalItems === 1) && (p.itemIndex === 0)) ? '75px' : '-11px'};
    left: -26px;
    border: 1px dashed #4E5156;
  }
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 20px;
    top: 9px;
    left: ${p => p.itemIndex === 0 ? '-52px' : '-25px'};
    border: 1px dashed #4E5156;
  }
  &.supporting-title-keywords-content {
    &::after {
      content: '';
      position: absolute;
      height: 1px;
      width: 20px;
      top: 9px;
      left: -24px !important;
      border: 1px dashed #4E5156;
    }
    &::before {
      display: ${p => ((p.totalItems > 1) && (p.itemIndex === p.totalItems - 1)) && 'none'};
      content: '';
      position: absolute;
      width: 1px;
      top: ${p => p.itemIndex === 0 ? '-26px' : '11px'};
      bottom: ${p => ((p.totalItems === 1) && (p.itemIndex === 0)) ? '89px' : '-11px'};
      left: -26px;
      border: 1px dashed #4E5156;
    }
  }
`;

export const GenerateButton = styled.div`
  font-size: 13px;
  color: #2D6CCA;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  height: 18px;
  padding: 5px 0px;
`;

export const AIGenerateButton = styled.div`
  width: fit-content;
  font-size: 13px;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px 10px;
  gap: 10px;
  background-color: #2D6CCA;
`;

const GeneratingTag = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4E5156;
`;


const TopicalMapContainer = styled.div`
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding: 10px 15px;
  gap: 25px;

  .connect-gsc {
    padding: 15px;
    border-radius: 12px;
    background: rgba(45, 108, 202, 0.1);
    flex: 1;
    .title {
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      color: #121212;
      margin-bottom: 10px;
    }
    .description {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #4E5156;
      margin-bottom: 10px;
    }
    .button {
      padding: 8px 15px;
      gap: 6px;
      border-radius: 6px;
      background: #2D6CCA;
      :hover {
        background: #003a90;
      }
    }
  }
  .create-tm {
    padding: 15px;
    border-radius: 12px;
    background: #E4E4E4;
    flex: 1;
    .title {
      font-family: Inter;
      font-size: 15px;
      font-weight: 500;
      line-height: 18.15px;
      color: #121212;
      margin-bottom: 10px;
    }
    .description {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #4E5156;
      margin-bottom: 10px;
    }
    .button {
      padding: 8px 15px;
      gap: 6px;
      border-radius: 6px;
      background: #4E5156;
      :hover {
        background: #212225;
      }
    }
  }
`;
// const StyledTag = styled.div`
//   font-family: 'Inter', sans-serif;
//   font-size: 12px;
//   font-weight: 700;
//   line-height: normal;
//   width: 22px;
//   height: 22px;
//   border-radius: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;
const ButtonStyled = styled(Button)<{background?: string; border?: string; color: string; hoverBg?: string}>`
  background-color:${p => p?.background ? p.background : ''} !important;
  border: ${p => p?.border ? p?.border : 'none'} !important;
  border-radius: 6px !important;
  line-height: normal !important;
  padding: 8px 12px;
  span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: ${p => p?.color ? p?.color : '#121212'} !important;
  }
  &:hover {
    background-color: ${p => p?.hoverBg ? p?.hoverBg : ''} !important;
  }
  &:focus {
    background-color: ${p => p?.hoverBg ? p?.hoverBg : ''} !important;
  }
  &:disabled {
    pointer-events: auto;
    cursor: not-allowed;
  }
`;
const StyledTopButtons = styled.div<{arrayLength: number}>`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-left: 8px;
  position: relative;
  margin-bottom: 10px;
  ${p => p?.arrayLength > 0 ? `
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 20px;
    top: 16px;
    left: -36px;
    border: 1px dashed #4E5156;
  }
  ` : ''}
`;

