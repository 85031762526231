import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

type props = {
  color?: string;
  fontSize?: number;
};
export const AntSpinIcon = ({color = '#2AC155', fontSize = 16}: props) => <LoadingOutlined style={{fontSize, color}} spin />;

export const StyledSpin = ({color, fontSize}: props) => <Spin style={{display: 'flex', alignItems: 'center'}} indicator={<AntSpinIcon color={color} fontSize={fontSize} />} />;
