import React, {useEffect, useRef, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {Radio, DatePicker, Button, Input, Upload, Spin, Tooltip, Select, Checkbox} from 'antd';
import {faPen, faRotate} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '@/store/root-store';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import moment from 'moment';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notification-v2';

const {Dragger} = Upload;
const {TextArea} = Input;

interface AddPostModalProps {
  openAddPostModal: boolean;
  setOpenAddPostModal: (value: boolean) => void;
  editing?: boolean;
  setEditing: (value: boolean) => void;
  data?: Record<string, any>;
}

export const AddPostModal: React.FC<AddPostModalProps> = ({openAddPostModal, setOpenAddPostModal, editing, data, setEditing}) => {
  const {ottoV2Store: {
    getOttoGBPAiGenerateContent,
    getOttoGBPAiGenerateImage,
    createOttoGBPPost,
    loadIssueTableData,
    ottoGBPUploadImage,
    editOttoGBPPost,
    publishOttoGBPPost,
  },
  settings: {
    customer: {getCustomerQuota},
  }} = useStore('');

  const [imageOption, setImageOption] = useState('ai-generate');
  const [contentOption, setContentOption] = useState('ai-generate');
  const [postingTimeOption, setPostingTimeOption] = useState('schedule-for-later');
  const [generatedImage, setGeneratedImage] = useState('');
  const [generatedContent, setGeneratedContent] = useState('');
  const [selectedImage, setSelectedImage] = useState<any>();
  const hiddenFileInput = useRef(null);
  const [imagePrompt, setImagePrompt] = useState('');
  const [contentPrompt, setContentPrompt] = useState('');
  const [generatingContent, setGeneratingContent] = useState(false);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [imgSizeError, setImgSizeError] = useState('');
  const [ignoreBusiness, setIgnoreBusiness] = useState(false);
  const [showEditingImage, setShowEditingImage] = useState(true);
  const initialErrorState = {
    'content': {
      message: 'Please add content for the post.',
      state: false,
    },
    'date': {
      message: 'Please select schedule date for post.',
      state: false,
    },
    'ctaButton': {
      message: 'Cta url is required',
      state: false,
    },
  };
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const [writtenContent, setWrittenContent] = useState('');
  const [addingPost, setAddingPost] = useState(false);
  const [date, setDate] = useState<any>();
  const uuid = getSingleUrlParam('uuid');
  const [ctaType, setCtaType] = useState('none');
  const [ctaUrl, setCtaUrl] = useState('');

  useEffect(() => {
    if (generatedContent || writtenContent) {
      setErrorStates({...errorStates, content: {...errorStates['content'], state: false}});
    }
  }, [generatedContent, writtenContent]);

  // useEffect use to set data for editing post
  useEffect(() => {
    if (editing) {
      if (data?.imageUrl) {
        setImageOption('upload');
      }
      if (data?.recommendedValue) {
        setContentOption('write-your-own');
        setWrittenContent(data?.recommendedValue);
      }
      if (data?.schedulePublishingAt && moment(data?.schedulePublishingAt) > moment()) {
        setPostingTimeOption('schedule-for-later');
        setDate(moment(data?.schedulePublishingAt));
      } else {
        setPostingTimeOption('post-now');
        setDate(moment());
      }
      setCtaType(data?.callToActionType === '' ? 'none' : data?.callToActionType ? data?.callToActionType : 'none');
      setCtaUrl(data?.callToActionUrl ?? '');
      setSelectedImage('');
    } else {
      resetStates();
    }
  }, [editing]);

  const resetStates = () => {
    setEditing(false);
    setImageOption('ai-generate');
    setContentOption('ai-generate');
    setPostingTimeOption('schedule-for-later');
    setGeneratedImage('');
    setGeneratedContent('');
    setGeneratedContent(null);
    setImagePrompt('');
    setContentPrompt('');
    setGeneratingContent(false);
    setGeneratingImage(false);
    setErrorStates(initialErrorState);
    setWrittenContent('');
    setAddingPost(false);
    setDate(null);
    setCtaUrl('');
    setCtaType('none');
    setIgnoreBusiness(false);
  };

  const handleImageOption = (e:any) => {
    setImageOption(e.target.value);
  };

  const handleContentOption = (e:any) => {
    setContentOption(e.target.value);
    setErrorStates({...errorStates, content: {...errorStates['content'], message: 'Please add content for the post.', state: false}});
  };

  const handlePostingTimeOption = (e:any) => {
    setPostingTimeOption(e.target.value);
    setErrorStates({...errorStates, date: {...errorStates['date'], state: false}});
  };

  // const textAreas = document.getElementsByTagName('textarea');

  // Array.prototype.forEach.call(textAreas, function(elem) {
  //   elem.placeholder = elem.placeholder.replace(/\\n/g, '\n');
  // });

  const imageGenerate = async () => {
    setGeneratingImage(true);
    try {
      const response = await getOttoGBPAiGenerateImage({prompt: imagePrompt});
      setGeneratedImage(response);
      getCustomerQuota();
      setGeneratingImage(false);
    } catch (err) {
      setGeneratingImage(false);
    }
  };

  const contentGenerate = async () => {
    setGeneratingContent(true);
    try {
      const response = await getOttoGBPAiGenerateContent({prompt: contentPrompt, override: ignoreBusiness});
      setGeneratedContent(response);
      getCustomerQuota();
      setGeneratingContent(false);
    } catch (err) {
      setGeneratingContent(false);
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };
  const handleContentPrompt = value => {
    setContentPrompt(value);
    setErrorStates(initialErrorState);
  };

  const AddPost = async () => {
    if (imageOption === 'ai-generate' && contentOption === 'ai-generate' && postingTimeOption === 'schedule-for-later' && (!generatedContent || !date)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please provide prompt to generate content by AI', state: !generatedContent ? true : false},
        date: {...errorStates['date'], state: !date ? true : false},
      });
      return;
    } else if (imageOption === 'ai-generate' && contentOption === 'ai-generate' && postingTimeOption === 'post-now' && (!generatedContent)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please provide prompt to generate content by AI', state: !generatedContent ? true : false},
      });
      return;
    } else if (imageOption === 'ai-generate' && contentOption === 'write-your-own' && postingTimeOption === 'post-now' && (!writtenContent)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please add content for the post.', state: !writtenContent ? true : false},
      });
      return;
    } else if (imageOption === 'ai-generate' && contentOption === 'write-your-own' && postingTimeOption === 'schedule-for-later' && (!writtenContent || !date)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please add content for the post.', state: !writtenContent ? true : false},
        date: {...errorStates['date'], state: !date ? true : false},
      });
      return;
    } else if (imageOption === 'upload' && contentOption === 'ai-generate' && postingTimeOption === 'schedule-for-later' && (!generatedContent || !date)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please provide prompt to generate content by AI', state: !generatedContent ? true : false},
        date: {...errorStates['date'], state: !date ? true : false},
      });
      return;
    } else if (imageOption === 'upload' && contentOption === 'ai-generate' && postingTimeOption === 'post-now' && (!generatedContent)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please provide prompt to generate content by AI', state: !generatedContent ? true : false},
      });
      return;
    } else if (imageOption === 'upload' && contentOption === 'write-your-own' && postingTimeOption === 'post-now' && (!writtenContent)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please add content for the post.', state: !writtenContent ? true : false},
      });
      return;
    } else if (imageOption === 'upload' && contentOption === 'write-your-own' && postingTimeOption === 'schedule-for-later' && (!writtenContent || !date)) {
      setErrorStates({...errorStates,
        content: {...errorStates['content'], message: 'Please add content for the post.', state: !writtenContent ? true : false},
        date: {...errorStates['date'], state: !date ? true : false},
      });
      return;
    } else if (ctaType!=='none' && ctaType!=='CALL' && !ctaUrl?.length) {
      setErrorStates({...errorStates, ctaButton: {...errorStates['ctaButton'], message: 'Cta url is required', state: true}});
      return;
    } else if (ctaType!=='none' && ctaType!=='CALL' && !validateURL(ctaUrl)) {
      setErrorStates({...errorStates, ctaButton: {...errorStates['ctaButton'], message: 'Please enter a valid url', state: true}});
      return;
    }
    try {
      setAddingPost(true);

      const payload = {
        content: contentOption === 'ai-generate' ? generatedContent : writtenContent,
        image_url: imageOption === 'ai-generate' ? generatedImage : selectedImage,
      };

      if (ctaType !== 'none') {
        payload['call_to_action_url'] = ctaType === 'CALL' ? '' : addProtocolToDomainHttps(ctaUrl);
        payload['call_to_action_type'] = ctaType;
      } else {
        payload['call_to_action_type'] = '';
      }

      if (imageOption === 'ai-generate' && generatedImage) {
        payload['image_url'] = generatedImage;
      } else {
        if (selectedImage) {
          const formData = new FormData();
          formData.append('image', selectedImage);
          formData.append('format', 'JPEG');
          const response = await ottoGBPUploadImage(formData);
          payload['image_url'] = response;
        } else {
          payload['image_url'] = (editing && showEditingImage && data?.imageUrl) ? data?.imageUrl : null;
        }
      }
      if (postingTimeOption === 'schedule-for-later' && date) {
        payload['schedule_publishing_at'] = moment(date).format('YYYY-MM-DD');
      } else {
        payload['schedule_publishing_at'] = moment().format('YYYY-MM-DD');
      }
      let response;
      if (editing) {
        response = await editOttoGBPPost(data?.id, payload);
        if (postingTimeOption === 'post-now') {
          const payload = {
            id: data?.id,
            imageUrl: data?.imageUrl,
            schedulePublishingAt: data?.schedulePublishingAt,
            content: data?.content,
          };
          await publishOttoGBPPost(payload);
        }
        notification.success('', `Post ${postingTimeOption === 'post-now' ? 'updated & published' : 'updated'} successfully`);
      } else {
        if (postingTimeOption === 'post-now') {
          response = await createOttoGBPPost(payload);
          if (response && response?.id) {
            await publishOttoGBPPost({
              id: response?.id,
              imageUrl: response?.image_url,
              schedulePublishingAt: response?.schedule_publishing_at,
              content: response?.content,
            });
          }
          notification.success('', 'Post is publishing');
        } else {
          response = await createOttoGBPPost(payload);
          notification.success('', 'Post created successfully');
        }
      }
      setGeneratedContent(response);
      setAddingPost(false);
      setOpenAddPostModal(false);
      resetStates();
      const params = {
        uuid,
        issue_type: 'gbp_posts',
        page_size: 10,
        page: 1,
        is_loading: false,
      };
      // isSitewide is related to alt image text
      await loadIssueTableData(params, false);
    } catch (err) {
      setAddingPost(false);
    }
  };

  const selectedImageUrl = selectedImage ? URL.createObjectURL(selectedImage) : '';

  const options = [
    {label: 'None', value: 'none'},
    {label: 'Book', value: 'BOOK'},
    {label: 'Order online', value: 'ORDER'},
    {label: 'Buy', value: 'SHOP'},
    {label: 'Learn more', value: 'LEARN_MORE'},
    {label: 'Sign up', value: 'SIGN_UP'},
    {label: 'Call now', value: 'CALL'},
  ];

  function validateURL(url) {
    const urlPattern = new RegExp(
      /^(https?:\/\/)?((?!localhost)[\w.-]+)\.([a-z]{2,})(:\d{1,5})?(\/.*)?$/i,
    );

    return urlPattern.test(url);
  }

  return (
    <StyledTopicalMapsModal
      width={'560px'}
      closable={true}
      onClose={() => {
        setOpenAddPostModal(false);
        resetStates();
      }}
      closeIcon={<div><FontAwesomeIcon color={'#A3A4A4'} fontSize={22} icon={faXmark}/></div>}
      visible={openAddPostModal}
    >
      <StyledModalHeader>
        <div className='modal-heading'>{editing ? 'Edit': 'Add'} Post</div>
      </StyledModalHeader>
      <FieldWrapper>
        <div className='label'>Image:</div>
        <div className='field-container'>
          <div className='header'>
            <StyledRadioGroup onChange={handleImageOption} value={imageOption}>
              <Radio.Button value='ai-generate'>AI Generate</Radio.Button>
              <Radio.Button value='upload'>Upload</Radio.Button>
            </StyledRadioGroup>
            {(generatedImage && imageOption === 'ai-generate') ?
              <div className='right-section'>
                <div className='button' onClick={imageGenerate}>
                  <FontAwesomeIcon icon={faRotate} fontSize={12} className={generatingImage ? 'fa-spin': ''} color='#2D6CCA'/>
                  Try again
                </div>
                <div className='button' onClick={()=> setGeneratedImage('')}>
                  <FontAwesomeIcon icon={faPen} fontSize={12} color='#2D6CCA'/>
                  Edit prompt
                </div>
              </div> : (((editing && showEditingImage && data?.imageUrl) || selectedImage) && imageOption === 'upload') ? <div className='right-section'>
                <div className='button' onClick={() => {
                  if (editing && showEditingImage && data?.imageUrl) {
                    setShowEditingImage(false);
                  } else {
                    setSelectedImage('');
                  }
                  setImgSizeError('');
                }}>
                  <FontAwesomeIcon icon={faTrash} fontSize={12} color='#2D6CCA'/>
                 Delete
                </div>
              </div> : <></>}
          </div>
          {imageOption === 'ai-generate' ?
            <>
              <div className='body' style={{padding: generatedImage ? 0 : ''}}>
                {
                  generatedImage ?
                    <img src={generatedImage ?? ''} style={{width: '100%', borderRadius: '6px'}} /> :
                    <>
                      <StyledTextArea bordered={false} rows={2}
                        autoSize={{minRows: 2, maxRows: 2}}
                        value={imagePrompt}
                        onChange={e => setImagePrompt(e?.target?.value)}
                        placeholder='Describe your image to the AI. e.g A group of happy people in a park...' />
                      <Tooltip title={!imagePrompt && 'Describe image to generate by AI.'}>
                        <GenerateButton onClick={imageGenerate} loading={generatingImage} disabled={generatingImage || !imagePrompt}>Generate Image</GenerateButton>
                      </Tooltip>
                    </>
                }
              </div>
            </> :
            <>
              {
                ((editing && showEditingImage) ? (selectedImage && selectedImage['name']) || data?.imageUrl : selectedImage && selectedImage['name']) ?
                  <div className='body' style={{padding: 0, borderColor: imgSizeError ? 'red' : ''}}>
                    <FontAwesomeIcon icon={faRotate} fontSize={12} color='#e1e1e1'onClick={handleClick} className='replace-image'/>
                    <input
                      type='file'
                      ref={hiddenFileInput}
                      accept='image/png, image/jpeg'
                      onClick={ (event:any) => {
                        event.target.value = null;
                      }}
                      onChange={e => {
                        if (e?.target?.files[0]) {
                          setSelectedImage(e?.target?.files[0]);
                          if (e?.target?.files[0]?.size >= 500000 && e?.target?.files[0]?.size <= 2000000) {
                            setImgSizeError('');
                          } else {
                            if (e?.target?.files[0]?.size < 500000) {
                              setImgSizeError('less_than_500');
                            } else if (e?.target?.files[0]?.size > 2000000) {
                              setImgSizeError('greater_than_2mb');
                            }
                          }
                        }
                      }}
                      style={{display: 'none'}}
                    />
                    <img src={(editing && showEditingImage && data?.imageUrl) ? (data?.imageUrl || selectedImageUrl) : selectedImageUrl} style={{width: '100%', borderRadius: '6px'}} />
                  </div> :
                  <>
                    <StyledDragger
                      multiple={false}
                      showUploadList={false}
                      accept='image/png, image/jpeg'
                      customRequest={(data:any) => {
                        setSelectedImage(data?.file);
                        if (data?.file?.size >= 500000 && data?.file?.size <= 2000000) {
                          setImgSizeError('');
                        } else {
                          if (data?.file?.size < 500000) {
                            setImgSizeError('less_than_500');
                          } else if (data?.file?.size > 2000000) {
                            setImgSizeError('greater_than_2mb');
                          }
                        }
                      }}
                    >
                      <DragContainer>
                        <img src='/icons/drop-upload.svg'/>
                        <div className='container'>
                          <TitleStyled>
                          Drop your png and jpeg image here,
                          </TitleStyled>
                          <StepStyled>
                          or&nbsp;<span style={{textDecoration: 'underline', color: '#2D6CCA'}}>click to browse</span>
                          </StepStyled>
                        </div>
                      </DragContainer>
                    </StyledDragger>
                    {errorStates['image']?.state ? <div className='error-message'>{errorStates['image']?.message}</div> : <></>}
                  </>
              }
              {imgSizeError == 'less_than_500' && <div style={{color: 'red'}}>Image size should not be less than 500KB</div>}
              {imgSizeError == 'greater_than_2mb' && <div style={{color: 'red'}}>Image size should not be greater than 2MB</div>}
            </>
          }
        </div>
      </FieldWrapper>
      <FieldWrapper>
        <div className='label'>Content:</div>
        <div className='field-container'>
          <div className='header'>
            <StyledRadioGroup onChange={handleContentOption} value={contentOption}>
              <Radio.Button value='ai-generate'>AI Generate</Radio.Button>
              <Radio.Button value='write-your-own'>Write your own</Radio.Button>
            </StyledRadioGroup>
            {
              generatedContent ?
                <div className='right-section'>
                  <div className='button' onClick={contentGenerate}>
                    <FontAwesomeIcon icon={faRotate} fontSize={12} className={generatingContent ? 'fa-spin': ''} color='#2D6CCA'/>
                    Try again
                  </div>
                  <div className='button' onClick={()=> setGeneratedContent('')}>
                    <FontAwesomeIcon icon={faPen} fontSize={12} color='#2D6CCA'/>
                    Edit prompt
                  </div>
                </div> : <></>
            }
          </div>
          {
            contentOption === 'ai-generate' ?
              <div className='body' style={{padding: generatedContent ? 0 : '', borderColor: errorStates['content'].state ? '#f96262': ''}}>
                {
                  generatedContent ?
                    <StyledTextArea bordered={false} rows={2}
                      autoSize={{minRows: 2, maxRows: 5}}
                      value={generatedContent}
                      onChange={e => setGeneratedContent(e.target.value)}
                    /> :
                    <>
                      <StyledTextArea bordered={false} rows={2}
                        autoSize={{minRows: 2, maxRows: 2}}
                        value={contentPrompt}
                        onChange={e => handleContentPrompt(e?.target?.value)}
                        placeholder='What should the post be about? e.g ABC Corp is organizing a meeting in the Central Park...' />
                      <Tooltip title={!contentPrompt && 'Write something to generate by AI.'}>
                        <GenerateButton onClick={contentGenerate} loading={generatingContent} disabled={generatingContent || !contentPrompt} >Generate Content</GenerateButton>
                      </Tooltip>
                    </>
                }
              </div>:
              <div className='body' style={{borderColor: errorStates['content'].state ? '#f96262': ''}}>
                <StyledTextArea bordered={false} rows={2}
                  placeholder='Write the post content here...'
                  autoSize={{minRows: 2, maxRows: 5}}
                  value={writtenContent}
                  onChange={e => setWrittenContent(e?.target?.value)}
                />
              </div>
          }
          {errorStates['content'].state ? <div className='error-message'>{errorStates['content'].message}</div> : <></>}
          {contentOption === 'ai-generate' && (
            <Tooltip
              overlayInnerStyle={{width: '390px', borderRadius: '8px', backgroundColor: '#24262A', color: '#FFFFFF', fontFamily: 'Inter, sans-serif', fontWeight: 400, fontSize: '12px'}}
              title={`The AI-generated post content will remain uninfluenced by the company's description information.`}
              placement='bottomLeft'>
              <ContentCheckbox>
                <Checkbox checked={ignoreBusiness} onChange={e => setIgnoreBusiness(e?.target?.checked)}/>Ignore business background info
              </ContentCheckbox>
            </Tooltip>
          )}
        </div>
      </FieldWrapper>
      <FieldWrapper>
        <div className='label'>CTA Button:</div>
        <div className='field-container'>
          <StyledSelect
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onChange={e => {
              if (e === 'none') {
                setErrorStates({...errorStates, ctaButton: {...errorStates['ctaButton'], state: false}});
              }
              setCtaType(e as string);
            }}
            value={ctaType}
          >
            {options.map((item, index) => (
              <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
            ))}
          </StyledSelect>
          {ctaType !== 'none' && ctaType !== 'CALL' && <>
            <StyledInput
              placeholder='Link for your button'
              onChange={e => {
                const value = e.target.value;
                if (!validateURL(value)) {
                  setErrorStates({...errorStates, ctaButton: {...errorStates['ctaButton'], message: 'Please enter a valid url', state: true}});
                }
                if (!value.length || validateURL(value)) {
                  setErrorStates({...errorStates, ctaButton: {...errorStates['ctaButton'], state: false}});
                }
                setCtaUrl(e.target.value);
              }}
              value={ctaUrl}
              redBorder={errorStates['ctaButton'].state}
            />
            <div className='example'>(Example google.com)</div>
          </>}
          {errorStates['ctaButton'].state ? <div className='error-message'>{errorStates['ctaButton'].message}</div> : <></>}
        </div>
      </FieldWrapper>
      <FieldWrapper>
        <div className='label'>Posting time:</div>
        <div className='field-container'>
          <div className='header'>
            <StyledRadioGroup onChange={handlePostingTimeOption} value={postingTimeOption}>
              <Radio.Button value='schedule-for-later'>Schedule for later</Radio.Button>
              <Radio.Button value='post-now'>Post now</Radio.Button>
            </StyledRadioGroup>
          </div>
          {
            postingTimeOption === 'schedule-for-later' ?
              <div className='body' style={{padding: '5px 10px', gap: '10px', display: 'flex', borderColor: errorStates['date'].state ? '#f96262': ''}}>
                <DatePicker placeholder='Date' suffixIcon={<CaretDownOutlined />} size={'small'} format={'D MMM, YYYY'}
                  className='date'
                  bordered={false} showToday={false}
                  disabledDate={currentDate => currentDate <= moment().subtract(1, 'days')}
                  value={date}
                  onChange={(e: any) => {
                    setDate(e);
                    setErrorStates({...errorStates, date: {...errorStates['date'], state: false}});
                  }}
                  allowClear={false} />
                {/* <div>at</div>
                <TimePicker
                  size={'small'}
                  className='time'
                  bordered={false}
                  placeholder='Time'
                  // showNow={false}
                  // onOk={() => setAssignedToForm(record?.uuid)}
                  allowClear={false}
                  use12Hours format='h:mm A'
                  // onChange={e => {
                  //   onChangeTime(e, record?.uuid);
                  // }}
                  suffixIcon={<CaretDownOutlined />} /> */}
              </div>:<div style={{marginBottom: '15px'}}></div>
          }
          {
            errorStates['date'].state ? <div className='error-message'>{errorStates['date'].message}</div> : <></>
          }
        </div>
      </FieldWrapper>
      <StyledModalFooter>
        <div className='cancel-btn' onClick={() => {
          setOpenAddPostModal(false);
          resetStates();
        }}>
            Cancel
        </div>
        <Tooltip overlayInnerStyle={{background: '#000'}} title={(generatingImage && imageOption === 'ai-generate' && !generatingContent) ? 'Please wait while the AI generates the image.' : ''}>
          <span>
            <ButtonComponent
              color='blue'
              style='solid'
              type='button'
              className='update-button'
              onClick={AddPost}
              disabled={addingPost || (generatingImage && imageOption === 'ai-generate' && !generatingContent) || imageOption === 'upload' && (imgSizeError == 'less_than_500' || imgSizeError == 'greater_than_2mb')}
            >
              {
                postingTimeOption === 'schedule-for-later' ? 'Schedule' : 'Post Now'
              }
              {
                addingPost ? <Spin indicator={<LoadingOutlined style={{fontSize: '6', color: 'white', marginLeft: 5}} spin />} /> : <></>
              }
            </ButtonComponent>
          </span>
        </Tooltip>
      </StyledModalFooter>
    </StyledTopicalMapsModal>
  );
};

const StyledTopicalMapsModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: 6px;
    top: 6px;
    font-weight: 700;
    color: #A3A4A4 !important;
    opacity: 1;
    &:hover {
      opacity: 0.5;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 16px;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 8px;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap;
  margin: 10px 0;
  .label {
    width: 70px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.94px;
    color: #121212;
    margin-top: 8px;
  }
  .field-container {
    flex: 1;
    .header {
      display: flex;
      justify-content: space-between;
      .right-section {
        align-items: center;
        display: flex;
        gap: 10px;
        .button {
          align-items: center;
          display: flex;
          gap: 10px;
          font-family: Inter;
          font-size: 12px;
          font-weight: 400;
          color:#2D6CCA;
          cursor: pointer;
          line-height: normal;
        }
      }
    }
    .body {
      border: 1px solid #D8D8D8;
      border-radius: 8px;
      padding: 10px;
      width: 100%;
      margin: 5px 0;
      background-color: #ffffff;

      .date {
        width: 105px;
      }
      .time {
        width: 85px;
      }
      .ant-picker-small {
        padding: 0px 0px 0px !important;
      }
      .replace-image {
        padding: 6px;
        background: #4E5156;
        border-radius: 6px;
        position: absolute;
        right: 33px;
        top: 131px;
        cursor: pointer;
      }

      .domain-input-wrapper {
        display: flex;
        align-items: center;
        .domain {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.94px;
          text-align: left;
          color: #A3A4A4;
          padding-left: 10px;
        }
        .slug {
          padding: 5px 0px;
        }
      }
    }
    .error-message {
      color: red;
      padding-left: 4px;
    }
    .example {
      margin-left: 15px;
      margin-top: 2px;
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #4E5156;
    }
  }
`;

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    padding: 0px 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    color: #121212;
    &.ant-radio-button-wrapper-checked {
      background: #2D6CCA26;
      :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
    }
    :focus {
        outline: none;
        box-shadow: none;
      }
      :focus-within {
        outline: none;
        box-shadow: none;
      }
      :hover {
        color: #121212;
      }
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px 8px 8px 0px;
  }
`;

const GenerateButton = styled(Button)`
  padding: 3px 12px;
  border-radius: 6px;
  background:#4E5156;
  color: #ffffff;
  :hover {
    background:#4E5156;
  }
`;
const StyledModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .cancel-btn {
    width: 47px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #4E5156;
    cursor: pointer;
  }
`;

const StyledTextArea = styled(TextArea)`
  height: 100%;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const DragContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;


const StepStyled = styled.div`
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #4E5156;
`;

const TitleStyled = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #4E5156;
`;

const StyledDragger = styled(Dragger)`
  border-radius: 8px !important;
  padding: 10px;
  margin: 15px 0;
  border: 3px dashed #d9d9d9 !important;
  .ant-upload {
    padding: 2px 0 !important;
  }
  .ant-upload-drag {
    height: 50px;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 5px;
  .ant-select-selector {
    height: 38px !important;
    border-radius: 8px !important;
  }
  .ant-select-selection-item {
    padding-top: 4px !important;
  }
`;

const StyledInput = styled(Input)<{redBorder?: boolean}>`
  border-radius: 8px;
  height: 38px;
  border-color: ${p => p.redBorder ? 'red' : ''};
`;
const ContentCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #121212;
  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 5px !important;
        overflow: hidden;
      }
      &.ant-checkbox-checked::after {
        border-radius: 5px !important;
      }
    }
  }
`;
