import {types, flow, cast, Instance} from 'mobx-state-tree';
import {invoicesApi, stripeApi} from '@/api/account/';
import moment from 'moment';
import {getApiUrl, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {convertDate} from '@/utils/filters';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {apiError} from '@/utils/api';

export const plan = types.model({

  id: types.maybeNull(types.number),
  monthlyCost: types.maybeNull(types.string),
  monthlyCostCurrency: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});
export const OrderCustomer = types.model({
  companyName: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
});
export const InvoiceDetailOrder = types.model({
  budget: types.maybeNull(types.string),
  cost: types.maybeNull(types.union(types.string, types.number)),
  customer: types.maybeNull(OrderCustomer),
  description: types.maybeNull(types.string),
  plan: types.maybeNull(types.string),
});

export const OrderInvoiceDetail = types.model({
  amount: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  paidAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  orderId: types.maybeNull(types.number),
  slug: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  dueDate: types.maybeNull(types.string),
  plan: types.maybeNull(plan),
  discountCode: types.maybeNull(types.string),
});

export type OrderInvoiceDetailType = Instance<typeof OrderInvoiceDetail>;

export const InvoiceDetail = types.model({
  amount: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  order: types.maybeNull(InvoiceDetailOrder),
  paidAt: types.maybeNull(types.string),
  rejectionReason: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});

export const InvoicePDF = types.model({
  amount: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  order: types.maybeNull(InvoiceDetailOrder),
  paidAt: types.maybeNull(types.string),
  rejectionReason: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});

export const Plan = types.model({
  amount: types.maybeNull(types.string),
  amountCurrency: types.maybeNull(types.string),
  statedAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
});

export const currentPlan = types.model({
  id: types.number,
  name: types.string,
  monthlyCostCurrency: types.string,
  monthlyCost: types.maybeNull(types.union(types.number, types.string)),
  started: types.maybeNull(types.string),
  nextBilling: types.maybeNull(types.string),
  quotaKeAllowedBulkAnalysisUrls: types.maybeNull(types.number),
});

export const Payment = types.model({
  brand: types.string,
  expMonth: types.number,
  expYear: types.number,
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  last4: types.string,
  isPrimary: types.maybeNull(types.boolean),
  paymentMethodId: types.maybeNull(types.number),
  lastUsedAt: types.maybeNull(types.string),
});

export const PaymentForBill = types.model({
  billBankaccountAccountLast4: types.maybeNull(types.string),
  billBankaccountRoutingLast4: types.maybeNull(types.string),
  billNameOnBankaccount: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  isPrimary: types.maybeNull(types.boolean),
  paymentMethodId: types.maybeNull(types.number),
  lastUsedAt: types.maybeNull(types.string),
});
export const Invoices = types.model({
  count: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.number),
  orderInvoices: types.maybeNull(types.array(OrderInvoiceDetail)),
  invoiceDetail: types.maybeNull(InvoiceDetail),
  invoicePDF: types.maybeNull(InvoicePDF),
  plan: types.array(Plan),
  visibleModal: types.boolean,
  currentPlan: types.maybeNull(currentPlan),
  paymentMethod: types.maybeNull(Payment),
  paymentOptions: types.array(Payment),
  paymentOptionsBill: types.array(PaymentForBill),
  country: types.string,
  state: types.string,
  loading: types.boolean,
  serverErrors: types.array(types.string),
  updateModal: types.boolean,
  currentPlanLoading: types.boolean,
  currentPaymentLoading: types.boolean,
  oneTimeOpen: types.boolean,
  deleteLoading: types.maybeNull(types.boolean),
}).views(self => ({

  get showInvoices() {
    return toJS(self.orderInvoices);
  },

  get getPlan() {
    return self.currentPlan;
  },

  get getCurrentPlanLoading() {
    return self.currentPlanLoading;
  },


  get getCurrentPaymentLoading() {
    return self.currentPaymentLoading;
  },

  get getDetailInvoice() {
    return self.invoiceDetail;
  },

  get getDetailPDF() {
    return self.invoicePDF;
  },

  get getPaymentMethod() {
    return self.paymentMethod;
  },
  get getPaymentOptions() {
    return self.paymentOptions.slice();
  },
  get getPaymentOptionsForBill() {
    return self.paymentOptionsBill.slice();
  },
  get getCountry() {
    return self.country;
  },

  get getState() {
    return self.state;
  },

  get invoicesList() {
    const data = [];
    self.orderInvoices.map(item => {
      data.push({
        id: item.id,
        date: item.createdAt ? moment(item.createdAt).format('MMM-DD-YYYY') : '-',
        invoice: item.slug,
        // currently plan set static due to no data in Invoice List API
        plan: item.plan ? item.plan.name : '',
        price: `$${item.amount}`,
        dueDate: item.dueDate ? item.dueDate : '-',
      });
    });
    return data;
  },

  get orderInvoicesList() {
    const data = [];
    self.orderInvoices?.map(item => {
      data.push({
        id: item.id,
        date: item.createdAt ? moment(item.createdAt).format('MMM-DD-YYYY') : '-',
        invoice: item.slug,
        // currently plan set static due to no data in Invoice List API
        description: item.description ? item.description : '',
        status: item.status,
        price: `$${item.amount}`,
        dueDate: item.dueDate ? item.dueDate : '-',
      });
    });
    return data;
  },

  get getPendingInvoices() {
    const orderinvoice= self.orderInvoices?.slice() || [];
    const invoiceOrder = orderinvoice?.filter(invoice => invoice?.status !== 'PAID' && invoice?.amount !== '0.00') || [];
    return invoiceOrder;
  },
  get getAllOrderInvoices() {
    const data = [];
    self.orderInvoices?.map(item => {
      if (Number(item.amount) > 0) {
        data.push({
          id: item.id,
          date: item.createdAt ? moment(item.createdAt).format('MMM-DD-YYYY') : '-',
          createdAt: item.createdAt,
          slug: item.slug,
          type: item.type,
          // currently plan set static due to no data in Invoice List API
          description: item.description ? item.description : '',
          status: item.status,
          plan: item.plan ? item.plan.name : '',
          price: item.amount,
          dueDate: item.dueDate ? item.dueDate : '-',
          discountCode: item?.discountCode,
        });
      }
    });
    return toJS(data);
  },
  get getOrderInvoices() {
    return toJS(self.orderInvoices);
  },
  get isLoading() {
    return self.loading;
  },

  get getServerErrors() {
    return self.serverErrors;
  },

  get getOrderUnpaidNumber() {
    return self.orderInvoices?.filter(invoice => invoice.status == 'PENDING').length;
  },


  get getUpdateModal() {
    return self.updateModal;
  },

  filterInvoice(query: string) {
    const formattedQuery = query.toLowerCase();
    const filterData = [...(self.orderInvoices ?? [])]?.filter(record => record?.amount?.toLowerCase().includes(formattedQuery) ||
      record?.id?.toString()?.includes(formattedQuery) ||
      // @ts-ignore
      record?.plan?.name.toLowerCase()?.includes(formattedQuery) ||
      record?.status.toLowerCase()?.includes(formattedQuery),
    );
    const data = [];
    filterData?.map(item => {
      data.push({
        id: item.id,
        date: item.createdAt ? convertDate(item.createdAt) : '-',
        createdAt: item.createdAt,
        invoice: item.slug,
        // @ts-ignore
        plan: item?.plan ? item?.plan.name : '',
        slug: item.slug,
        price: item.amount,
        status: item.status,
        type: item.type,
        dueDate: item.dueDate ? item.dueDate : '-',
      });
    });
    return data;
  },

  filterInvoiceData(query: string) {
    const formattedQuery = query.toLowerCase();
    const filterData = toJS(self.orderInvoices)?.filter(record => record?.amount?.toLowerCase().includes(formattedQuery) ||
      convertDate(record?.paidAt ? record?.paidAt : record?.createdAt).toLowerCase()?.includes(formattedQuery) ||
      record?.status.toLowerCase()?.includes(formattedQuery),

    );
    const data = [];
    filterData?.map(item => {
      data.push({
        id: item.id,
        paidAt: item.paidAt ? convertDate(item.paidAt) : convertDate(item.createdAt),
        slug: item.slug,
        amount: item.amount,
        status: item.status,
        type: item.type,
        description: item.description,
      });
    });
    return data;
  },


})).actions(self => {
  const resetServerErrors = ()=> {
    self.serverErrors = cast([]);
  };

  const loadOrderInvoices = flow(function* (type) {
    try {
      const response = yield invoicesApi.loadOrderInvoices(type);
      const orderLists = response?.results?.map(item => {
        return {
          amount: item.amount,
          id: item.id,
          paidAt: item.paidAt,
          createdAt: item.createdAt,
          slug: item.slug,
          address: item.address,
          name: item.order?.customer?.contactName,
          orderId: item.order?.id,
          status: item.status,
          type: item.type,
          description: item.order?.description,
          dueDate: item?.dueDate,
          discountCode: item?.discountCode,
        };
      });
      // @ts-ignore
      self.orderInvoices = orderLists?.sort((a, b) => moment(a.paidAt) - moment(b.paidAt));
    } catch (e) {
      self.orderInvoices = null;
      const errorMessage = apiError(e, 'Failed to get order invoices') as string;
      notification.error('', errorMessage);
    }
  });

  const getCurrentPlan = flow(function* () {
    try {
      self.currentPlanLoading = true;
      const response = yield invoicesApi.loadCurrentPlan();
      if (!('detail' in response)) {
        self.currentPlan = response?.plan ? cast({
          ...response?.plan,
          monthlyCost: response?.nextCharge || '',
        }) : null;
        self.currentPlan.started = self.currentPlan && moment(response?.activatedAt).format('MMM DD, YYYY');
        self.currentPlan.nextBilling = self.currentPlan && moment(response?.nextPaymentAt).format('MMM DD, YYYY');
      }
    } catch (e) {
      self.currentPlanLoading = false;
    } finally {
      self.currentPlanLoading = false;
    }
  });

  const getInvoiceDetail = flow(function* (invoiceSlug) {
    try {
      const response = yield invoicesApi.fetchInvoiceDetail(invoiceSlug);
      self.invoiceDetail = cast(response);
    } catch (e) {
      self.invoiceDetail = null;
      throw e;
    }
  });

  const getInvoicePDF = flow(function* (invoiceSlug) {
    try {
      const response = yield invoicesApi.fetchInvoicePDF(invoiceSlug);
      self.invoicePDF = cast(response);
    } catch (e) {
      self.invoicePDF = null;
      throw e;
    }
  });
  const getInvoiceLink = (invoiceSlug, invoiceType) =>{
    const baseUrl= getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/customer');
    const token = getTokenFromCookies();
    return invoiceSlug ? `${baseUrl}/invoices/pdf/?invoice=${invoiceSlug}&type=${invoiceType}&jwt=${token}` : '';
  };

  const getCurrentPayment = flow(function* () {
    try {
      self.currentPaymentLoading = true;
      const response = yield invoicesApi.loadCurrentPayment();
      if (response?.isCancel) return;
      if (response?.length > 0) {
        const firstPayment = response?.find(item => item.isPrimary == true);
        if (firstPayment && firstPayment?.source == 'stripe') {
          self.paymentMethod = firstPayment?.stripeCard ? {...firstPayment.stripeCard, isPrimary: firstPayment.isPrimary, id: firstPayment.id, paymentMethodId: firstPayment.id} : null;
        }
        self.paymentOptions = response?.filter(e => e.source =='stripe').map(item => {
          if (item.stripeCard) {
            return {
              brand: item.stripeCard.brand,
              expMonth: item.stripeCard.expMonth,
              expYear: item.stripeCard.expYear,
              last4: item.stripeCard.last4,
              name: item.firstName ? item.firstName : '' + ' ' + item.lastName ? item.lastName : '',
              id: item.id,
              isPrimary: item.isPrimary,
              paymentMethodId: item.id,
              lastUsedAt: item.lastUsedAt,
            };
          }
        });
        self.paymentOptionsBill = response?.filter(e => e.source =='bill.com').map(item => {
          if (item.billBankaccount) {
            return {
              billBankaccountAccountLast4: item.billBankaccount.billBankaccountAccountLast4,
              billBankaccountRoutingLast4: item.billBankaccount.billBankaccountRoutingLast4,
              billNameOnBankaccount: item.billBankaccount.billNameOnBankaccount,
              name: item.firstName ? item.firstName : '' + ' ' + item.lastName ? item.lastName : '',
              id: item.id,
              isPrimary: item.isPrimary,
              paymentMethodId: item.id,
              lastUsedAt: item.lastUsedAt,
            };
          }
        });
      }
      self.currentPaymentLoading = false;
    } catch (e) {
      self.currentPaymentLoading = false;
      const errorMessage = apiError(e, 'Failed to get current payment') as string;
      notification.error('', errorMessage);
    }
  });

  const emptyDetailEnvoice = () => {
    self.invoiceDetail = null;
  };

  const updatePaymentMethod = flow(function* (paymentData) {
    try {
      paymentData.stripe_payment_method.billing_details.address.country = self.country;
      paymentData.stripe_payment_method.billing_details.address.state = self.state;
      const dataObj = {...paymentData, state: self.state, country: self.country};
      const response = yield stripeApi.updatePaymentMethod(self.paymentMethod.paymentMethodId, dataObj);
      if (response) {
        self.paymentMethod = response?.stripeCard ? {...response?.stripeCard, isPrimary: response?.isPrimary, id: response?.id, paymentMethodId: response?.id} : null;
        self.updateModal = true;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process update payment method request. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
  });
  const deleteInvoice = flow(function* (slug, type) {
    self.deleteLoading= true;
    try {
      yield stripeApi.deleteInvoice(slug, type);
      return loadOrderInvoices(type);
    } catch (e) {
      Promise.reject(e);
    } finally {
      self.deleteLoading = false;
    }
  });
  const updatePrimaryPaymentMethod = flow(function* (paymentMethodId, paymentData) {
    try {
      self.currentPaymentLoading = true;
      const response = yield stripeApi.updatePaymentMethod(paymentMethodId, paymentData);
      if (response) {
        self.paymentMethod = response?.stripeCard ? {...response?.stripeCard, isPrimary: response?.isPrimary, id: response?.id, paymentMethodId: response?.id} : null;
        self.updateModal = true;
      }
      notification.success('', 'Payment method updated successfully.');
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process update payment method request. Please contact support. Thanks');
      }
    } finally {
      self.currentPaymentLoading = false;
      stopLoading();
    }
  });

  const createPaymentMethod = flow(function* (paymentData) {
    try {
      paymentData.stripe_payment_method.billing_details.address.country = self.country;
      paymentData.stripe_payment_method.billing_details.address.state = self.state;
      const dataObj = {...paymentData, state: self.state, country: self.country};
      const response = yield stripeApi.createPaymentMethod(dataObj);
      if (response) {
        self.paymentMethod = response?.stripeCard ? {...response?.stripeCard, isPrimary: response?.isPrimary, id: response?.id, paymentMethodId: response?.id} : null;
        self.updateModal = false;
        return response;
      }
    } catch (e) {
      const {response} = e;
      if (response?.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response?.status == 500) {
        notification.error('Payment Error', 'Unable to process add payment method request. Please contact support. Thanks');
      }
      return false;
    }
  });

  const openModal = () => {
    if (self.oneTimeOpen) {
      self.oneTimeOpen = false;
      self.visibleModal = true;
    }
  };

  const closeModal = () => {
    self.visibleModal = false;
  };

  const updatePaymentModal = val => {
    self.updateModal = val;
  };

  const setCountry = country => {
    self.country = country;
  };

  const setState = state => {
    self.state = state;
  };

  const startLoading = () => {
    self.loading = true;
  };
  const stopLoading = () => {
    self.loading = false;
  };
  return {
    deleteInvoice,
    loadOrderInvoices,
    closeModal,
    getCurrentPlan,
    getCurrentPayment,
    getInvoiceDetail,
    getInvoicePDF,
    emptyDetailEnvoice,
    updatePaymentMethod,
    setCountry,
    getInvoiceLink,
    setState,
    resetServerErrors,
    startLoading,
    stopLoading,
    openModal,
    updatePaymentModal,
    createPaymentMethod,
    updatePrimaryPaymentMethod,
  };
});

export function initInvoices() {
  return Invoices.create({
    count: 0,
    pageSize: 0,
    orderInvoices: [],
    plan: [],
    visibleModal: false,
    currentPlan: null,
    paymentMethod: null,
    invoiceDetail: null,
    invoicePDF: null,
    loading: false,
    country: 'US',
    state: 'Alabama',
    serverErrors: [],
    updateModal: true,
    currentPlanLoading: false,
    currentPaymentLoading: false,
    oneTimeOpen: true,
  });
}
