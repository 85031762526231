import {useStore} from '@/store/root-store';
import {faCaretDown} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Dropdown, Menu} from 'antd';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Heatmap} from './Heatmap';
import {faTrash} from '@fortawesome/pro-regular-svg-icons';
import {getSingleUrlParam} from '@/utils/url';
import {isArray} from 'lodash';
import {heatmapHeightLogic} from '../../../utils';
import {getKeywordFromMaps} from '@/utils/maps';
import {useRouter} from 'next/router';

export const MultiMaps = observer(({seoId, multiKeywords, setAddKeywordLoader, data, isDelete, handleMultiKeywordDelete, keywordBreakdown}: any) => {
  const {reportBuilder: {isLocalSEOSave, updateReportBuilderProjectLayout, details: {setBackupLayout, isConfig, updateSingleProjectData, getReportsLocalSeoList, singleProjectData, setDefaultMap, isSeoUpdate, setIsSeoUpdate, getLocalSeoDetailsData}}} = useStore('');
  const [mapNum, setMapNum] = useState([]);
  const [keyword, setKeyword] = useState('');
  const getReportId = getSingleUrlParam('report_id');
  const [isSave, setIsSave] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const getPublicHash = getSingleUrlParam('public_hash');
  const router = useRouter();
  const isPublicPage = router?.pathname?.includes('full-report-public');
  const [isThrottled, setIsThrottled] = useState(false);
  const allScans = ['Latest Scan', 'Previous Scan', '2nd Previous Scan', '3rd Previous Scan'];

  useEffect(() => {
    async function load() {
      const mapItems = [];
      if (data?.maps?.length) {
        data?.maps?.map(item => {
          mapItems.push({...item, selectedDate: item.selectedDate});
          setDefaultMap(item);
          setKeyword(`0--${item.keyword}`);
        });
      } else if (data && keywordBreakdown) {
        const dates = JSON.parse(JSON.stringify(keywordBreakdown[0]?.availableDates ?? []));
        mapItems.push({...keywordBreakdown[0], id: new Date().getTime(), dataCid: data?.seoId, groupId: data.groupId, isLatestScan: data.isLatestScan, selectedDate: dates.sort().reverse()[0]});
        setDefaultMap(keywordBreakdown[0]);
        setKeyword(`0--${keywordBreakdown[0]?.keyword}`);
      }
      setMapNum([...mapItems]);
    }
    load();
  }, [data]);

  useEffect(()=> {
    async function save() {
      const getStoreMap = singleProjectData?.layout?.find(map => map.index == seoId && map.name == 'Map');
      const storeKeywords = getStoreMap ? getStoreMap?.seoData?.[0]?.keywordList : [];
      const keywords = getReportsLocalSeoList?.find(item => item.id == seoId)?.keywordBreakdown || [];
      let seo = null;
      if (localStorage.getItem('SEO')) {
        seo = JSON.parse(localStorage.getItem('SEO'));
      }
      const updateLayout = singleProjectData?.layout.map(items => {
        if (seo) {
          const seoObj = seo.find(item => item.seoId == items.index);
          if (items?.name == 'Map' && seoObj) {
            return {...items, seoData: seoObj?.mapNum, h: items?.h + heatmapHeightLogic(seoObj?.mapNum)};
          } else return {...items};
        } else if (!seo && keywords && JSON.stringify(storeKeywords) != JSON.stringify(keywords)) {
          if (items?.name == 'Map' && items?.index == seoId) {
            items.seoData = items?.seoData?.map(item=> {
              return {...item, keywordList: keywords};
            });
            return {...items};
          } else return {...items};
        }
      });
      updateLayout[0] && await updateReportBuilderProjectLayout({...singleProjectData, layout: updateLayout}, Number(getReportId), 'rb');
      updateLayout[0] && updateSingleProjectData({...singleProjectData, layout: updateLayout});
      localStorage.removeItem('SEO');
    }

    if (isLocalSEOSave) {
      save();
    }
  }, [isLocalSEOSave]);

  useEffect(() => {
    if (!singleProjectData?.layout.find(item=> item.index == data.seoId && item.name == 'Map')?.seoData?.length) {
      const seoData = {...data};
      seoData['maps'] = mapNum;
      if (localStorage.getItem('SEO')) {
        const seo = JSON.parse(localStorage.getItem('SEO'));
        const seoObj = seo.find(item => item.seoId == seoId);
        if (seoObj) {
          seoObj.mapNum = [seoData];
        } else {
          seo.push({
            seoId: seoId,
            mapNum: [seoData],
          });
        }
        localStorage.setItem('SEO', JSON.stringify(seo));
      } else {
        localStorage.setItem('SEO', JSON.stringify([{
          seoId: seoId,
          mapNum: [seoData],
        }]));
      }
    }
  }, [mapNum]);

  const saveSeo = async value => {
    setIsSave(true);
    setAddKeywordLoader(true);
    const updateLayout = singleProjectData?.layout.map(items => {
      if (items?.name == 'Map' && items.index == seoId) {
        return {...items, seoData: value, h: items.h + heatmapHeightLogic(value)};
      } else return {...items};
    });

    if (getLocalSeoDetailsData) {
      const response = await updateReportBuilderProjectLayout({...singleProjectData, layout: updateLayout}, Number(getReportId), 'rb');
      setBackupLayout({customLayout: response?.customLayout, layout: response?.layout, layoutType: response?.layoutType});
      updateSingleProjectData({...singleProjectData, layout: updateLayout});
      setTimeout(() => {
        setIsSeoUpdate(!isSeoUpdate);
      }, 700);
    }
    data.hide = false;
    setIsSave(false);
    setAddKeywordLoader(false);
  };

  const addHeatMap = () => {
    if (!isThrottled) {
      setIsThrottled(true);
      const selecedKeyword = keywordBreakdown.find(val => val.keyword == keyword?.split('--')[1]);
      const dates = [...selecedKeyword.availableDates];
      const newHeatMap = {...selecedKeyword, id: new Date().getTime(), dataCid: data.seoId, groupId: data.groupId, selectedDate: dates.sort().reverse()[0], isLatestScan: data.isLatestScan};
      const heatmap = mapNum.concat(newHeatMap);
      const mapkeyupdate = multiKeywords.map(item => {
        if (item.groupId == data.groupId) {
          return {...item, maps: heatmap};
        } else {
          return item;
        }
      });
      setMapNum([...heatmap]);
      saveSeo(mapkeyupdate);
      setTimeout(() => {
        setIsThrottled(false);
      }, 1500);
    }
  };


  const updateKeyword = keyword => {
    if (!isPublicPage) {
      const keywordSplit = keyword.split('--');
      const businessDetail = getReportsLocalSeoList.find(item => item.id == seoId);
      const dates = JSON.parse(JSON.stringify(businessDetail?.keywordBreakdown.find(item=> item.keyword == keywordSplit[1])?.availableDates ?? []));
      const newMap = {
        dataCid: businessDetail.id,
        uuid: new Date().getTime(),
        availableDates: businessDetail?.keywordBreakdown.find(item=> item.keyword == keywordSplit[1])?.availableDates,
        selectedDate: dates.sort().reverse()[0],
        isLatestScan: '',
        keyword: businessDetail?.keywordBreakdown.find(item=> item.keyword == keywordSplit[1])?.keyword,
        id: new Date().getTime(),
      };
      const mapUpdated = multiKeywords.map(item=> {
        item.maps = isArray(item.maps) ? item.maps : [item.maps];
        return {
          ...item, maps: item.groupId == data.groupId ? [newMap] : item?.maps};
      });
      setKeyword(keyword);
      saveSeo(mapUpdated);
    }
  };

  const updateDate = (dateData, date) => {
    const mapUpdated = multiKeywords.map(item=> {
      if ((!item.maps || item.maps?.length == 0) && item.groupId == dateData.groupId) {
        item['maps'] = [dateData];
      }
      return {
        ...item, maps: item?.maps?.map(map=>{
          if (map.id == dateData.id) {
            return {...map, selectedDate: date == 'Latest Scan' ? map.selectedDate : date, isLatestScan: allScans.includes(date) ? date : ''};
          } else {
            return {...map, isLatestScan: map.isLatestScan ? map.isLatestScan : ''};
          }
        }),
      };
    });
    saveSeo(mapUpdated);
  };

  const handleMapDelete = id => {
    if (!isThrottled) {
      setShowDelete(true);
      setIsThrottled(true);
      const getIndex = mapNum.findIndex(item => item.id == id);
      mapNum.splice(getIndex, 1);
      setMapNum([...mapNum]);
      const mapkeyupdate = multiKeywords.map(item => {
        if (item.groupId == data.groupId) {
          return {...item, maps: item.maps?.filter(map => map.id != id)};
        } else {
          return item;
        }
      });
      saveSeo(mapkeyupdate);
      setTimeout(() => {
        setShowDelete(false);
        setIsThrottled(false);
      }, 1500);
    }
  };

  useEffect(() => {
    if (!isPublicPage && data?.maps?.length && !keywordBreakdown?.some(item => item?.keyword == getKeywordFromMaps(data))) {
      handleMultiKeywordDelete(data.groupId);
    }
  }, [data]);

  return (data?.maps?.length ? (keywordBreakdown?.some(item => item?.keyword == getKeywordFromMaps(data)) &&
    <div style={{border: '1px solid #C4C4C4', borderRadius: '16px', padding: '20px 20px 10px 20px', marginTop: '20px', display: `${data.hide == true ? 'none' : ''}`}}>
      <div style={{display: 'flex'}}>
        <div className='keywordsList' style={{border: '1px solid #CDCDCD', borderRadius: '6px', padding: '1px 8px 1px 8px'}}>
          <span>Keyword:</span>
          <Dropdown
            disabled={isPublicPage}
            overlay={
              <MenuStyled defaultValue={keyword?.split('--')[0]} onClick={e => updateKeyword(e?.key)}>
                {
                  keywordBreakdown?.filter(item => item.keyword != '').map((item, index) => {
                    return <Menu.Item
                      style={{background: keyword?.split('--')[1] == item.keyword ? 'rgba(45, 108, 202, 0.2)' : ''}}
                      key={`${index}--${item?.keyword}`}>
                      {item?.keyword || ''}
                    </Menu.Item>;
                  })
                }
              </MenuStyled>
            }
            trigger={['click']}
            placement='bottomLeft'>
            <DropdownDiv>{keyword ? keyword?.split('--')[1] : 'Select keyword'}&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown} /></DropdownDiv>
          </Dropdown>
          <span style={{color: '#CDCDCD'}}>|</span>
          <FontAwesomeIconStyled onClick={() => !getPublicHash && (isDelete && !isConfig) && handleMultiKeywordDelete(data.groupId)} pointerEvents={(!isDelete || isConfig) ? 'none' : 'auto'} color= {((!isDelete && isThrottled) || isConfig) && 'lightgray'} icon={faTrash} fontSize={15} />
        </div>
        <div className='keywordsList' style={{marginLeft: '10px', border: '1px solid #CDCDCD', borderRadius: '6px', padding: '1px 8px 1px 8px'}}>
          <span>Heatmaps: <b>{mapNum.length}</b></span>
          <span style={{color: '#CDCDCD'}}>|</span>
          <span style={{fontSize: '14px', color: isThrottled ? 'gray' : '#2D6CCA', cursor: 'pointer'}} onClick={() => !isThrottled && !getPublicHash && addHeatMap()}>+Add</span>
        </div>
        <p style={{margin: isConfig ? '20px 0 0 10px' : '3px 0 0 10px', color: 'gray'}}><b>{showDelete ? 'Deleting...' : isSave && 'Saving...'}</b></p>
      </div>
      <p className='heatmaps'></p>
      <div style={{display: 'flex', flexWrap: 'wrap', height: 'auto', overflowY: 'auto'}}>
        {
          mapNum?.map((item, index) => {
            return <Heatmap seoId={seoId} keywordBreakdown={keywordBreakdown} key={index} isDelete={mapNum.length > 1} updateDate={updateDate} handleMapDelete={handleMapDelete} mapData={item} />;
          })
        }
      </div>
    </div>) : <div style={{border: '1px solid #C4C4C4', borderRadius: '16px', padding: '20px 20px 10px 20px', marginTop: '20px', display: `${data.hide == true ? 'none' : ''}`}}>
    <div style={{display: 'flex'}}>
      <div className='keywordsList' style={{border: '1px solid #CDCDCD', borderRadius: '6px', padding: '1px 8px 1px 8px'}}>
        <span>Keyword:</span>
        <Dropdown
          overlay={
            <MenuStyled defaultValue={keyword?.split('--')[0]} onClick={e => updateKeyword(e?.key)}>
              {
                keywordBreakdown?.filter(item => item.keyword != '').map((item, index) => {
                  return <Menu.Item
                    style={{background: keyword?.split('--')[1] == item.keyword ? 'rgba(45, 108, 202, 0.2)' : ''}}
                    key={`${index}--${item?.keyword}`}>
                    {item?.keyword || ''}
                  </Menu.Item>;
                })
              }
            </MenuStyled>
          }
          trigger={['click']}
          placement='bottomLeft'>
          <DropdownDiv>{keyword ? keyword?.split('--')[1] : 'Select keyword'}&nbsp;&nbsp;<FontAwesomeIcon icon={faCaretDown} /></DropdownDiv>
        </Dropdown>
        <span style={{color: '#CDCDCD'}}>|</span>
        <FontAwesomeIconStyled onClick={() => !getPublicHash && isDelete && handleMultiKeywordDelete(data.groupId)} pointerEvents={!isDelete ? 'none' : 'auto'} color= { !isDelete && isThrottled && 'lightgray'} icon={faTrash} fontSize={15} />
      </div>
      <div className='keywordsList' style={{marginLeft: '10px', border: '1px solid #CDCDCD', borderRadius: '6px', padding: '1px 8px 1px 8px'}}>
        <span>Heatmaps: <b>{mapNum.length}</b></span>
        <span style={{color: '#CDCDCD'}}>|</span>
        <span style={{fontSize: '14px', color: isThrottled ? 'gray' : '#2D6CCA', cursor: 'pointer'}} onClick={() => !isThrottled && !getPublicHash && addHeatMap()}>+Add</span>
      </div>
      <p style={{margin: isConfig ? '20px 0 0 10px' : '3px 0 0 10px', color: 'gray'}}><b>{showDelete ? 'Deleting...' : isSave && 'Saving...'}</b></p>
    </div>
    <p className='heatmaps'></p>
    <div style={{display: 'flex', flexWrap: 'wrap', height: 'auto', overflowY: 'auto'}}>
      {
        keywordBreakdown && mapNum?.map((item, index, arr) => {
          return <Heatmap seoId={seoId} mapNum={arr} keywordBreakdown={keywordBreakdown} key={index} isDelete={mapNum.length > 1} updateDate={updateDate} handleMapDelete={handleMapDelete} mapData={item} />;
        })
      }
    </div>
  </div>
  );
});

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  &:hover {
    color: red;
  }
`;

const MenuStyled = styled(Menu)`
  max-height: 300px !important;
  border-radius: 7px;
  overflow: auto !important;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
  }
  &:hover {
    ::-webkit-scrollbar-thumb {
      background: rgba(78, 81, 86, 0.6);
    }
  }
  .ant-dropdown-menu-item{
    border-radius: 10px;
    margin: 0 10px;
  }
`;

const DropdownDiv = styled.div`
  width: fit-content;
  max-width: 100%;
  height: 28px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 6px;
  background: #FFFFFF;
  font-weight: bold;
`;
