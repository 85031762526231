import {linkgraphDomains} from '@/utils/router';
import {getDomain} from '@/utils/url';

export const checked = '/img/icon/map_checked_node.png';
export const unChecked = '/img/icon/map_unchecked_node.png';

export const ottoScriptLink = uuid => {
  const currentDomain = getDomain(window.location.hostname);
  const isWhiteLabelDomain = !linkgraphDomains.includes(currentDomain);
  const base64URL = 'data:text/javascript;base64,' + Buffer.from(`var script = document.createElement("script");script.src = 'https://${isWhiteLabelDomain ? currentDomain : 'dashboard.searchatlas.com'}/scripts/dynamic_optimization.js';document.head.appendChild(script);`).toString('base64');
  const uuidStr = uuid ? `data-uuid='${uuid}` : '';
  return `<script type='text/javascript' id='sa-dynamic-optimization' ${uuidStr} src='${base64URL}'></script>`;
};

const checkedObj = {
  isSelected: true,
  symbol: `image://${checked}`,
  itemStyle: {color: '#7F4EAD', borderColor: '#7F4EAD'},
  lineStyle: {color: '#7F4EAD'},
};

const unCheckedObj = {
  isSelected: false,
  symbol: `image://${unChecked}`,
  itemStyle: {color: '#D7D7D7', borderColor: '#D7D7D7'},
  lineStyle: {color: '#D7D7D7'},
};

const isCheckedAll = (children, child) => {
  let obj = {} as any;
  if (child.isSelected) {
    obj = {...unCheckedObj, children: children?.length ? children : []};
  } else {
    obj = {...checkedObj, children: children?.length ? children : []};
  }
  return obj;
};

const singleCheck = (child, val) => {
  let obj = {} as any;
  if (child.isSelected) {
    obj = {...unCheckedObj, parentName: val};
  } else {
    obj = {...checkedObj, parentName: val};
  }
  return obj;
};

const nodesDepth = (children, val, parentName = null, prevChild = null) => {
  let obj = {} as any;
  const data = children.map(child => {
    if ((child.name === val) || (val === parentName)) {
      if (val !== parentName) {
        obj = singleCheck(child, val);
        obj.prevChild = child;
        obj = {...obj, children: isCheckedAll(child.children, child)};
      } else {
        const res = isCheckedAll(child.children, prevChild);
        obj = {
          ...child,
          parentName: val,
          prevChild: prevChild,
          isSelected: res.isSelected,
          itemStyle: res.itemStyle,
          lineStyle: res.lineStyle,
          symbol: res.symbol,
        };
      }
    } else {
      if (Number(child.value) === Number(val)) {
        obj = singleCheck(child, val);
      } else {
        obj = {...child};
      }
    }

    const result = {
      ...child,
      parentName: obj.parentName,
      isSelected: obj.isSelected,
      itemStyle: obj.itemStyle,
      lineStyle: obj.lineStyle,
      symbol: obj.symbol,
      children: child.children?.length ? nodesDepth(child?.children, val, obj.parentName, obj.prevChild) : [],
    };
    return result;
  });
  return data;
};

let checkCount = 0;

const checkIfNodesChecked = (children, val) => {
  const data = children.map(child => {
    let obj = {} as any;
    if (child?.children?.length) {
      const isNotAllChecked = child.children.filter(d => d?.isSelected === false);
      const isSingleChecked = child.children.filter(d => d?.isSelected === true);
      for (let index = 0; index < isSingleChecked.length; index++) {
        if (Number(isSingleChecked[index].value) === Number(val)) {
          checkCount = checkCount + 1;
        }
      }
      if (isNotAllChecked?.length) {
        if (Number(isNotAllChecked[0].value) === Number(val)) {
          checkCount = checkCount + 1;
        }
        obj = unCheckedObj;
      } else {
        obj = checkedObj;
      }
      return {
        ...child,
        isSelected: obj.isSelected,
        symbol: obj.symbol,
        itemStyle: obj.itemStyle,
        lineStyle: obj.lineStyle,
        children: child.children?.length ? checkIfNodesChecked(child?.children, val) : [],
      };
    }
    return child;
  });
  return data;
};

export const isNodeSelected = (val, data) => {
  let clone = data as any;
  clone = nodesDepth(clone.children, val);
  let results = checkIfNodesChecked(clone, val);
  for (let index = 0; index < checkCount; index++) {
    results = checkIfNodesChecked(results, null);
  }
  checkCount = 0;
  return {name: data.name, children: results};
};

export const getLeafNodes = (data: any) => {
  if (!Array.isArray(data?.children) || data.children.length === 0) {
    return [data];
  }
  return data.children.flatMap((child: any) => getLeafNodes(child));
};

export const spaceBetweenNodes = topicalMapDataState => {
  if (getLeafNodes(topicalMapDataState)?.length > 1) {
    const nodesNum = getLeafNodes(topicalMapDataState)?.length;
    if (nodesNum > 0) {
      if (nodesNum > 0 && nodesNum <= 10) {
        return '-5%';
      } else if (nodesNum > 10 && nodesNum <= 20) {
        return '-50%';
      } else if (nodesNum > 20 && nodesNum <= 30) {
        return '-130%';
      } else if (nodesNum > 30 && nodesNum <= 40) {
        return '-200%';
      } else if (nodesNum > 40 && nodesNum <= 50) {
        return '-300%';
      } else if (nodesNum > 50 && nodesNum <= 60) {
        return '-400%';
      } else if (nodesNum > 60 && nodesNum <= 70) {
        return '-500%';
      } else if (nodesNum > 70 && nodesNum <= 80) {
        return '-600%';
      } else if (nodesNum > 80 && nodesNum <= 90) {
        return '-700%';
      } else if (nodesNum > 90 && nodesNum <= 100) {
        return '-800%';
      } else if (nodesNum > 100 && nodesNum <= 200) {
        return '-1600%';
      } else if (nodesNum > 200 && nodesNum <= 300) {
        return '-1950%';
      } else if (nodesNum > 300 && nodesNum <= 400) {
        return '-2600%';
      } else if (nodesNum > 400 && nodesNum <= 500) {
        return '-3250%';
      } else if (nodesNum > 500 && nodesNum <= 600) {
        return '-3950%';
      } else if (nodesNum > 600 && nodesNum <= 700) {
        return '-4650%';
      } else if (nodesNum > 700 && nodesNum <= 800) {
        return '-5250%';
      } else if (nodesNum > 800 && nodesNum <= 900) {
        return '-5950%';
      } else if (nodesNum > 900 && nodesNum <= 1000) {
        return '-6250%';
      } else {
        return '-6090%';
      }
    }
  } else {
    return '100%';
  }
};

export const handleOnChangeFilterTab = (key: string, initialData: any) => {
  const filterChildren = node => {
    const filteredChildren = node.children?.map(child => {
      const filteredChild = filterChildren(child);
      if (filteredChild.children?.length) {
        const matchingChildren = filteredChild.children.filter(grandchild => grandchild[key]);
        if (matchingChildren.length) {
          return {...filteredChild, children: matchingChildren};
        }
      } else if (
        filteredChild[key]
      ) {
        return filteredChild;
      }
      return null;
    }).filter(Boolean);
    return {...node, children: filteredChildren};
  };
  return initialData
    .map(filterChildren)
    .filter(node => node.children?.length);
};
