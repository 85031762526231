type Hours = {
  start: string;
  end: string;
};

type DayDetails = {
  name: string;
  dayNumber: number;
};

type RegularDetails = {
  status?: string;
  hours?: Hours[];
};

type Data = {
  regular?: {
    [key: string]: RegularDetails;
  };
  special?: any; // Assuming 'special' could be null or any type
};

export function convertData(data: Data | null): any[] {
  const result: any[] = [];
  const startDate = new Date('2024-08-05');

  // Convert 24-hour time format to 12-hour format with AM/PM
  function convertTo12HourFormat(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  // Get full name and day number of the week
  function getDayDetails(dayAbbr: string): DayDetails | undefined {
    const days: { [key: string]: DayDetails } = {
      sun: {name: 'Sunday', dayNumber: 0},
      mon: {name: 'Monday', dayNumber: 1},
      tue: {name: 'Tuesday', dayNumber: 2},
      wed: {name: 'Wednesday', dayNumber: 3},
      thu: {name: 'Thursday', dayNumber: 4},
      fri: {name: 'Friday', dayNumber: 5},
      sat: {name: 'Saturday', dayNumber: 6},
    };
    return days[dayAbbr];
  }

  if (data?.regular) {
    for (const [dayAbbr, details] of Object.entries(data.regular)) {
      if (details?.status === 'open' && Array.isArray(details?.hours)) {
        const dayDetails = getDayDetails(dayAbbr);
        if (dayDetails) {
          details.hours.forEach(({start, end}) => {
            const date = new Date(startDate);
            date.setDate(date.getDate() + (dayDetails.dayNumber ?? 0));

            result.push({
              date: date.toISOString().split('T')[0],
              name: dayDetails.name,
              dayNumber: dayDetails.dayNumber,
              parsed: [{
                open: convertTo12HourFormat(start),
                close: convertTo12HourFormat(end),
              }],
              value: `${convertTo12HourFormat(start)}–${convertTo12HourFormat(end)}`,
            });
          });
        }
      }
    }
  }

  return result;
}
