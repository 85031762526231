import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import styles from '../styles.module.scss';
import {iconUpward} from '@/utils/icons';
import {DubbleLineChart} from '../charts/dubbleLineChart';
import {BarChart} from '../charts/barChart';
import {TopuserBySource} from './topuserBySource';
import {CircleChart} from '../charts/circleChart';
import {TableStyled} from '../../../home-page-main/components/table-styled';
import {PaginationStyled} from '../../../local-seo-scan/seo-projects-list-v2/styled';
import {DeltaForSingleValue} from '@/components/common-components/components/delta';
import {SkeletonHorizontalLoaderGray} from '@/components/common-components';
import {formatLargeNumber} from '@/utils/number';
import {useStore} from '@/store/root-store';
import {addProtocolToDomain} from '@/utils/url';


const dateRangeObj = {
  last_1_month: 'One month',
  last_3_months: 'Three month',
  last_6_months: 'Six month',
  last_year: 'One year',
};

export const View = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail}}} = useStore('');
  const urlMetrics = getReportGADetailData?.topMetrics?.length ? getReportGADetailData?.topMetrics[0] : null;

  return <div className={`${styles.mainInner} mainInner`}>
    <p>{iconUpward()}<span style={{marginLeft: '10px'}}>Views</span></p>
    {loadingGADetail ?
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>:
      <div style={{display: 'flex', height: 32}}>
        <TitleBlack>{formatLargeNumber(urlMetrics?.screenPageViews, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack><DeltaForSingleValue format={urlMetrics?.screenPageViewsDelta}/>
        <div className={styles.chartStyled}>
        </div>
      </div>}
  </div>;
});

export const Session = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail}}} = useStore('');
  const urlMetrics = getReportGADetailData?.topMetrics?.length ? getReportGADetailData?.topMetrics[0] : null;

  return <div className={`${styles.mainInner} mainInner`}>
    <p>{iconUpward()}<span style={{marginLeft: '10px'}}> Sessions</span></p>
    {loadingGADetail ?
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>:<div style={{display: 'flex', height: 32}}>
        <TitleBlack>{formatLargeNumber(urlMetrics?.sessions, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack><DeltaForSingleValue format={urlMetrics?.sessionsDelta}/>
        <div className={styles.chartStyled}>
        </div>
      </div>}
  </div>;
});

export const TotalUser = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail}}} = useStore('');
  const urlMetrics = getReportGADetailData?.topMetrics?.length ? getReportGADetailData?.topMetrics[0] : null;

  return <div className={`${styles.mainInner} mainInner`}>
    <p>{iconUpward()}<span style={{marginLeft: '10px'}}>Total Users</span></p>
    {loadingGADetail ?
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>:<div style={{display: 'flex', height: 32}}>
        <TitleBlack>{formatLargeNumber(urlMetrics?.totalUsers, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack><DeltaForSingleValue format={urlMetrics?.totalUsersDelta}/>
        <div className={styles.chartStyled}>
        </div>
      </div>}
  </div>;
});

export const NewUser = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail}}} = useStore('');
  const urlMetrics = getReportGADetailData?.topMetrics?.length ? getReportGADetailData?.topMetrics[0] : null;

  return <div className={`${styles.mainInner} mainInner`}>
    <p>{iconUpward()}<span style={{marginLeft: '10px'}}>New Users</span></p>
    {loadingGADetail ?
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>:<div style={{display: 'flex', height: 32}}>
        <TitleBlack>{formatLargeNumber(urlMetrics?.newUsers, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack><DeltaForSingleValue format={urlMetrics?.newUsersDelta}/>
        <div className={styles.chartStyled}>
        </div>
      </div>}
  </div>;
});

export const EngagedSession = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail}}} = useStore('');
  const urlMetrics = getReportGADetailData?.topMetrics?.length ? getReportGADetailData?.topMetrics[0] : null;

  return <div className={`${styles.mainInner} mainInner`}>
    <p>{iconUpward()}<span style={{marginLeft: '10px'}}>Engaged Sessions</span></p>
    {loadingGADetail ?
      <><SkeletonHorizontalLoaderGray lightLoader={true} height='10px' width='100px' skeletonHeight='10px' /><SkeletonHorizontalLoaderGray lightLoader={true} height='50px' width='80px' skeletonHeight='10px' /></>:<div style={{display: 'flex', height: 32}}>
        <TitleBlack>{formatLargeNumber(urlMetrics?.engagedSessions, 1)?.toString()?.replace('.0', '') || '-'}</TitleBlack><DeltaForSingleValue format={urlMetrics?.engagedSessionsDelta}/>
        <div className={styles.chartStyled}>
        </div>
      </div>}
  </div>;
});

export const UserNewTrend = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig, singleProjectData}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}How are New Users Trending?</Title></div>
    <DubbleLineChart dataLoader={loadingGADetail} data={getReportGADetailData?.usersTrend} cardType='newUsers' dateRange={singleProjectData?.dateRangeStart ? {dateRangeStart: singleProjectData?.dateRangeStart, dateRangeEnd: singleProjectData?.dateRangeEnd} : singleProjectData?.dateRange}/>
  </Wrapper> : null;
});

export const TotalUserTrend = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig, singleProjectData}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}How are Total Users Trending?</Title></div>
    <DubbleLineChart dataLoader={loadingGADetail} data={getReportGADetailData?.usersTrend} cardType='totalUsers' dateRange={singleProjectData?.dateRangeStart ? {dateRangeStart: singleProjectData?.dateRangeStart, dateRangeEnd: singleProjectData?.dateRangeEnd} : singleProjectData?.dateRange}/>
  </Wrapper> : null;
});

export const DrivingEngagement = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig}}} = useStore('');
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded ? <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}Which channels are driving engagement?</Title></div>
    <BarChart loading={loadingGADetail} data={getReportGADetailData?.channels} secondSeriesName='Total Users'/>
  </Wrapper> : null;
});

export const SourceMedium = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, loadingGADetail, singleProjectData, isConfig}}} = useStore('');

  const range = dateRangeObj[`${singleProjectData?.dateRange}`];
  const numberOfPastMonths = range == 'One month' ? 'oneMonth' : range == 'Three month' ? 'threeMonth' : range == 'Six month' ? 'sixMonth' : 'oneYear';

  return <Wrapper className={isConfig && 'no-border'} style={{
    border: 'none',
    borderRadius: '0px',
    borderTop: '1px solid #E8E8E8',
    borderRight: '1px solid #E8E8E8',
    borderLeft: '1px solid #E8E8E8',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
  }}>
    <div><Title>{iconUpward()}Total Users by Source/Medium</Title></div>
    <TopuserBySource selectedCountry={singleProjectData?.location} selectedProject={singleProjectData?.reportsData.ga?.gaPropertyId} selectedRange={numberOfPastMonths} section={singleProjectData?.reportsData.ga?.gaSections} loading={ loadingGADetail} data={getReportGADetailData?.sourceMedium}/>
  </Wrapper>;
});

export const SourceMediumPagination = observer(() => {
  const {reportBuilder: {details: {getReportGADetailData, singleProjectData, isConfig}}} = useStore('');
  const {reportBuilder: {details: {setTopUsersParams, getTopUsersParams}}} = useStore('');
  const range = dateRangeObj[`${singleProjectData?.dateRange}`];
  const numberOfPastMonths = range == 'One month' ? 'oneMonth' : range == 'Three month' ? 'threeMonth' : range == 'Six month' ? 'sixMonth' : 'oneYear';

  const handlePaginationChange = (page, pageSize) => {
    setTopUsersParams(singleProjectData?.reportsData.ga?.gaPropertyId, singleProjectData?.location, numberOfPastMonths, {...getTopUsersParams, page: page, page_size: pageSize}, false);
  };

  return <Pagination
    className={`${'hideInPdf'} ${isConfig ? ' no-border' : ''}`}
    onChange={handlePaginationChange}
    total={getReportGADetailData?.sourceMedium?.count}
    pageSize={getTopUsersParams['page_size']}
    current={getTopUsersParams['page']}
    defaultPageSize={5}
    showSizeChanger={false}
  />;
});

export const UserMedium = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig}}} = useStore('');

  return <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}Top User Mediums</Title></div>
    <CircleChart dataLoader={loadingGADetail} data={getReportGADetailData?.usersByMediums?.map(z => {
      return {
        value: z?.totalUsers,
        name: z?.medium,
      };
    })}/>
  </Wrapper>;
});

export const RevenueBySource = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig}}} = useStore('');

  return <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}Revenue by Source</Title></div>
    <BarChart dataLoader={loadingGADetail} showViewColum={false} data={getReportGADetailData?.revenueBySource}/>
  </Wrapper>;
});

export const ConversionBySource = observer(() => {
  const {reportBuilder: {details: {loadingGADetail, getReportGADetailData, isConfig}}} = useStore('');

  return <Wrapper className={isConfig && 'no-border'}>
    <div><Title>{iconUpward()}Conversions by Source</Title></div>
    <CircleChart dataLoader={loadingGADetail} data={getReportGADetailData?.conversionsBySource?.map(z => {
      return {
        value: z?.conversions,
        name: z?.source,
      };
    })}/>
  </Wrapper>;
});


export const TopPageUser = observer(({loader}:any) => {
  const {reportBuilder: {details: {isConfig, singleProjectData, topPagesLoading, setTopPagesParams, getTopPagesParams, loadingGADetail, getReportGADetailData}}} = useStore('');
  const range = dateRangeObj[`${singleProjectData?.dateRange}`];
  const rangeObj = {startDate: singleProjectData?.dateRangeStart, endDate: singleProjectData?.dateRangeEnd};
  const numberOfPastMonths = range == 'One month' ? 'oneMonth' : range == 'Three month' ? 'threeMonth' : range == 'Six month' ? 'sixMonth' : 'oneYear';

  const columns = [
    {
      title: 'PAGE',
      dataIndex: 'fullPageUrl',
      key: 'fullPageUrl',
      align: 'left' as 'left',
      width: '50%',
      ellipsis: true,
      render: (_, record) => {
        return (
          <a target='_blank' rel='noreferrer' href={addProtocolToDomain(record?.fullPageUrl)} style={{color: '#2D6CCA'}}>{record?.fullPageUrl || '-'}</a>
        );
      },
    },
    {
      title: <div>TOTAL<br /> USERS</div>,
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      align: 'center' as 'center',
      sortFieldName: 'totalUsers',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper style={{paddingLeft: '8px'}} align='center' justifyContent='center'>{record?.totalUsers != null || record?.totalUsers != undefined ? record?.totalUsers : '-'}<DeltaForSingleValue format={record?.totalUsersDelta}/></FlexWrapper>
        );
      },
    },
    {
      title: <div>AVG. ENGAGE. <br /> PER SESSION</div>,
      dataIndex: 'engagement_rate',
      key: 'engagement_rate',
      align: 'center' as 'center',
      width: 80,
      render: (_, record) => {
        return <p>{record?.engagementRate ? record?.engagementRate + '%' : '-'}</p>;
      },
    },
  ];

  const handleTableChange = async () => {
    setTopPagesParams(singleProjectData?.reportsData.ga?.gaPropertyId, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getTopPagesParams});
  };

  const handlePaginationChange = (page, pageSize) => {
    setTopPagesParams(singleProjectData?.reportsData.ga?.gaPropertyId, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getTopPagesParams, page: page, page_size: pageSize});
  };

  return <TableWrapper>
    <div style={{marginLeft: '10px'}}><Title>{iconUpward()}Top Pages and Total Users</Title></div>
    <TableStyled
      style={{height: '490px'}}
      columns={columns as any}
      dataSource={getReportGADetailData?.topPages?.results || []}
      pagination={false}
      loading={loadingGADetail || loader || topPagesLoading}
      onChange={handleTableChange}
      loadingRows={10}
      sticky={true}
    />
    {!isConfig && <PaginationStyled
      className='hideInPdf'
      onChange={handlePaginationChange}
      total={getReportGADetailData?.topPages?.count}
      pageSize={getTopPagesParams['page_size']}
      current={getTopPagesParams['page']}
      defaultPageSize={10}
    />}
  </TableWrapper>;
});

export const KeyEvents = observer(({loader}:any) => {
  const {reportBuilder: {details: {isConfig, singleProjectData, loadingGADetail, keyEventLoading, setKeyEventsParams, getKeyEventParams, getReportGADetailData}}} = useStore('');
  const range = dateRangeObj[`${singleProjectData?.dateRange}`];
  const rangeObj = {startDate: singleProjectData?.dateRangeStart, endDate: singleProjectData?.dateRangeEnd};
  const numberOfPastMonths = range == 'One month' ? 'oneMonth' : range == 'Three month' ? 'threeMonth' : range == 'Six month' ? 'sixMonth' : 'oneYear';

  const columns = [
    {
      title: 'EVENT NAME',
      dataIndex: 'eventName',
      key: 'eventName',
      align: 'left' as 'left',
      width: '50%',
      ellipsis: true,
      render: (_, record) => {
        return (
          <p>{record.eventName}</p>
        );
      },
    },
    {
      title: <div>EVENT COUNT</div>,
      dataIndex: 'eventCount',
      key: 'eventCount',
      align: 'right' as 'right',
      sortFieldName: 'eventCount',
      width: 100,
      render: (_, record) => {
        return (
          <FlexWrapper style={{paddingLeft: '8px'}} align='right' justifyContent='end'>{formatLargeNumber(record.eventCount, 1)?.toString()?.replace('.0', '') || '-'}</FlexWrapper>
        );
      },
    },
    {
      title: <div>TOTAL <br/> USERS</div>,
      dataIndex: 'totalUsers',
      key: 'totalUsers',
      align: 'right' as 'right',
      width: 80,
      render: (_, record) => {
        return (
          <FlexWrapper style={{paddingLeft: '8px'}} align='right' justifyContent='end'>{formatLargeNumber(record.totalUsers, 1)?.toString()?.replace('.0', '') || '-'}</FlexWrapper>
        );
      },
    },
    {
      title: <div>EVENT COUNT <br/>PER USER</div>,
      dataIndex: 'eventCountPerUser',
      key: 'eventCountPerUser',
      align: 'right' as 'right',
      width: 80,
      render: (_, record) => {
        return (
          <FlexWrapper style={{paddingLeft: '8px'}} align='right' justifyContent='end'>{formatLargeNumber(record.eventCountPerUser, 1)?.toString()?.replace('.0', '') || '-'}</FlexWrapper>
        );
      },
    },
    {
      title: <div>TOTAL <br/>REVENUE</div>,
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      align: 'right' as 'right',
      width: 80,
      render: (_, record) => {
        return (
          <FlexWrapper style={{paddingLeft: '8px'}} align='right' justifyContent='end'>{formatLargeNumber(record.totalRevenue, 1)?.toString()?.replace('.0', '') || '-'}</FlexWrapper>
        );
      },
    },
  ];

  const handleTableChange = async () => {
    setKeyEventsParams(singleProjectData?.reportsData.ga?.gaPropertyId, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getKeyEventParams});
  };

  const handlePaginationChange = (page, pageSize) => {
    setKeyEventsParams(singleProjectData?.reportsData.ga?.gaPropertyId, singleProjectData?.location, singleProjectData?.dateRangeStart ? rangeObj : numberOfPastMonths, {...getKeyEventParams, page: page, page_size: pageSize});
  };

  return <TableWrapper>
    <div style={{marginLeft: '10px'}}><Title>{iconUpward()}Key Events</Title></div>
    <TableStyled
      style={{height: '490px'}}
      columns={columns as any}
      dataSource={getReportGADetailData?.keyEvents?.results || []}
      pagination={false}
      loading={loadingGADetail || loader || keyEventLoading}
      onChange={handleTableChange}
      loadingRows={10}
      sticky={true}
    />
    {!isConfig && <PaginationStyled
      className='hideInPdf'
      onChange={handlePaginationChange}
      total={getReportGADetailData?.keyEvents?.count}
      pageSize={getKeyEventParams['page_size']}
      current={getKeyEventParams['page']}
      defaultPageSize={10}
    />}
  </TableWrapper>;
});

const Title = styled.h2`
font-size: 18px
`;

const Wrapper = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  padding: 20px;
  background-color: white;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  border-radius: 16px;
  // margin-top: 20px;
  h2 {
    font-size: 16px;
    font-weight: 600;
  }
  svg {
    margin-right: 10px;
  }
`;

const TableWrapper = styled.div`
  padding: 10px 10px 0 10px;
  background-color: white;
  border-radius: 10px;
  h2{
    font-size: 16px;
    font-weight: 600;
  }
  svg{
    margin-right: 10px;
  }
`;

const FlexWrapper = styled.div<{align?: string; justifyContent?: string; flexDirection?: string}>`
  display: flex;
  align-items: ${p=> p?.align};
  justify-content: ${p=> p?.justifyContent};
  flex-direction: ${p=> p?.flexDirection};
`;

const TitleBlack = styled.div<{fontSize?: string}>`
font-weight: 600;
font-size: ${p=> p?.fontSize ? p?.fontSize : '24px'};
line-height: 22px;
color: #121212;
margin-right: 10px;
`;


const Pagination = styled(PaginationStyled)`
  padding: 20px 5px 20px 0px !important;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  // border-bottom: 1px solid #E8E8E8;
  // border-right: 1px solid #E8E8E8;
  // border-left: 1px solid #E8E8E8;
  background-color: white;
  margin-top: 0;
`;
