import React, {useEffect, useState} from 'react';
import {StyledRadioGroup} from '../styleComponents';
import {Radio} from 'antd';

const RadioField = ({data, form}) => {
  const [radio, setRadio] = useState(data?.defaultValue || '');

  useEffect(() => {
    const obj = {};
    obj[data?.key] = data?.defaultValue;
    form.setFieldsValue(obj);
    setRadio(data?.defaultValue);
  }, [data?.defaultValue]);

  const isSelected = value => radio === value;

  return (
    <StyledRadioGroup onChange={e => {
      const value = e.target.value;
      const obj = {};
      obj[data?.resetKey] = data?.resetVal;
      obj[data?.key] = value;
      form.setFieldsValue(obj);
      setRadio(value);
    }} value={radio}
    >
      {data?.radioOptions?.map((item, index) => (
        <Radio
          key={index}
          value={item?.value}
          className={isSelected(item?.value) && 'selected'}>
          <div style={{width: '100%'}}>
            <div className={isSelected(item?.value) ? 'radio-heading-active' : 'radio-heading'}>{item?.heading}</div>
            <div className='radio-desc'>{item?.desc}</div>
            {isSelected(item?.value) && <div>{item?.content}</div>}
          </div>
        </Radio>
      ))}
    </StyledRadioGroup>
  );
};

export default RadioField;
