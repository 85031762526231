import {GetAllPagesResponse} from '@/components/dashboard/pages/landing-page-optimizer/types';
import {Instance, types, flow, getParent, getRoot, cast} from 'mobx-state-tree';
import {PAGES_API} from '@/api/content-optimizer';
import {notification} from '@/utils/notifications';
import {notification as notify} from '@/utils/notification-v2';
import {DialogModel} from './pages.model';
import {PageModel, ProjectModel} from './PageModel';
import {SummaryStatsModel} from './SummaryStatsModel';
import debounce from 'lodash/debounce';
import {QUERY_DEBOUNCE_WAIT} from '@/constants';
import {isNil} from 'lodash';
import {useErrorNotification} from '@/utils/notification-v2';

export const DeleteDialog = types.compose(
  DialogModel,
  types.model({
    uuid: types.maybeNull(types.string),
  })).actions(self => ({
  openDialog(uuid: string) {
    self.isOpen = true;
    self.uuid = uuid;
  },
  closeDialog() {
    self.isOpen = false;
    self.uuid = null;
  },
  removeItem() {
    getParent<PagesStoreInstance>(self).removeContentItem();
  },
}));

export type DeleteDialogInstance = Instance<typeof DeleteDialog>;

const RenameProjectModel = types.model(
  {
    name: types.maybeNull(types.string),
    id: types.maybeNull(types.number),
  },
);

const FolderSettingsFormValidationState = types.model(
  {
    linksToInclude: types.boolean,
    companyInfo: types.boolean,
  },
);

export const Pages = types
  .model({
    loading: types.boolean,
    initialLoading: types.boolean,
    dateLoading: types.maybeNull(types.boolean),
    emailLoading: types.maybeNull(types.boolean),
    statusLoading: types.maybeNull(types.boolean),
    loadingSummary: types.boolean,
    pageRemovedFromEditor: types.boolean,
    searchTerm: types.string,
    error: types.boolean,
    content: types.array(PageModel),
    summary: SummaryStatsModel,
    ordering: types.string,
    daOrdering: types.string,
    count: types.number,
    deleteDialog: DeleteDialog,
    totalPages: types.number,
    currentPage: types.number,
    pageSize: types.number,
    articlePages: types.number,
    counter: types.optional(types.number, 0),
    showSwitchToTableTooltip: types.boolean,
    statusFilters: types.array(types.string),
    projects: types.array(ProjectModel),
    projectsLoading: types.boolean,
    currentProject: types.string,
    articleProjectName: types.string,
    updatingPageUuid: types.array(types.string),
    showAddNewProjectModal: types.boolean,
    showRenameProjectModal: types.boolean,
    showDeleteProjectModal: types.boolean,
    projectToRename: types.maybeNull(RenameProjectModel),
    activeProjectId: types.maybeNull(types.number),
    showAiProgressTooltip: types.boolean,
    wpSelectedKeyword: types.array(types.string),
    repollWpSelectedKeyword: types.array(types.string),
    repollCurrentPage: types.number,
    repollPageSize: types.number,
    loadingWriteWithAiInBulk: types.boolean,
    deletedArticlesLoading: types.boolean,
    deletedArticles: types.array(PageModel),
    daPageSize: types.number,
    daCurrentPage: types.number,
    daCount: types.number,
    exportArticlesLoading: types.boolean,
    loadingSettingsUpdate: types.boolean,
    createProjectLoading: types.boolean,
    renamedProjectId: types.maybeNull(types.number),
    isFolderSettingsFormValid: FolderSettingsFormValidationState,
  })
  .actions(self => {
    const setisFolderSettingsFormValid = ({
      linksToInclude = self.isFolderSettingsFormValid.linksToInclude,
      companyInfo = self.isFolderSettingsFormValid.companyInfo,
    }: {linksToInclude?: boolean; companyInfo?: boolean}) => {
      self.isFolderSettingsFormValid.linksToInclude = linksToInclude;
      self.isFolderSettingsFormValid.companyInfo = companyInfo;
    };

    const setRenamedProjectId = (value: number | null) => {
      self.renamedProjectId = value;
    };

    const setProjectName = (id: number, name: string) => {
      const index = self.projects.findIndex(project => project.id === id);
      self.projects[index].name = name;
      self.renamedProjectId = id;
    };

    const setFromResponse = (data: GetAllPagesResponse) => {
      if (data) {
        self.count = data.count;
        self.totalPages = data.totalPages;
      }
    };

    const setPageRemovedFromEditor = (value: boolean) => {
      self.pageRemovedFromEditor = value;
    };

    const setContent = content => {
      self.content = cast(content);
    };

    const setActiveProjectId = (value:number) => {
      self.activeProjectId = value;
    };

    const setSummary = summary => {
      self.summary = cast(summary);
    };

    const setSearchTerm = (value: string) => {
      self.searchTerm = value;
    };

    const setArticlePages = () => {
      self.articlePages = 10;
    };

    const setCounter = (size: number) => {
      self.counter += size;
    };

    const setCounterDefault = (size: number) => {
      self.counter = size;
    };

    const setArticlePagesTwenty = (size: number) => {
      self.articlePages = size;
    };

    const setArticleProjectName = (name: string) => {
      self.articleProjectName = name;
    };

    const setCurrentPage = (page: number) => {
      self.currentPage = page;
    };

    const setCurrentProject = (project: string) => self.currentProject = project;

    const setShowDeleteProjectModal = (value: boolean) => self.showDeleteProjectModal = value;
    const setShowAddNewProjectModal = (value: boolean) => self.showAddNewProjectModal = value;
    const setShowRenameProjectModal = (value: boolean) => self.showRenameProjectModal = value;
    const setProjectToRename = (name: string, id: number) => {
      self.projectToRename.name = name;
      self.projectToRename.id = id;
    };
    const setUpdatingPageUuid = uuidArr => self.updatingPageUuid = cast(uuidArr);
    const setShowAiProgressTooltip = value => self.showAiProgressTooltip = value;


    const updateContentProjectSettings = flow(function* ({payload, projectId}: any) {
      self.loadingSettingsUpdate = true;
      try {
        const response = yield PAGES_API.updateContentProjectAiSettings({payload, projectId});
        if (response.isCancel) return;
        getAllProjects();
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.loadingSettingsUpdate = false;
      }
    });

    const refetchLoadPages = flow(function* (projectId?: number, timeStamp?: number) {
      const parent = getParent(self) as any;
      self.activeProjectId = projectId;
      self.projectsLoading = true;
      try {
        const response = yield PAGES_API.getPages(
          {
            ordering: self.ordering,
            search: self.searchTerm,
            page: self.repollCurrentPage,
            page_size: self.repollPageSize,
            status: self.statusFilters.join(','),
            ...(!isNil(projectId) && self.currentProject != 'Total Articles' && {id: projectId}),
          });
        if (response.isCancel) return;

        self.error = false;
        self.loading = false;
        setFromResponse(response);
        setContent(response.results);

        const results = response?.results;
        let stopRepoll = false;

        for (const item of results) {
          // Check if the uuid is in wpSelectedKeyword and wp_syncing_task_status is "Pending" or null
          if (self.repollWpSelectedKeyword.includes(item?.uuid) && (item?.wpSyncingTaskStatus === 'PENDING' || item?.wpSyncingTaskStatus === null)) {
            // Set stopRepoll to false if conditions are met
            stopRepoll = false;
          } else if (self.repollWpSelectedKeyword.includes(item?.uuid) && (item?.wpSyncingTaskStatus !== 'PENDING' || item?.wpSyncingTaskStatus !== null)) {
            stopRepoll = true;
          }
        }

        if (stopRepoll) {
          return;
        } else {
          yield new Promise(r => setTimeout(r, 3000));
          const now = new Date().getTime();

          if (now - timeStamp < 30 * 60 * 1000 ) {
            return refetchLoadPages(projectId, timeStamp);
          }
        }
      } catch (e) {
        self.error = true;
        self.loading = false;
        self.projectsLoading = false;
        const rootStore = getRoot(self) as any;
        if (e?.response?.status === 520 || e?.response?.status === 524) {
          yield new Promise(r => setTimeout(r, 3000));
          const now = new Date().getTime();

          if (now - timeStamp < 30 * 60 * 1000) {
            return refetchLoadPages(projectId, timeStamp);
          }
        } else if (e?.response?.status === 429) {
          if (rootStore?.settings?.customer?.profile?.isWhitelabel) {
            notify.error('Quota is exceeded', 'You’ve exceeded the usage limit for this tool.');
          } else {
            const quotaTitle = 'You are trying to use more quota than available.';
            notify.warning(quotaTitle, 'Upgrading to a higher plan will increase the quota limits.', 'View pricing', () => rootStore?.plans?.showSidebarPaymentDrawer());
          }
        }
        parent.currentPage.loadPage(String(projectId) as string, false);
      }
    });

    const loadDeletedArticles = flow(function* () {
      self.deletedArticlesLoading = true;
      try {
        const response = yield PAGES_API.getPages(
          {
            page: self.daCurrentPage,
            page_size: self.daPageSize,
            ordering: self.daOrdering,
            was_deleted: true,
          });
        if (response.isCancel) return;
        self.deletedArticles = cast(response.results);
        self.daCount = response.count;
      } catch (error) {
        notification.error('Something went wrong. Try again later.');
        self.deletedArticlesLoading = false;
      } finally {
        self.deletedArticlesLoading = false;
        self.error = false;
      }
    });

    const loadPages = flow(function* (isInit = false, projectId?: number, shouldChangeLoading?: boolean) {
      // it is very important to check for projectId thurtfullness by checking if its null
      // reason is that we have projectId that is equal to zero (0), and JS threats it as a falsey value
      // we need to treat any project ID as an actual number
      self.activeProjectId = projectId;
      self.projectsLoading = true;
      self.initialLoading = true;

      if (isInit) {
        self.currentPage=1;
        self.searchTerm = '';
        self.ordering = '-updated_at';
        // self.statusFilters = cast([]);
      }
      if (shouldChangeLoading) self.loading = true;
      try {
        const response = yield PAGES_API.getPages(
          {
            ordering: self.ordering,
            search: self.searchTerm,
            page: self.currentPage,
            page_size: self.pageSize,
            status: self.statusFilters.join(','),
            ...(!isNil(projectId) && self.currentProject != 'Total Articles' && {id: projectId}),
          });
        if (response.isCancel) return;
        self.error = false;
        self.loading = false;
        setFromResponse(response);
        setContent(response.results);

        const inProgressContent = response?.results?.filter(i => i?.aiInProgress);
        if (inProgressContent?.length) {
          yield new Promise(r => setTimeout(r, 5000));
          const now = new Date().getTime();
          const parsedDate = new Date(inProgressContent[0]?.createdAt);
          const timestamp = parsedDate.getTime();

          if (now - timestamp < 25 * 60 * 1000) {
            loadPages(false, self.activeProjectId, false);
          }
        }
        return response;
      } catch (error) {
        if (error?.response?.status !== 520) {
          notification.error('Something went wrong. Try again later.');
          self.error = true;
          self.loading = false;
          self.projectsLoading = false;
        }
      } finally {
        self.loading = false;
        self.initialLoading = false;
        self.projectsLoading = false;
      }
    });

    const loadUpdatedPages = flow(function* (noloading?: boolean) {
      if (!noloading) {
        self.emailLoading=true;
        self.statusLoading=true;
        self.dateLoading = true;
      }
      try {
        const response = yield PAGES_API.getPages({ordering: self.ordering, search: self.searchTerm, page: self.currentPage, page_size: self.pageSize, status: self.statusFilters.join(',')});
        if (response.isCancel) return;
        self.error = false;
        self.dateLoading = false;
        self.emailLoading=false;
        self.statusLoading=false;
        setFromResponse(response);
        setContent(response.results);
      } catch (error) {
        notification.error('Something went wrong. Try again later.');
        self.error = true;
        self.dateLoading = false;
        self.emailLoading=false;
        self.statusLoading=false;
      } finally {
        self.dateLoading = false;
        self.emailLoading=false;
        self.statusLoading=false;
      }
    });
    const loadPagesSummary = flow(function* () {
      self.loadingSummary = true;
      try {
        const response = yield PAGES_API.getPagesSummary();
        if (response.isCancel) return;
        setSummary(response);
      } catch (error) {
        notify.error('', 'Failed to load summary. Try again later.');
      } finally {
        self.loadingSummary = false;
      }
    });
    const bulkUpdateData = flow(function* (uuid?: any, dueDate?:any, status?:string, assignee?:string[], project?:string, loadUpdatedPage: boolean=true, restoreArticles?: boolean) {
      try {
        const resp = yield PAGES_API.bulkUpdate(uuid, dueDate, status, assignee, project, restoreArticles);
        if (loadUpdatedPage) {
          loadPages(false, self.activeProjectId);
        }
        if (restoreArticles) {
          loadDeletedArticles();
          loadPages(false, self.activeProjectId);
          return {success: true};
        }
        return resp;
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could update data.',
          desc: 'Try again later',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      }
    });
    const deletebulkUpdate = flow(function* (uuid?: any, activeProject?: any) {
      try {
        const resp = yield PAGES_API.deletebulkUpdate(uuid);
        if (activeProject) {
          filterPagesByProject(activeProject);
        } else {
          loadUpdatedPages();
        }
        getAllProjects();


        return resp;
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const getAllProjects = flow(function* () {
      self.projectsLoading = true;
      try {
        const response = yield PAGES_API.getProjects();
        if (response.isCancel) return;

        self.projects = cast(response);
      } catch (e) {
        notify.error('', 'Something went wrong. Failed to get all projects');
      } finally {
        self.projectsLoading = false;
      }
    });

    const getHeroImageById = flow(function* (uuid) {
      try {
        const response = yield PAGES_API.getHeroImagebyUuid(uuid);
        return response;
      } catch (e) {
        return Promise.reject(e);
      }
    });


    const createProjectsByName = flow(function* (name: string) {
      self.createProjectLoading = true;
      self.projectsLoading = true;
      try {
        const res = yield PAGES_API.createProjectByName(name);
        getAllProjects();
        setCurrentProject(name);
        return res;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.projectsLoading = false;
        self.createProjectLoading = false;
      }
    });

    const deletePage = flow(function* (id: any) {
      try {
        yield PAGES_API.deletePage(id);
        const pageNum = self.content?.length % 10 == 1 ? 1 : self.currentPage;
        setCurrentPage(pageNum);
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const downloadCSV = async url => {
      try {
        const sheetId = url.match(/\/d\/([a-zA-Z0-9-_]+)/)[1];
        const exportUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/export?format=csv`;

        const response = await fetch(exportUrl, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const urlBlob = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlBlob;
        a.download = 'SCA Article Export';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(urlBlob);
      } catch (error) {
        notification.error('Failed to Export Article', 'topRight');
      }
    };

    const exportToCSV = flow(function* (payload: any) {
      try {
        self.exportArticlesLoading = true;
        const response = yield PAGES_API.exportToCSV(payload);
        if (response) yield downloadCSV(response?.googleSheetUrl);
        self.exportArticlesLoading = false;
      } catch (e) {
        self.exportArticlesLoading = false;
      }
    });

    const syncWp = flow(function* (id: any) {
      try {
        yield PAGES_API.syncWP(id);
        const now = new Date().getTime();
        refetchLoadPages(self.activeProjectId, now);
      } catch (e) {
        return Promise.reject(e);
      }
    });

    const deleteProject = flow(function* (id: number) {
      self.projectsLoading = true;
      try {
        yield PAGES_API.deleteProject(id);
        getAllProjects();
        loadPages(false, self.activeProjectId);
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not delete project.',
          desc: 'Try again later.',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      } finally {
        self.projectsLoading = false;
      }
    });

    const renameProject = flow(function* (id: number, name: string) {
      self.projectsLoading = true;
      try {
        const response = yield PAGES_API.renameProject(id, name);
        if (response.isCancel) return;

        self.projects = cast(response);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.projectsLoading = false;
      }
    });

    const addArticleToProject = flow(function* (projectId: number, uuid: string) {
      self.projectsLoading = true;
      try {
        const response = yield PAGES_API.addPageToProject(projectId, uuid);
        if (response.isCancel) return;

        // const projectName = self.projects?.find(item => item.id === projectId);
        // loadPages(false, projectId);
        getAllProjects();
        // setCurrentProject(projectName?.name ?? '');
      } catch (e) {
        useErrorNotification({
          e,
          msg: 'Could not add article to project.',
          desc: 'Try again later.',
          permanent: false,
          handleStatuses: [{
            statuses: [401, 403],
            msg: 'Please sign up to edit this article.',
            permanent: false,
            showDetails: false,
          }],
        });
        return Promise.reject(e);
      } finally {
        self.projectsLoading = false;
      }
    });

    const filterPagesByProject = flow(function* (id: number) {
      self.loading = true;
      self.projectsLoading = true;
      try {
        const response = yield PAGES_API.filterPagesByProject(self.ordering, self.searchTerm, self.statusFilters.join(','), id, self.currentPage, self.pageSize);
        if (response.isCancel) return;

        setFromResponse(response);
        setContent(response.results);
      } catch (e) {
        return Promise.reject(e);
      } finally {
        self.projectsLoading = false;
        self.loading = false;
      }
    });

    const writeWithAiInBulk = flow(function* (payload: any) {
      self.loadingWriteWithAiInBulk = true;
      try {
        yield PAGES_API.createBulkPages(payload);
        loadPages();
      } catch (error) {
        Promise.reject(error);
      } finally {
        self.loadingWriteWithAiInBulk = false;
      }
    });

    const setWpSelectedKeyword = (value: any) => self.wpSelectedKeyword = value;
    const setRepollWpSelectedKeyword = (value: any) => self.repollWpSelectedKeyword = value;
    const setRepollCurrentPage = (value: number) => self.repollCurrentPage = value;
    const setRepollPageSize = (value: number) => self.repollPageSize = value;

    return {
      deletebulkUpdate,
      bulkUpdateData,
      setFromResponse,
      setPageRemovedFromEditor,
      setActiveProjectId,
      setCounterDefault,
      setCounter,
      setSearchTerm,
      setContent,
      setSummary,
      deletePage,
      syncWp,
      setArticlePagesTwenty,
      setCurrentPage,
      setArticleProjectName,
      loadPages,
      loadUpdatedPages,
      setArticlePages,
      loadPagesSummary,
      getAllProjects,
      createProjectsByName,
      deleteProject,
      renameProject,
      filterPagesByProject,
      setCurrentProject,
      setShowAddNewProjectModal,
      setShowRenameProjectModal,
      setProjectToRename,
      setShowDeleteProjectModal,
      addArticleToProject,
      setUpdatingPageUuid,
      updateContentProjectSettings,
      setShowAiProgressTooltip,
      getHeroImageById,
      setWpSelectedKeyword,
      setRepollWpSelectedKeyword,
      setRepollCurrentPage,
      setRepollPageSize,
      writeWithAiInBulk,
      loadDeletedArticles,
      exportToCSV,
      setProjectName,
      setRenamedProjectId,
      setisFolderSettingsFormValid,
    };
  })
  .actions(self => {
    const removeContentItem = () => {
      const newContent = self.content.filter(page => page.uuid !== self.deleteDialog.uuid);
      self.content = newContent as any;
      self.summary.totalArticles--;
    };

    const incrementInTotalArticle = () => {
      self.summary.totalArticles++;
    };

    const setOrdering = (ordering: string) => {
      self.ordering = ordering;
      self.setCurrentPage(1);
      self.loadPages(false, self.activeProjectId);
    };

    const setDaOrdering = (ordering: string) => {
      self.daOrdering = ordering;
      self.daCurrentPage = 1;
      self.loadDeletedArticles();
    };

    const handleSearchTermChange = (searchTerm: string) => {
      self.setSearchTerm(searchTerm);
      self.setCurrentPage(1);
      self.loadPages(false, self.activeProjectId);
    };

    const handlePagination = (page: number, pageSize: number) => {
      if (pageSize !== self.pageSize) {
        self.pageSize = pageSize;
        self.setCurrentPage(1);
        self.loadPages(false, self.activeProjectId);
      } else {
        self.currentPage = page;
        self.loadPages(false, self.activeProjectId);
      }
    };

    const handleDaPagination = (page: number, pageSize: number) => {
      if (pageSize !== self.daPageSize) {
        self.daPageSize = pageSize;
        self.daCurrentPage = 1;
        self.loadDeletedArticles();
      } else {
        self.daCurrentPage = page;
        self.loadDeletedArticles();
      }
    };

    const resetDaPagination = () => {
      self.daPageSize = 10;
      self.daCurrentPage = 1;
    };

    const handleTooltipClose = () => {
      self.showSwitchToTableTooltip = false;
    };


    const setStatusFilters = (name: string, checked: boolean) => {
      if (checked) {
        self.statusFilters = cast([...self.statusFilters, name]);
      } else {
        self.statusFilters = cast(self.statusFilters.filter(x => x !== name));
      }
      self.loadPages(false, self.activeProjectId);
    };


    return {
      handleTooltipClose,
      removeContentItem,
      setOrdering,
      setDaOrdering,
      handleSearchTermChange: debounce(handleSearchTermChange, QUERY_DEBOUNCE_WAIT),
      handlePagination,
      incrementInTotalArticle,
      setStatusFilters,
      handleDaPagination,
      resetDaPagination,
    };
  });

export type PagesStoreInstance = Instance<typeof Pages>;

export const initPagesStore = () => {
  return Pages.create({
    loading: true,
    initialLoading: true,
    loadingSummary: true,
    pageRemovedFromEditor: false,
    error: false,
    articlePages: 10,
    content: [],
    deletedArticles: [],
    summary: {
      averageContentScore: null,
      focustermsUsed: null,
      keywordsOptimized: null,
      totalArticles: null,
    },
    ordering: '-updated_at',
    daOrdering: '-updated_at',
    statusFilters: ['completed', 'in_progress', 'not_begun', 'needs_review'],
    searchTerm: '',
    count: 0,
    totalPages: 1,
    deleteDialog: DeleteDialog.create({isOpen: false, uuid: null}),
    currentPage: 1,
    pageSize: 10,
    counter: 40,
    showSwitchToTableTooltip: true,
    projects: [],
    projectsLoading: false,
    currentProject: '',
    updatingPageUuid: [],
    showAddNewProjectModal: false,
    showRenameProjectModal: false,
    projectToRename: {
      name: '',
      id: null,
    },
    showDeleteProjectModal: false,
    articleProjectName: '',
    activeProjectId: null,
    showAiProgressTooltip: false,
    wpSelectedKeyword: [''],
    repollWpSelectedKeyword: [''],
    repollCurrentPage: 0,
    repollPageSize: 0,
    loadingWriteWithAiInBulk: false,
    deletedArticlesLoading: false,
    daPageSize: 10,
    daCurrentPage: 1,
    daCount: 0,
    exportArticlesLoading: false,
    loadingSettingsUpdate: false,
    createProjectLoading: false,
    isFolderSettingsFormValid: {linksToInclude: true, companyInfo: true},
  });
};
