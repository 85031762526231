import {LoadingOutlined} from '@ant-design/icons';
import EChartsReact from 'echarts-for-react';
import {observer} from 'mobx-react';
import React from 'react';
import {FlexWrapper} from '../StyledComponent';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as duoTone from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  legendsData?: any;
  isSerps?: any;
  val?: any;
  left?: any;
  isBranded?: boolean;
  height?: number;
  top?: number;
}
export const LoadingChart: React.FC<Props> = observer(({legendsData, val, left, isSerps, isBranded, height, top}) => {
  const icons = ['faMagnifyingGlass', 'faComments', 'faLightbulb', 'faBagShopping', 'faVideo', 'faScissors', 'faImage', 'faStarSharpHalfStroke', 'faNewspaper', 'faMapPin', 'faBagShopping', 'faMessage', 'faLink'];
  const chartOptions = {
    grid: {
      top: top ? top : 25,
      bottom: 25,
      left: 50,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: !isSerps && ['Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr'],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          color: isBranded ? '#4E5156' : '#E8E8E8',
          showMinLabel: true,
          showMaxLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: val ? val : 2000,
        axisLabel: {
          color: isBranded ? '#4E5156' : '#E8E8E8',
        },
        splitLine: {
          lineStyle: {
            color: isBranded ? '#E8E8E8' : '#3E4043',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 0,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#E8E8E8',
        },
        splitLine: {
          lineStyle: {
            color: '#3E4043',
            type: 'dashed',
          },
        },
      },
      {
        type: 'value',
        min: 10,
        max: function(value) {
          return value.max;
        },
        axisLabel: {
          color: '#E8E8E8',
        },
        splitLine: {
          lineStyle: {
            color: '#3E4043',
            type: 'dashed',
          },
        },
      },
    ],
  };
  const antIcon=<LoadingOutlined style={{color: '#4e5156', fontSize: '30px'}} spin/>;
  return (
    <div style={{position: 'relative'}}>
      <div style={{position: 'absolute', left: left ? left : '50%', top: '50%'}}>{antIcon}</div>
      {(!isSerps || !isBranded) && <FlexWrapper>
        {legendsData?.map((item, index) => {
          return <div key={index}>
            <div>
              <div style={{lineHeight: '22px', whiteSpace: 'nowrap', marginLeft: '20px', color: '#fff', display: 'flex'}}><CheckBox/>&nbsp;&nbsp;{item.name}</div>
            </div>
          </div>;
        })}
      </FlexWrapper>}
      <EChartsReact
        style={{height: height}}
        option={chartOptions}
      />
      {isSerps && <FlexWrapper style={{gap: '8px', margin: '-8px 0 0 42px'}}>
        {icons?.map((item, idx)=> {
          return <FontAwesomeIcon key={idx} icon={duoTone[item]} color='#fff'/>;
        })}
      </FlexWrapper>}
    </div>
  );
});
const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  border-radius: 4px;
  background: var(--text-gray-dark, #4E5156);
`;
