import styled from 'styled-components';
import {Checkbox, Form, Input, Menu, Select} from 'antd';
import {Modal} from '@/components/common-components';
import {Button} from '@/components/common-components/v2';

const {TextArea} = Input;

export const TextAreaStyled = styled(TextArea)<{isError?: boolean}>`
  &:hover {
    border-color: ${p => p?.isError ? 'red !important' : ''};
  }
  &:focus {
    border-color: ${p => p?.isError ? 'red !important' : ''};
  }
`;

export const ModalStyled = styled(Modal)<{background?: string; borderRadius?: string}>`
  .rc-dialog-wrap {
    align-items: center !important;
  }
  .ant-divider-horizontal {
    margin: 0 !important;
  }
  .rc-dialog-body {
    padding: 30px 30px 0 30px !important;
    border-radius: ${p => p?.borderRadius ? p?.borderRadius : '16px'} !important;
    background: ${p => p?.background ? p?.background : '#FBFBFB'} !important;
  }
  .rc-dialog-close {
    font-size: 28px !important;
    color: white !important;
    left: 102% !important;
    top: -3px !important;
    opacity: 1 !important;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 15px;
`;

export const ButtonStyled = styled(Button)<{isCancel?: true; background?: string; width?: string; height?: string}>`
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  width: ${p => p?.isCancel ? 'fit-content' : p?.width ? p?.width : '187px'};
  height: ${p => p?.height ? p?.height : '44px'};
  font-weight: ${p => p?.isCancel ? '400' : '600'};
  background: ${p => p?.isCancel ? 'transparent' : p?.background ? p?.background : '#2D6CCA'};
  color: ${p => p?.isCancel ? '#4E5156' : ''};

  &:hover {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
  &:focus {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
  &:disabled {
    background: ${p => p?.isCancel ? 'none !important' : `linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),${p?.background ? p?.background : '#2D6CCA'} !important`};
    background-color: ${p => p?.isCancel ? 'none' : p?.background ? p?.background : '#7f4ead2E'};
    border: 1px solid transparent !important;
    color: ${p => p?.isCancel ? 'black' : '#fff'};
  }
`;

export const ButtonStyledV2 = styled(Button)<{width?: string}>`
  height: auto;
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  width: ${p => p?.width ? p?.width : '187px'};
  height: 44px;
  font-weight: 600;
  background: #2D6CCA;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:disabled {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
`;

export const Heading = styled.div`
  color: #000000;
  font-size: 24px;
  font-weight: 600;
`;

export const Description = styled.div`
  color: #4E5156;
  font-size: 14px;
  font-weight: 400;
`;

export const SectionsWrapper = styled.div`
  padding: 17px 0px;
`;

export const ProjectSectionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const KeywordSectionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const StyledInput = styled(Input)<{width?: string; height?: string; borderRadius?: string; isError?: boolean; isSeed?: boolean}>`
  width: ${p => p?.width};
  height: ${p => p?.height};
  flex-shrink: 0;
  border-radius: ${p => p?.borderRadius};
  border: ${p => p?.isError ? '1px solid red' : '1px solid #E8E8E8'};
  background: var(--Table-row-stripe, #FFFFFF);
  ${p => p?.isSeed && 'padding-right: 76px;'};
  @media screen and (max-width: 790px) {
   width: 100%;
  }
`;

export const KeywordsInputWrapper = styled.div<{isError: boolean}>`
  width: 600px;
  border-radius: 10px;
  border: ${p => p?.isError && '1px solid red'};
`;

export const KeywordSuggestionsWrapper = styled.div`
  background-color: rgba(242, 242, 245, 1);
  max-height: 80px;
  min-height: 36px;
  border-radius: 0px 0px 8px 8px;
  padding: 5px 0 5px 0;
  overflow-y: auto;
`;

export const KeywordPopulateWraper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: 0px 0px 8px 8px;
  padding-left: 15px;
  color: #4E5156;
  padding-bottom: 5px;
`;

export const KeywordSuggestions = styled.div`
  display: flex;
  flex-direction: row;
  width: 96%;
  gap: 5px;
  margin-left: 15px;
  overflow-x: auto;
  padding-bottom: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

export const SuggestedKeyword = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: 26px;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 38px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  margin-right: 5px;

  &:hover {
    background-color: rgba(45, 108, 202, 0.1);
    border: 1px solid #2D6CCA;
  }
`;

export const InputWithButton = styled.div`
  position: relative;
`;

export const PopulateButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66px;
  height: 20px;
  font-size: 12px;
  color: #2D6CCA;
  border-radius: 6px;
  background-color: rgba(45, 108, 202, 0.25);
  top: 4px;
  right: 4px;
  cursor: pointer;
`;

export const FiltersSectionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  word-wrap: break-word;

  .ant-select-selection-item {
    padding-right: 0px !important;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    top: -9px;
  }
`;

export const CustomRangeContainer = styled.div`
  padding: 10px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const RadioGropContainer = styled.div`    
  width: 100%;
  overflow: auto;
  max-height: 300px;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  ::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
  .ant-radio-checked .ant-radio-inner{
    border-color: #7F4EAD;
    background-color: #7F4EAD;
  }
  .ant-radio-inner:after {
    background: #fff !important;
  }
  .ant-radio-inner {
    border-radius:50%; 
    border-color: #CDCDCD;
    background-color: #F9F9FB;
  }
  .ant-radio-group {
    display: flex !important;
    flex-direction: column !important;
  }
`;

export const TextBlack = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

export const CheckboxGroupStyled = styled(Checkbox.Group)`
  width: 100% !important;
`;

export const InputStyled = styled.input`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  background: #F9F9FB;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  &:focus-visible {
    outline: none;
    border-color: #2D6CCA !important;
  }
`;

export const RangeInputContainer = styled.div<{isError?: boolean}>`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  input {
    height: 32px;
    width: 50%;
    background: #F9F9FB;
    border: 1px solid #E8E8E8;
    &:focus-visible {
      outline: none;
      > :first-child {
        border-color: #7F4EAD !important;
      } 
      > :last-child {
        border-color: ${p=> p?.isError ? '#F44343' : '#7F4EAD'} !important;
      }
    }
  }
  > :first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  > :last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-color: ${p=> p?.isError ? '#F44343' : '#E8E8E8'};
  }
`;

export const ApplyBtnWrapper = styled(Menu.Item)`
  width: 100%;
  padding: 0 !important;
  background-color: transparent !important;
  height: fit-content !important;
  margin-bottom: 0 !important;
`;

export const ApplyButton = styled(Button)`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  width: 100%;
  background: #2D6CCA !important;
  color: #fff;

  &:hover {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
  &:focus {
    background: linear-gradient(0deg,rgba(0,0,0,0.18),rgba(0,0,0,0.18)),#2D6CCA !important;
    background-color: #7f4ead2E;
    border: 1px solid transparent !important;
    color: #fff;
  }
`;

export const FilterTag = styled.div<{selected?: boolean; isDarkTheme?: boolean; isError?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  height: 28px;
  background: ${p=> p?.isDarkTheme ? '#4E515680' : '#FFFFFF'};
  border: ${p=> p?.isError ? '1px solid red' : p?.isDarkTheme ? '1px solid #4E515680' : '1px solid #CDCDCD'};
  color: ${p=> p?.isDarkTheme && '#fff'};
  border-radius: 6px;
  cursor: pointer;
  ${p => p.selected ? `
    background: rgba(127, 78, 173, 0.17);
    border: 1px solid #B498D0;
    ` : p?.isDarkTheme ? `
    &:hover {
      background: #4e5156;
    }
    &.ant-dropdown-open {
      background: #4e5156;
    }
    ` :
    `
    &:hover {
      background: #E8E8E8;
    }
    &.ant-dropdown-open {
      background: #E8E8E8;
    }
  `}
`;

export const FilterTagText = styled.div<{selected?: boolean; isDarkTheme?: boolean}>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #121212;
`;

export const EmailPresetSectionWrapper = styled.div`
`;

export const EmailPresetHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-top: 30px;
`;

export const EmailPresetDescription = styled.div`
  color: #4E5156;
  font-size: 14px;
  margin-top: 15px;
`;

export const EmailPresetFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 10px;
`;

export const SubjectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SenderWrappper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectStyled = styled(Select)<{isError?: boolean}>`
  width: 340px;
  height: 38px;
  display: flex;
  align-items: center;

  .ant-select-selector {
    border: 1px solid ${p => p?.isError ? 'red' : '#d9d9d9'} !important;
    border-radius: 8px !important;
  }
`;

export const MultipleSelectStyled = styled(Select)`
  width: 100%;
  .ant-select-selection-item {
    border-radius: 45px !important;
    &:hover {
      .ant-select-selection-item-remove {
    display: inline-block;
  }
    }
  }
  .ant-select-selection-item-remove {
    display: none;
  }
  .ant-select-selector {
    min-height: 38px !important;
    border-radius: 8px !important;
    border: 1px solid #E0E0E0 !important;
  }
`;

export const FooterWrapper = styled.div<{useTemplateChecked?: boolean}>`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-left: ${p => p?.useTemplateChecked ? '' : '78px'};
`;

export const BodyWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const EditorSectionWrapper = styled.div<{width?: string; height?: string}>`
  .DraftEditor-root {
    background-color: #fff !important;
    height: auto !important;
    max-height: auto !important;
    padding: 10px 25px !important;
    width: ${p => p?.width ? p?.width : '600px'} !important;
    border-radius: 0px 0px 8px 8px !important;
    border: 1px solid #E0E0E0 !important;
    margin: 0 !important;
    min-height: ${p => p?.height ? p?.height : '80px'} !important;
  }

  .public-DraftEditor-content {
    min-height: ${p => p?.height ? p?.height : '80px'} !important;
  }
`;

export const StylingSections = styled.div`
  display: flex;
  background-color: #F9F9FB;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #E8E8E8;
  padding: 5px 10px;
`;

export const MenuStyled = styled(Menu)`
  width: 200px;
  max-height: 300px;
  overflow: auto;
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
 
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;

export const FormItem = styled(Form.Item)`
  .ant-form-item-label{
    margin-right: 12px !important;
  }
`;

export const FormItemForCreate = styled(Form.Item)`
  margin-bottom: 0px !important;
`;

export const FormItemForBody = styled(Form.Item)`
  .ant-form-item-label{
    margin-right: 17px !important;
  }
`;

export const BlockListSectionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  .label {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    text-align: left;
    color: #121212;
    margin-right: 34px;
  }
`;
export const TypeRadioContainer = styled.div`
  height: 38px;
  background-color: #FBFBFB;
  padding: 6px 16px 6px 14px;
  border-bottom: 1px solid rgba(232, 232, 232, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ant-radio-wrapper {
    margin-right: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
  }
  .ant-radio-checked .ant-radio-inner{
    border-color: #2D6CCA;
    background-color: #2D6CCA;
  }
  .ant-radio-inner:after {
    background: #fff !important;
    width: 14px;
    height: 14px;
    margin-top: -7px;
    margin-left: -7px;
  }
  .ant-radio-inner {
    border-radius:50%; 
    border-color: #CDCDCD;
    background-color: #F9F9FB;
  }
`;

export const StyledTextArea = styled(TextArea)<{isError?}>`
  width: 600px;
  min-height: 85px !important;
  height: 85px !important;
  padding: 10px 15px;
  border-radius: 8px;
  background: #FFFFFF;
  border: ${p => p?.isError ? '1px solid red' : '1px solid #E0E0E0'};

  &:hover, &:focus, &:focus-visible {
    border: ${p => p?.isError ? '1px solid red' : '1px solid #E0E0E0'};
  }
`;
