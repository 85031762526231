import {notification as antdNotification} from 'antd';
import styles from './styles.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBug, faCheck, faInfo, faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import router from 'next/router';
import {ReactNode} from 'react';
import moment from 'moment';
import {getHttpStatusColor} from '../colors';
import {AxiosError} from 'axios';
import {openLiveChat} from '../intercom';

interface customStatusProps {
  statuses: number[];
  msg?: string;
  msgFromResponse?: boolean;
  desc?: string;
  permanent?: boolean;
  closeMsg?: string;
  showDetails?: boolean;
}

interface ErrorNotificationProps {
  e?: any;
  msg?: string;
  desc?: string;
  permanent?: boolean;
  closeMsg?: string;
  handleStatuses?: customStatusProps[];
  isQuotaIssue?: boolean;
}

/**
 * @method useErrorNotification
 *
 * @param {any} e error event object
 * @param {string} msg custom msg to show as the error title
 * @param {string} desc custom desc to show as the error description
 * @param {boolean} permanent will set notification to stay on the screen permanently until closed manually
 * @param {string} closeMsg closing modal message
 * @param {customStatusType} handleStatuses array of custom handling of diff statuses
 */

const getResponseUrlWithoutDomain = url => {
  // eslint-disable-next-line no-useless-escape
  return url.replace(/^.*\/\/[^\/]+/, '');
};

export const useErrorNotification = ({
  e, msg = '', desc = '', permanent = false, closeMsg = 'Refresh page', handleStatuses = [], isQuotaIssue = true,
}: ErrorNotificationProps) => {
  if (msg === 'Network Error') {
    // eslint-disable-next-line
    console.warn('notification error', msg);
    return;
  }
  const defaultHandle = () => notification.error(msg, desc, permanent, closeMsg, e.response?.status, e.request?.responseURL);
  const quotaDefaultHandle = e => {
    if (e?.response?.status === 429) {
      notification.error(msg, e.response?.data?.message, permanent, closeMsg, e.response?.status, e.request?.responseURL, true, isQuotaIssue);
    }
  };

  /*
  * HANDLE AXIOS EXCEPTIONS (quota 429 also goes here)
  * update notification with status and request url
  * status = e?.response?.status
  * url = e?.request?.responseURL
  */
  if (e && e instanceof AxiosError) {
    // if custom status handling not passed
    if (!handleStatuses.length) {
      if (e?.response?.status === 429) {
        quotaDefaultHandle(e);
        return;
      }
      defaultHandle();
      return;
    }

    // if custom status handling passed
    if (handleStatuses?.length) {
      // if quota is not handled in custom status array, we are handling it by default
      if (!handleStatuses.filter(statusItem => statusItem.statuses?.includes(429))?.length) {
        quotaDefaultHandle(e);
      }

      let allStatuses = [];
      handleStatuses.forEach(customItem => {
        allStatuses = [...allStatuses, ...(customItem.statuses ?? [])];
      });

      if (!allStatuses.includes(e?.response?.status)) {
        defaultHandle();
      } else {
        handleStatuses.forEach(statusItem => {
          if (statusItem.statuses?.includes(e?.response?.status)) {
            notification.error(statusItem.msg ?? '', statusItem.desc ?? e.response?.data?.message ?? '', statusItem.permanent, statusItem.closeMsg ?? '', e.response?.status, e.request?.responseURL, statusItem.showDetails ?? true);
          }
        });
      }
    }


    return;
  }

  /*
  * HANDLE OTHER EXCEPTIONS
  * show regular notifications
  * we are passing 0 as status, so notification knows that it has been called using this hook, and should show all advanced details
  * when notification is not called using this hook, we should not be showing details
  */
  notification.error(msg, desc, permanent, closeMsg, 0 );
};


export const notification = {
  error(message: string, description: string, permanent?:boolean, closeMessage:string='Refresh page', status?: number, failingUrl?: string, showDetails = true, isQuotaIssue = false, showTopup?: boolean, setTopUpBulkVisibility?: any, setShowDesc?: any, link?: string) {
    if (description === 'Network Error') {
      // eslint-disable-next-line
      console.warn('notification error', description);
      return;
    }
    const key = `key${Date.now()}`;
    const dateStamp = moment(new Date()).format('ll');
    const timeStampt = moment(new Date()).format('LT');

    return antdNotification.error({
      message:
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
          <span className={styles.description} style={link?.length ? {marginBottom: '0px'} : {}}>{description}</span>
          {link?.length ? <a className={styles.link} href={link} target='_blank' rel='noreferrer'>{link}</a> : ''}
          <div className={styles.timeStamp}>
            {`Detected: ${dateStamp} at ${timeStampt}`}
          </div>

          {status && showDetails ? <div>
            <div className={styles.issueTypeContainer}>Issue type: <span style={{fontWeight: 500, display: 'inlineBlock', marginLeft: 5}}>{isQuotaIssue ? 'Exhausted quota' : status == 0 ? 'FE' : 'API Fail'}</span></div>
            {status && !isQuotaIssue ? <div className={styles.issueTypeContainer}>Status: <span style={{color: getHttpStatusColor(status)}} className={styles.issueStatus}>{status}</span></div> : null}
            {failingUrl && !isQuotaIssue ? <div className={styles.issueTypeContainer}>Request URL: <a href={failingUrl} target='_blank' className={styles.issueUrl} rel='noreferrer'>{getResponseUrlWithoutDomain(failingUrl)}</a></div> : null}
          </div> : null}

          <div className={styles.buttons}>
            {showTopup ? <span className={styles.btn}
              onClick={() => {
                setShowDesc(false);
                setTopUpBulkVisibility(true);
              }}
            >
              Top up
            </span> :
              <>
                <span className={styles.btn} onClick={() => {
                  if (closeMessage == 'View Pricing') {
                    router.push('/pricing');
                  } else if (closeMessage == 'OK') {
                    antdNotification.close(key);
                  } else {
                    location.reload();
                  }
                }}>{closeMessage}</span>
                <span className={classnames(styles.btn, styles.contactSupportLink)} onClick={() => openLiveChat()}>Contact support</span>
              </>
            }
          </div>
        </div>,
      placement: 'topRight',
      duration: permanent ? 0 : 10.5,
      key,
      className: classnames(styles.notification, styles.errorNotification),
      icon: <div className={styles.icon}><FontAwesomeIcon icon={faBug} /></div>,
    });
  },

  success(message: string | ReactNode, description: string | ReactNode, closeMessage:string='Continue', onClickHandler?: any) {
    const key = `key${Date.now()}`;
    return antdNotification.success({
      message:
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
          <span className={styles.description}>{description}</span>
          <span className={styles.btn} onClick={() => {
            onClickHandler && onClickHandler();
            antdNotification.close(key);
          }}>{closeMessage}</span>
        </div>,
      placement: 'topRight',
      key,
      className: classnames(styles.notification, styles.successNotification),
      icon: <div className={styles.icon}><FontAwesomeIcon icon={faCheck} /></div>,
    });
  },

  info(message: string, description: string, label?: string, action?: any, duration?: number) {
    const key = `key${Date.now()}`;

    const callback = () => {
      if (label) {
        return <span className={classnames(styles.btn, styles.contactSupportLink)} onClick={() => {
          if (action) {
            action();
          } else {
            return;
          }
        }}>{label}</span>;
      }

      return null;
    };

    return antdNotification.info({
      message:
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
          <span className={styles.description}>{description}</span>
          <div className={styles.buttons}>
            <span className={styles.btn} onClick={() => antdNotification.close(key)}>Dismiss</span>
            {callback()}
          </div>
        </div>,
      placement: 'topRight',
      key,
      className: classnames(styles.notification, styles.infoNotification),
      icon: <div className={styles.icon}><FontAwesomeIcon icon={faInfo} /></div>,
      ...(duration && {duration}),
    });
  },

  warning(message: string | ReactNode, description: string | ReactNode, label?: string, action?: any) {
    const key = `key${Date.now()}`;
    const dateStamp = moment(new Date()).format('ll');
    const timeStampt = moment(new Date()).format('LT');
    const callback = () => {
      if (label) {
        return <span className={classnames(styles.btn, styles.contactSupportLink)} onClick={() => {
          if (action) {
            action();
          } else {
            return;
          }
        }}>{label}</span>;
      }

      return null;
    };

    return antdNotification.info({
      message:
        <div className={styles.notificationContent}>
          <span className={styles.title}>{message}</span>
          <span className={styles.description}>{description}</span>
          <div className={styles.timeStamp}>
            {`Detected: ${dateStamp} at ${timeStampt}`}
          </div>
          <div className={styles.buttons}>
            <span className={styles.btn} onClick={() => antdNotification.close(key)}>Dismiss</span>
            {callback()}
          </div>
        </div>,
      placement: 'topRight',
      key,
      className: classnames(styles.notification, styles.warningNotification),
      icon: <div className={styles.icon}><FontAwesomeIcon icon={faExclamationTriangle} /></div>,
    });
  },
  destroy() {
    return antdNotification.destroy();
  },
};
