import {Modal} from '@/components/common-components';
import {faArrowRight, faCircleInfo, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FieldWrapper} from '../../otto-page-detail/Modals/addPostModal';
import {Form, Input, Tabs} from 'antd';
import {Button} from '@/components/common-components/v2';
import ModalContent from '../../landing-page-optimizer/blocks/hero/folder-settings/modalContent';
import {useStore} from '@/store/root-store';
import {domainValidationRegex, urlWithProtocolRegex} from '@/constants/regex';
import {addProtocolToDomainHttps, getSingleUrlParam} from '@/utils/url';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {TopupModal} from '../../otto-page-detail/Modals/topupModal';
import {notification} from '@/utils/notification-v2';
import {CustomNumberInput} from '@/components/common-components/components/custom-number-input';

interface CreateTopicalMapModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  isUsingInOttoTopicMaps?: boolean;
}
const tabsTitle = [
  {
    step: 1,
    key: 'choose_a_topic',
    title: 'Choose a topic',
  },
  {
    step: 2,
    key: 'ai_writing_settings',
    title: 'AI Writing Settings',
  },
];
const getTitle = tab => (
  <div className='tab-title'>
    <div className='step'>Step {tab.step}</div>
    <div className='text'>{tab.title}</div>
  </div>
);
const linksToIncludeInitialState = {
  isEmpty: true,
  items: [{
    value: '',
    anchorText: '',
    label: 'Link',
    placeholder: 'Link',
    fields: [

    ],
    required: true,
  },
  {
    value: '',
    anchorText: '',
    label: 'Link',
    placeholder: 'Link',
    fields: [

    ],
    required: false,
  },
  ],
};
const externalLinksToIncludeInitialState = {
  isEmpty: true,
  items: [{
    value: '',
    anchorText: '',
    label: 'Link',
    placeholder: 'Link',
    fields: [
    ],
    required: true,
  },
  {
    value: '',
    anchorText: '',
    label: 'Link',
    placeholder: 'Link',
    fields: [
    ],
    required: false,
  },
  ],
};
const initialErrorState = {
  'url': {
    message: 'Pillar page URL is required',
    state: false,
  },
  'keyword': {
    message: 'Target keyword is required',
    state: false,
  },
  'clusters': {
    message: 'At least 1',
    state: false,
  },
  'longTails': {
    message: 'At least 1',
    state: false,
  },
  'titles': {
    message: 'At least 1',
    state: false,
  },
};
const topUpBulkVisibilityInitialState = {
  visibility: false,
  price: 0,
  title: '',
  options: [],
  consumed: 0,
  total: 0,
  recurring: '',
  quotaKey: '',
  desc: '',
  buttonTxt: '',
  overAllQuota: 0,
  nextPaymentAt: null,
  step: 0,
  multiplesOfStep: false,
  unitIsMultipleOfStep: false,
  wholeCredits: false,
};

export const CreateTopicalMapModal: React.FC<CreateTopicalMapModalProps> = observer(({openModal, setOpenModal, isUsingInOttoTopicMaps=false}) => {
  const {
    contentOptimizer: {
      contentIdeas: {createTopicalMapData, getTopicalMapData, setTopicalMapParams},
      pages: {getAllProjects, setActiveProjectId},
    },
    settings: {
      customer: {getCustomerQuota, profile: {quotaUtilization, nextPaymentAt, isLinkgraph}}},
    ottoV2Store: {
      getOttoV2Project,
      createOttoTopicalMap,
      loadIssueTableData,
      loadOttoV2Project,
      knowledgeGraph,
      getKnowledgeGraphData,
      quotaAllocations,
      setIsDeploying,
    }} = useStore('');
  const uuid = getSingleUrlParam('uuid');
  const [knowledgeGraphValues, setKnowledgeGraphValues] = useState(null);
  const [activeTab, setActiveTab] = useState('choose_a_topic');
  const [maxAccessTab, setMaxAccessTab] = useState(['choose_a_topic']);
  const [termsToExclude, setTermsToExclude] = useState([]);
  const [selectedToneOfVoice, setSelectedToneOfVoice] = useState('Professional');
  const [wpId, setWpId] = useState(null);
  const [clusters, setClusters] = useState('1');
  const [longTails, setLongTails] = useState('1');
  const [titles, setTitles] = useState('1');
  const [currentProjectData] = useState({});
  const [terms, setTerms] = useState('');
  const [slug, setSlug] = useState('');
  const [keyword, setKeyword] = useState('');
  const [form] = Form.useForm();
  const [linksToIncludeState, setLinksToIncludeState] = useState<any>(linksToIncludeInitialState);
  const [externalLinksToIncludeState, setExternalLinksToIncludeState] = useState<any>(externalLinksToIncludeInitialState);
  const [errorStates, setErrorStates] = useState(initialErrorState);
  const domainName = addProtocolToDomainHttps(getOttoV2Project?.hostname);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState(topUpBulkVisibilityInitialState);
  const [isFormSubmitPass, setIsFormSubmitPass] = useState(false);
  const [isFormSubmitFail, setIsFormSubmitFail] = useState(false);

  const loadKnowledgeGraphData = async () => {
    await getKnowledgeGraphData(getOttoV2Project?.knowledgeGraphId);
  };
  useEffect(() => {
    if (!knowledgeGraph) {
      loadKnowledgeGraphData();
    }
    if (knowledgeGraph) {
      setKnowledgeGraphValues(knowledgeGraph);
    }
  }, [knowledgeGraph]);

  useEffect(() => {
    if (isFormSubmitPass && !isFormSubmitFail) {
      async () => {
        await getCustomerQuota();
      };
      setLoadingSubmit(false);
      setOpenModal(false);
      setIsEdit(false);
      setMaxAccessTab(['choose_a_topic']);
      setActiveTab('choose_a_topic');
      setIsFormSubmitPass(false);
      setIsFormSubmitFail(false);
      notification.success('', 'Folder settings updated successfully');
    }
    if (isFormSubmitFail && !isFormSubmitPass) {
      setLoadingSubmit(false);
      setIsFormSubmitPass(false);
      setIsFormSubmitFail(false);
    }
  }, [isFormSubmitPass, isFormSubmitFail]);

  const onChangeUrl = (value: string) => {
    setSlug(value);
    let validUrl = false;
    if (isUsingInOttoTopicMaps) {
      validUrl = domainValidationRegex.test(domainName+'/'+value);
    } else {
      validUrl = domainValidationRegex.test(value);
    }
    if (isUsingInOttoTopicMaps) {
      if (!validUrl) {
        setErrorStates({...errorStates, url: {...errorStates['url'], message: `Provide valid url like https://example.com`, state: true}});
        return;
      } else {
        setErrorStates({...errorStates, url: {...errorStates['url'], message: `Provide valid url like https://example.com`, state: false}});
      }
    } else {
      if (value) {
        setErrorStates({...errorStates,
          url: {...errorStates['url'],
            message: !validUrl ? `Provide valid url like example.com` : 'Pillar page URL is required',
            state: !validUrl ? true : false},
        });
        return;
      } else {
        setErrorStates({...errorStates,
          url: {...errorStates['url'], message: 'Pillar page URL is required', state: false},
        });
      }
    }
  };
  const onKeywordChange = (value: string) => {
    setKeyword(value);
    if (!value) {
      setErrorStates({...errorStates, keyword: {...errorStates['keyword'], state: true}});
      return;
    } else {
      setErrorStates({...errorStates, keyword: {...errorStates['keyword'], state: false}});
    }
  };
  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>, usingFor: string) => {
    const inputValue = e?.target?.value;
    const replacedValue = inputValue.replace(/[^0-9]/g, '');
    if (replacedValue === '') {
      if (usingFor === 'clusters') {
        setClusters('');
        setErrorStates({...errorStates, clusters: {...errorStates['clusters'], state: true}});
      } else if (usingFor === 'longTails') {
        setLongTails('');
        setErrorStates({...errorStates, longTails: {...errorStates['longTails'], state: true}});
      } else if (usingFor === 'titles') {
        setTitles('');
        setErrorStates({...errorStates, titles: {...errorStates['titles'], state: true}});
      }
      return;
    }
    const numericValue = Number(replacedValue);
    if (numericValue > 10) {
      if (usingFor === 'clusters') {
        setClusters('10');
      } else if (usingFor === 'longTails') {
        setLongTails('10');
      } else if (usingFor === 'titles') {
        setTitles('0');
      }
    } else if (numericValue < 1) {
      if (usingFor === 'clusters') {
        setClusters('1');
        setErrorStates({...errorStates, clusters: {...errorStates['clusters'], state: false}});
      } else if (usingFor === 'longTails') {
        setLongTails('1');
        setErrorStates({...errorStates, longTails: {...errorStates['longTails'], state: false}});
      } else if (usingFor === 'titles') {
        setTitles('1');
        setErrorStates({...errorStates, titles: {...errorStates['titles'], state: false}});
      }
      return;
    } else {
      if (usingFor === 'clusters') {
        setClusters(replacedValue);
        setErrorStates({...errorStates, clusters: {...errorStates['clusters'], state: false}});
      } else if (usingFor === 'longTails') {
        setLongTails(replacedValue);
        setErrorStates({...errorStates, longTails: {...errorStates['longTails'], state: false}});
      } else if (usingFor === 'titles') {
        setTitles(replacedValue);
        setErrorStates({...errorStates, titles: {...errorStates['titles'], state: false}});
      }
    }
  };
  const onCountKeyDown = (e: any, usingFor) => {
    if (usingFor === 'clusters') {
      if (e?.keyCode === 38 && Number(clusters) < 10) {
        setClusters(prevState => String(Number(prevState)+1));
        setErrorStates({...errorStates, clusters: {...errorStates['clusters'], state: false}});
      } else if (e?.keyCode === 40 && Number(clusters) > 1) {
        setClusters(prevState => String(Number(prevState)-1));
      }
    } else if (usingFor === 'longTails') {
      if (e?.keyCode === 38 && Number(longTails) < 10) {
        setLongTails(prevState => String(Number(prevState)+1));
        setErrorStates({...errorStates, longTails: {...errorStates['longTails'], state: false}});
      } else if (e?.keyCode === 40 && Number(longTails) > 1) {
        setLongTails(prevState => String(Number(prevState)-1));
      }
    } else if (usingFor === 'titles') {
      if (e?.keyCode === 38 && Number(titles) < 10) {
        setTitles(prevState => String(Number(prevState)+1));
        setErrorStates({...errorStates, titles: {...errorStates['titles'], state: false}});
      } else if (e?.keyCode === 40 && Number(titles) > 1) {
        setTitles(prevState => String(Number(prevState)-1));
      }
    }
  };
  const steps = {
    choose_a_topic: (<>
      <Field style={{display: 'block', marginTop: 0}}>
        <div className='label' style={{marginTop: 12, marginBottom: 10, width: '100%'}}>Pillar Page URL (Optional)</div>
        <div className='field-container'>
          <div className='body'
            style={{borderColor: errorStates['url']?.state ? '#f96262': '', padding: 0}}
          >
            <div className='domain-input-wrapper'>
              {isUsingInOttoTopicMaps ?
                (<>
                  <div className='domain'>{domainName}/</div>
                  <Input className='slug-only' value={slug} onChange={e => onChangeUrl(e?.target?.value)} bordered={false}></Input>
                </>) :
                <Input value={slug} className='slug' onChange={e => onChangeUrl(e?.target?.value)} bordered={false}/>}
            </div>
          </div>
          {errorStates['url'].state ? <div className='error-message'>{errorStates['url'].message}</div> : <></>}
        </div>
      </Field>
      <Field style={{display: 'block', marginTop: 0}}>
        <div className='label' style={{marginBottom: 10, width: '100%'}}>Target Keyword (Topic)</div>
        <div className='field-container'>
          <div className='body'
            style={{borderColor: errorStates['keyword']?.state ? '#f96262': '', padding: 0}}
          >
            <Input className='slug' value={keyword} onChange={e => onKeywordChange(e?.target?.value)} bordered={false} placeholder={`e.g “chocolate donuts”`}/>
          </div>
          {errorStates['keyword'].state ? <div className='error-message'>{errorStates['keyword'].message}</div> : <></>}
        </div>
      </Field>
      <Banner style={{display: 'flex', alignItems: 'center'}}>
        <FontAwesomeIcon icon={faCircleInfo} color='#2D6CCA' fontSize={14}/>
        <span style={{lineHeight: 'normal'}}>Input the number of clusters (categories) you want to create based on your target keyword. Next, specify the number of long-tail keywords to be generated within each cluster. Finally, choose the quantity of article titles to be generated for each long-tail keyword.</span>
      </Banner>
      <ClustersWrapper hasInputError={errorStates['clusters'].state || errorStates['longTails'].state || errorStates['titles'].state}>
        <CustomNumberInput
          hasError={errorStates['clusters'].state}
          label='Clusters (Categories)'
          valueState={clusters}
          setValueState={setClusters}
          onInputChange={e => onChangeCount(e, 'clusters')}
          onInputKeyDown={e => onCountKeyDown(e, 'clusters')}
          min={1}
          max={10}
        />
        <div className='x-wrapper'>x</div>
        <CustomNumberInput
          hasError={errorStates['longTails'].state}
          label='Long tails'
          valueState={longTails}
          setValueState={setLongTails}
          onInputChange={e => onChangeCount(e, 'longTails')}
          onInputKeyDown={e => onCountKeyDown(e, 'longTails')}
          min={1}
          max={10}
        />
        <div className='x-wrapper'>x</div>
        <CustomNumberInput
          hasError={errorStates['titles'].state}
          label='Title'
          valueState={titles}
          setValueState={setTitles}
          onInputChange={e => onChangeCount(e, 'titles')}
          onInputKeyDown={e => onCountKeyDown(e, 'titles')}
          min={1}
          max={10}
        />
        <div className='total-count-wrapper'>= {Number(clusters)*Number(longTails)*Number(titles) ?? 1} article idea{Number(clusters)*Number(longTails)*Number(titles) > 1 ? 's': ''}</div>
        {(errorStates['clusters'].state || errorStates['longTails'].state || errorStates['titles'].state ) ?
          <CountErrorWrapper>
            {errorStates['clusters'].state && 'Clusters count, '}
            {errorStates['longTails'].state && 'Long Tails count, '}
            {errorStates['titles'].state && 'Titles count '}
          must me at least 1</CountErrorWrapper> :
          <></>}
      </ClustersWrapper>
      <TotalLimitWrapper>Total upper limit is 1,000 results (10x10x10)</TotalLimitWrapper>
    </>),
    ai_writing_settings: (
      <div style={{marginTop: '12px'}}>
        <ModalContent
          form={form}
          isEdit={isEdit}
          termsToExclude={termsToExclude}
          setTermsToExclude={setTermsToExclude}
          wpId={wpId}
          setWpId={setWpId}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          currentProjectData={currentProjectData}
          terms={terms}
          setTerms={setTerms}
          linksToIncludeState={linksToIncludeState}
          setLinksToIncludeState={setLinksToIncludeState}
          externalLinksToIncludeState={externalLinksToIncludeState}
          setExternalLinksToIncludeState={setExternalLinksToIncludeState}
          setVisible={setOpenModal}
          setIsEdit={setIsEdit}
          isInTab={true}
          showUpdateNotification={false}
          setIsFormSubmitPass={setIsFormSubmitPass}
          setIsFormSubmitFail={setIsFormSubmitFail}
        />
      </div>
    ),
  };
  const nextStep = async () => {
    let validUrl = false;
    if (isUsingInOttoTopicMaps) {
      validUrl = urlWithProtocolRegex.test(domainName+'/'+slug);
      if (!keyword || !validUrl || Number(clusters) < 1 || Number(longTails) < 0 || Number(titles) < 0) {
        setErrorStates({...errorStates,
          keyword: {...errorStates['keyword'], state: !keyword ? true : false},
          url: {...errorStates['url'],
            message: !validUrl ? `Provide valid url like https://example.com` : 'Pillar page URL is required',
            state: !validUrl? true : false},
          clusters: {...errorStates['clusters'], state: Number(clusters) < 1 ? true : false},
          longTails: {...errorStates['longTails'], state: Number(longTails) < 1 ? true : false},
          titles: {...errorStates['titles'], state: Number(titles) < 1 ? true : false},
        });
        return;
      }
    } else {
      validUrl = domainValidationRegex.test(slug);
      if (!keyword || (slug.length && !validUrl) || Number(clusters) < 1 || Number(longTails) < 0 || Number(titles) < 0) {
        setErrorStates({...errorStates,
          keyword: {...errorStates['keyword'], state: !keyword ? true : false},
          url: {...errorStates['url'],
            message: !validUrl ? `Provide valid url like example.com` : 'Pillar page URL is required',
            state: !validUrl ? true : false},
          clusters: {...errorStates['clusters'], state: Number(clusters) < 1 ? true : false},
          longTails: {...errorStates['longTails'], state: Number(longTails) < 1 ? true : false},
          titles: {...errorStates['titles'], state: Number(titles) < 1 ? true : false},
        });
        return;
      }
    }
    try {
      if (activeTab === 'choose_a_topic') {
        setLoadingSubmit(true);
        const payload = {
          content_topic: keyword,
          clusters_count: Number(clusters),
          keywords_count: Number(longTails),
          titles_count: Number(titles),
          language_code: 'en',
        };
        const defaultAiSettingsPayload = {
          llm: 'claude',
          language: 'en',
          tone_of_voice: 'Professional',
          point_of_view: 'First person',
          reading_level: 'College',
          length: 'Medium',
          faq_section: 'short',
          generate_metadata: true,
          use_list: true,
          use_table: true,
          generate_hero_image: true,
          generate_images: true,
          images_aspect_ratio: '8:3',
          ...((('businessName' || 'gbpUrl' || 'streetAddress' || 'emailAddress' || 'phoneNumber') in knowledgeGraphValues) && {company_info: {
            ...(('businessName' in knowledgeGraphValues && knowledgeGraphValues.businessName) && {company_name: knowledgeGraphValues.businessName}),
            ...(('gbpUrl' in knowledgeGraphValues && knowledgeGraphValues.gbpUrl) && {gbp_url: knowledgeGraphValues.gbpUrl}),
            ...(('streetAddress' in knowledgeGraphValues && 'addressLocality' in knowledgeGraphValues && 'addressRegion' in knowledgeGraphValues) && (knowledgeGraphValues.streetAddress) && {
              business_address: `${knowledgeGraphValues.streetAddress}, ${knowledgeGraphValues.addressLocality ? knowledgeGraphValues.addressLocality : ''} ${knowledgeGraphValues.addressRegion ? knowledgeGraphValues.addressRegion : ''}`,
            }),
            ...(('emailAddress' in knowledgeGraphValues && knowledgeGraphValues.emailAddress) && {email: knowledgeGraphValues.emailAddress}),
            ...(('phoneNumber' in knowledgeGraphValues && knowledgeGraphValues.phoneNumber) && {phone: knowledgeGraphValues.phoneNumber}),
          },
          }),
        };
        if (isUsingInOttoTopicMaps) {
          const globalLink = new URL(domainName);
          defaultAiSettingsPayload['required_internal_links'] = [{anchor: null, url: globalLink?.origin}];
          payload['content_page'] = `${domainName+'/'+slug}`;
          payload['otto_project'] = uuid;
        } else {
          if (slug) {
            payload['content_page'] = slug;
          }
        }
        let res = null;
        if (isUsingInOttoTopicMaps) {
          res = await createOttoTopicalMap(payload, true, defaultAiSettingsPayload);
        } else {
          res = await createTopicalMapData(payload);
        }
        if (res) {
          await getAllProjects();
          setIsEdit(true);
          setActiveProjectId(res?.project);
          setActiveTab('ai_writing_settings');
          setMaxAccessTab([...maxAccessTab, 'ai_writing_settings']);
          setLoadingSubmit(false);
          notification.success('', 'Topical map created successfully');
          if (isUsingInOttoTopicMaps) {
            setIsDeploying(true);
            if (uuid) {
              await loadOttoV2Project(uuid, true);
            }
            const params = {
              uuid,
              issue_type: 'topical_maps_and_supplemental_content',
              page_size: 10,
              page: 1,
              is_loading: false,
            };
            // isSitewide is related to alt image text
            await loadIssueTableData(params, true);
          } else {
            setTopicalMapParams({page: 1, pageSize: 10});
            await getTopicalMapData();
          }
        }
      } else {
        form.submit();
        const nameValue = form.getFieldValue('name');
        if (nameValue && nameValue?.length < 100 ) {
          setLoadingSubmit(true);
        }
      }
    } catch (error) {
      setLoadingSubmit(false);
      setIsFormSubmitPass(false);
      setIsFormSubmitFail(false);
      Promise.reject(error);
    }
  };
  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };
  return ( <StyledCreateTopicalMapModal
    width={'750px'}
    height={'90vh'}
    zIndex={900}
    style={{top: '-15px'}}
    closable={true}
    visible={openModal}
    onClose={() => setOpenModal(false)}
    closeIcon={<div><FontAwesomeIcon color={'#fff'} fontSize={22} icon={faXmark} /></div>}
  >
    <StyledModalHeader>
      <div className='modal-heading'>Create Topical Map</div>
      {isUsingInOttoTopicMaps && <QuotaInfoDetail
        isCenter
        consumedData={quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed}
        totalData= {quotaUtilization?.ca?.allowedOttoAiSuggestions?.total}
        heading={'AI Generation'}
        description={'Quota Utilization AI Generation'}
        showCta
        ctaLabel={'Top up'}
        ctaIcon={<FontAwesomeIcon icon={faCoins} color='#2AC155' style={{marginRight: '5px'}} />}
        varient={'green'}
        customCta={() => {
          setTopUpBulkVisibility({
            visibility: true,
            price: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.pricePerUnit * 500 || 0,
            title: 'AI Generation Quota',
            options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
            recurring: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.topOptions[0],
            quotaKey: getTopupQuotaConf('quota_ca_allowed_otto_ai_suggestions')?.quotaKey,
            desc: 'per 500 points',
            buttonTxt: 'AI Generation',
            overAllQuota: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
            nextPaymentAt,
            step: 500,
            multiplesOfStep: true,
            unitIsMultipleOfStep: true,
            total: quotaUtilization?.ca?.allowedOttoAiSuggestions?.total,
            consumed: quotaUtilization?.ca?.allowedOttoAiSuggestions?.consumed,
            wholeCredits: true,
          });
        }}
      />}
    </StyledModalHeader>
    <StyledTabs activeKey={activeTab}
      onChange={() => {}}
      bottomBarWidth={activeTab === 'choose_a_topic' ? '350px' : '700px'}
    >
      {tabsTitle.map(tab => (
        <Tabs.TabPane tab={getTitle(tab)} key={tab.key} disabled={!maxAccessTab.includes(tab.key)}>
          {steps[tab.key]}
        </Tabs.TabPane>
      ))}
    </StyledTabs>
    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 25}}>
      <Button background='transparent' textColor='#4E5156' style={{color: 'black'}} onClick={() => setOpenModal(false)}>Cancel</Button>
      <Button
        background='#7F4EAD'
        style={{marginRight: 0}}
        textColor='#FFFFFF'
        loading={loadingSubmit}
        disabled={loadingSubmit}
        htmlType={'button'}
        onClick={nextStep}>
        {activeTab === 'choose_a_topic' ? <>Create & Continue <FontAwesomeIcon icon={faArrowRight} fontSize={12} style={{marginLeft: '15px'}} /></> : 'Update Settings'}
      </Button>
    </div>
    <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
  </StyledCreateTopicalMapModal>);
});

const StyledCreateTopicalMapModal = styled(Modal)`
   .rc-dialog-content {
    .rc-dialog-body {
      background-color: #F9F9FB;
      padding: 25px !important;
      border-radius: 10px;
    }
    .rc-dialog-close {
    font-size: 21px;
    right: -34px;
    top: 0px;
    font-weight: 700;
    color: #fff !important;
    opacity: 0.8 !important;
    &:hover {
      opacity: 1 !important;
    }
    }
  }
`;
const StyledModalHeader = styled.div`
  padding: 0px !important;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .modal-heading {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #121212;
  }
  .modal-description {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const Field = styled(FieldWrapper)`
  .label {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #4E5156;
  }
  .field-container {
    .body {
      padding: 0px !important;
      .domain-input-wrapper {
        .slug {
        padding: 10px 12px;
      }
      .slug-only {
        padding: 10px 12px 10px 0px;
      }
      }
      .slug {
        padding: 10px 12px;
      }
    }
  }
`;
const Banner = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background-color: rgba(45, 108, 202, 0.14);
  margin-bottom: 10px;
  span {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
  }
`;
const StyledTabs = styled(Tabs)<{bottomBarWidth: string}>`
  .ant-tabs-nav {
    margin: 0px !important;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        width: 100%;
        .ant-tabs-tab {
          width: 50%;
          padding: 6px 0px !important;
          .ant-tabs-tab-btn {
            width: 100%;
            .tab-title {
              .step {
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                font-weight: 400;
                color: #4E5156;
              }
              .text {
                font-family: 'Inter', sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #121212;
              }
            }
          }
        }
        .ant-tabs-tab + .ant-tabs-tab {
          margin: 0px !important;
        }
        .ant-tabs-ink-bar {
          background: #7F4EAD !important;
          height: 4px !important;
          border-radius: 24px;
          width: 50%;
          left: 0px !important;
          width: ${p => p.bottomBarWidth} !important;
        }
      }
    }
  }
`;
const ClustersWrapper= styled.div<{hasInputError?: boolean}>`
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  margin-bottom: ${p => p?.hasInputError ? '24px' : '10px'} !important;
  .input-wrapper {
    width: 130px;
    position: relative;
    .input-label {
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
      color: #4E5156;
    }
    .number-input {
      border: 1px solid #D8D8D8 !important;
      border-radius: 8px;
      overflow: hidden;
      .ant-input-number-handler-wrap {
        z-index: 1050 !important;
        transition: none !important;
        height: 86% !important;
      }
      &:hover {
        .ant-input-number-handler-wrap {
          .ant-input-number-handler {
            border-left: 0px transparent !important;
          }
          .ant-input-number-handler-up {
            .ant-input-number-handler-up-inner {
              margin-top: 0px !important;
            }
            &:hover {
              height: 50% !important;
            }
          }
          .ant-input-number-handler-down {
            border-top: 0px transparent !important;
            &:hover {
              height: 50% !important;
            }
          }
          &:hover {
            .ant-input-number-handler-up {
              height: 50% !important;
          }
          }
        }
      }
    }
  }
  .x-wrapper {
    display: flex;
    align-items: center;
    padding-top: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #4E5156;
  }
  .total-count-wrapper {
    display: flex;
    align-items: center;
    padding-top: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #4E5156;
  }
`;
const TotalLimitWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #4E5156;
`;
const CountErrorWrapper = styled.div`
  position: absolute;
  z-index: 950;
  color: red;
  font-size: 14px;
  top: 58px;
  left: 4px;
`;
