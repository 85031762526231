import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {AddTaskModal} from './add-task-modal';
import {getSingleUrlParam} from '@/utils/url';
import {SmallBadge} from '@/components/common-components/components/badge';

const NotionIframe = observer(() => {
  const {reportBuilder: {details: {singleProjectData, isConfig, setModalData, modalData}}} = useStore('');
  const publicHash = getSingleUrlParam('public_hash');

  return <div>{
    singleProjectData?.notionPageId != '' && singleProjectData?.notionPageId != 'None' ?
      <div>
        {(isConfig || publicHash) ? null : <CreateNotionBtnsWrapper>
          <CreateNotionBtn onClick={() => setModalData({...modalData, openModal: true, modalType: 'add-task'})}>+ Add Task <SmallBadge customStyle={{left: '95px', top: '7px'}} beta /></CreateNotionBtn>
        </CreateNotionBtnsWrapper>}
        <iframe id='notion-iframe' style={{border: '1px solid #e8e8e8', boxShadow: '0 3px 2px 0 #00000005', borderRadius: '8px'}} height={550} width={'100%'} src={`https://api.searchatlas.com/client-notion-page/${singleProjectData?.notionPageId}/`}></iframe>
      </div> :
      <StyleNotion><p>No &quot;Orders & Tasks&quot; found!</p></StyleNotion>}
  {!publicHash && <AddTaskModal />}
  </div>;
});
export default NotionIframe;

const StyleNotion = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: gray;
  font-weight: 500;
  background-color: white;
  border-radius: 8px;
`;

const CreateNotionBtnsWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-bottom: 10px;
  position: relative;
`;

const CreateNotionBtn = styled.div`
  position: absolute;
  top: 18px;
  right: 110px;
  display: flex;
  align-items: center;
  width: 138px;
  height: 32px;
  padding: 7px 15px 8px 15px;
  border-radius: 8px;
  color: #ffff;
  cursor: pointer;
  background: #121212;
  box-shadow: 0px 3px 2px 0px #00000005;
  font-weight: 500;
`;
