import {BaseApi} from '@/api/base-api';
import {TaskStatus} from './backlink-analyzer/backlink-analyzer.model';
import {API_POLL_INTERVAL} from '@/constants';
import cookieCutter from 'cookie-cutter';

const AUTH_TOKEN = 'token';
const WHITELABELOTTO = 'whitelabelOtto';
const LAST_PROPERTY = 'lastProperty';
const LAST_PERIOD = 'lastPeriod';
const LAST_PERIOD_SE = 'lastPeriodSE';
const LAST_PERIOD_KRT = 'lastPeriodKrt';
const HOME_TYPE = 'type';
const HOME_SORT_BY = 'sortBy';
const HAS_REGISTERED = 'hasRegistered';
const COUNTRY_CODE = 'countryCode';
export const ONBOARDING_DATA = 'onboardingData';
const MEETING_BANNER = 'meetingBanner';
const REDIRECT_PATH = '_redirect_path';
export const ONBOARDING_ONBOARDING_USER_SELECTED_KEY = 'OnBoardingUserSelected';
export const ONBOARDING_ONBOARDING_MAIN_KEY = 'OnBoardingMain';
export const ONBOARDING_WHITELABEL_KEY = 'WhiteLabel';
export const ONBOARDING_CONTENT = 'Content';
export const ONBOARDING_KRT = 'Krt';
export const ONBOARDING_WEBSITEPROJECT_KEY = 'WebsiteProject';
export const ONBOARDING_AISEO_KEY = 'AiSeo';
export const ONBOARDING_RESEARCHTOOL_KEY = 'ResearchTools';
export const ONBOARDING_COMMUNITYPERKS_KEY = 'CommunityPerks';
export const ONBOARDING_BOOSTLOCAL_KEY = 'BoostLocal';
export const CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE = 'bulkUrlsAnalyzer';
export const ONBOARDING_AISEO_ISCOMPLETED_KEY = 'AiSeoIsCompleted';
export const ONBOARDING_GSC_ISCOMPLETED_KEY = 'GscIsCompleted';
export const ORDER_BUILDER_ISCOMPLETED = 'OrderbuilderIsCompleted';
export const ONBOARDING_ONBOARDING_USER_ISCOMPLETED_SELECTED_KEY = 'OnBoardingUserSelectedIsCompleted';
export const ONBOARDING_WHITELABEL_ISCOMPLETED_KEY = 'WhiteLabelIsCompleted';
export const ONBOARDING_COMMUNITYPERKS_ISCOMPLETED_KEY = 'CommunityPerksIsCompleted';
export const ONBOARDING_RESEARCHTOOL_ISCOMPLETED_KEY = 'ResearchToolsIsCompleted';
export const ONBOARDING_CURRENT_STEP = 'onboardingCurrentStep';
export const ONBOARDING_INTRO_COMPLETED = 'onboardingIntroCompleted';
export const ONBOARDING_COMPLETED_STEPS = 'onboardingCompletedSteps';

export const WHAT_IS_THIS = 'whatisthis';
export const LPO_WHAT_IS_THIS = 'lpowhatisthis';

export const GA_BANNER_HIDDEN = 'gaBannerHidden';

export function getTokenFromCookies() {
  if (typeof window !== 'undefined') {
    const eleId = document.getElementById('customerToken') as any;
    return eleId?.value || localStorage.getItem(AUTH_TOKEN) || '';
  }
}

export function setTokenIntoCookies(token: string) {
  localStorage.setItem(AUTH_TOKEN, token);
}

export function removeWhiteLabelOtto() {
  localStorage.removeItem(WHITELABELOTTO);
}

export function flushTokenFromCookies() {
  localStorage.removeItem(AUTH_TOKEN);
}


export function setRedirectPathIntoCookies() {
  if (typeof window !== 'undefined') {
    cookieCutter.set(REDIRECT_PATH, window.location.pathname);
  }
}

export function setWhatIsThisIntoStorage() {
  localStorage.setItem(WHAT_IS_THIS, 'clicked');
}
export function getWhatIsThisFromStorage() {
  return localStorage.getItem(WHAT_IS_THIS) || '';
}
export function setLPOWhatIsThisIntoStorage() {
  localStorage.setItem(LPO_WHAT_IS_THIS, 'lpoclicked');
}
export function getLPOWhatIsThisFromStorage() {
  return localStorage.getItem(LPO_WHAT_IS_THIS) || '';
}
export function getIsGaBannerHiddenFromStorage(userId: number) {
  const storedData = localStorage.getItem(GA_BANNER_HIDDEN);

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    return parsedData.includes(userId);
  } else {
    return false;
  }
}

export function setHideGaBannerIntoStorage(userId) {
  const storedData = localStorage.getItem(GA_BANNER_HIDDEN);
  if (storedData) {
    const parsedData = JSON.parse(storedData);
    const updatedData = [...(parsedData ?? []).filter(item => item !== userId), userId];
    localStorage.setItem(GA_BANNER_HIDDEN, JSON.stringify(updatedData));
  } else {
    localStorage.setItem(GA_BANNER_HIDDEN, JSON.stringify([userId]));
  }
}

export function setPeriodIntoLocalStorage(previousPeriodStart: string, previousPeriodEnd: string, currentPeriodStart: string, currentPeriodEnd: string, periodStatus:string) {
  const lastPeriod = JSON.stringify({
    p1Start: previousPeriodStart,
    p1End: previousPeriodEnd,
    p2Start: currentPeriodStart,
    p2End: currentPeriodEnd,
    periodStatus,
  });
  localStorage.setItem(LAST_PERIOD, lastPeriod);
}

export function setSiteEventDateIntoLocalStorage(previousPeriodStart: string, previousPeriodEnd: string, currentPeriodStart: string, currentPeriodEnd: string, periodStatus:string) {
  const lastPeriod = JSON.stringify({
    p1Start: previousPeriodStart,
    p1End: previousPeriodEnd,
    p2Start: currentPeriodStart,
    p2End: currentPeriodEnd,
    periodStatus,
  });
  localStorage.setItem(LAST_PERIOD_SE, lastPeriod);
}


export function setKrtPeriodIntoLocalStorage(currentPeriodStart: string, currentPeriodEnd: string) {
  const lastPeriod = JSON.stringify({
    p2Start: currentPeriodStart,
    p2End: currentPeriodEnd,
  });
  localStorage.setItem(LAST_PERIOD_KRT, lastPeriod);
}

export function getPeriodFromLocalStorage() {
  return localStorage.getItem(LAST_PERIOD) || null;
}

export function setLastPropertyIntoCookies(property: string) {
  localStorage.setItem(LAST_PROPERTY, property);
}

export function getLastPropertyFromCookies() {
  return localStorage.getItem(LAST_PROPERTY) || '';
}

export function setCountryCodeToLocalStorage(countryCode: string) {
  localStorage.setItem(COUNTRY_CODE, countryCode);
}

export function getCountryCodeFromLocalStorage() {
  return localStorage.getItem(COUNTRY_CODE) || '';
}

export function setHasRegisteredIntoStorage() {
  localStorage.setItem(HAS_REGISTERED, 'YES');
}

export function getHasRegisteredFromStorage() {
  return localStorage.getItem(HAS_REGISTERED) || '';
}

export function getHomeSortFromStorage(type: string) {
  switch (type) {
    case 'sort-by':
      return localStorage.getItem(HOME_SORT_BY);
    case 'type':
      return localStorage.getItem(HOME_TYPE);
    default:
      return null;
  }
}

export function setHomeSortIntoStorage(value: string, type: string) {
  switch (type) {
    case 'sort-by':
      localStorage.setItem(HOME_SORT_BY, value);
      break;
    case 'type':
      localStorage.setItem(HOME_TYPE, value);
      break;
    default:
      return null;
  }
}

export function clearHomeSortFromStorage() {
  localStorage.removeItem(HOME_SORT_BY);
  localStorage.removeItem(HOME_TYPE);
  localStorage.removeItem(CONSTANT_BULK_URL_ANALYZER_LOCAL_STORE);
}

export function getAuthHeader(headers = {}) {
  if (getTokenFromCookies()) {
    headers['Authorization'] = getAuthorizationHeader();
  }
  return headers;
}

export function getAuthorizationHeader() {
  const eleId = document.getElementById('customerToken') as any;
  return `Bearer ${eleId?.value || getTokenFromCookies()}`;
}

export function getHeaders() {
  return {
    headers: {Authorization: getAuthorizationHeader()},
  };
}

export function getApiHost(service: string) {
  if (service === BaseApi.LINKGRAPH_ENDPOINT) {
    return process.env.LG_HOST || '';
  } else if (service === BaseApi.GSC_ENDPOINT) {
    return process.env.GSC_HOST || '';
  } else if (service === BaseApi.BACKLINK_ENDPOINT) {
    return process.env.BL_HOST || '';
  } else if (service === BaseApi.KEYWORD_ENDPOINT) {
    return process.env.KW_HOST || '';
  } else if (service === BaseApi.CA_ENDPOINT) {
    return process.env.CA_HOST || '';
  } else if (service === BaseApi.SA_ENDPOINT) {
    return process.env.SA_HOST || '';
  }

  throw new Error(`No a valid service: ${service}`);
}

export function getApiUrl(service: string, url: string) {
  return `${getApiHost(service)}${url}`;
}

export async function retry<T>(callback: () => Promise<T>, status: Array<keyof T>): Promise<T> {
  return callback()
    .then(async resp => {
      // const didFail = status.some(status => ((resp[status] as unknown) as TaskStatus) === TaskStatus.FAILURE);
      // const didPass = status.every(status => {
      //   const respStatus: TaskStatus = resp[status] as unknown as TaskStatus;

      //   return respStatus === TaskStatus.SUCCESS || respStatus === null;
      // });
      const didFinish = status.every(status => {
        const respStatus: TaskStatus = resp[status] as unknown as TaskStatus;
        return respStatus === TaskStatus.SUCCESS || respStatus === TaskStatus.FAILURE;
      });
      const isPending = status.some(
        status =>
          ((resp[status] as unknown) as TaskStatus) !== TaskStatus.SUCCESS &&
          ((resp[status] as unknown) as TaskStatus) !== TaskStatus.FAILURE,
      );
      // if (didFail) {
      //   // TODO: Remove after STATUS
      //   return resp;
      //   // return Promise.reject(new Error('Internal API Failure'));
      // }
      // if (didPass) {
      //   return resp;
      // }
      if (didFinish) {
        return resp;
      }
      if (isPending) {
        await new Promise(r => setTimeout(r, API_POLL_INTERVAL));
        return retry<T>(callback, status);
      }
      return resp;
    })
    .catch(e => {
      throw new Error(e);
    });
}

export const getMessagesFromErrorResponse = (errorResponse: any, plainString = false): string[] | string => {
  const messages: any = [];
  let messageStr = '';
  let finalMessage = '';
  const responseData = errorResponse.response.data;
  try {
    Object?.keys(responseData).forEach( (key: string) => {
      if (typeof(responseData[key]) == 'string') {
        messageStr += `${responseData[key]}`;
        messages.push(responseData[key]);
      } else {
        messageStr += `${responseData[key][0]}.`;
        messages.push(responseData[key][0]);
      }
    });
    finalMessage = plainString ? messageStr : messages;
  } catch (error) {
    finalMessage = plainString ? messageStr : messages;
  }
  return finalMessage || 'Something went wrong.';
};

export function convertDom(str: string) {
  const a = str.split(/(?=[A-Z])/);
  return a.join('.').toLowerCase();
}

export const getExportUrl = (params:any, pageSize:number): string => {
  Object.keys(params).forEach(param => {
    if (params[param]=='' || typeof params[param] == 'undefined') {
      delete params[param];
    }
  });
  params['page'] = 1;
  params['page_size'] = pageSize;
  return new URLSearchParams(params).toString();
};

export const getExportUrlV2 = (params:any): string => {
  Object.keys(params).forEach(param => {
    if (params[param]=='' || typeof params[param] == 'undefined' || param == 'page' || param == 'page_size') {
      delete params[param];
    }
  });
  return new URLSearchParams(params).toString();
};

export const setOnboardingData = (key: string, data: any) => {
  try {
    let storedData = localStorage.getItem(ONBOARDING_DATA);
    if (storedData) {
      storedData = {...JSON.parse(storedData)};
      storedData[key] = data;
    } else {
      storedData = JSON.stringify({});
    }
    localStorage.setItem(ONBOARDING_DATA, JSON.stringify(storedData));
  } catch (error) {
    return null;
  }
};

export const getOnboardingData = (key: string) => {
  let storedData = localStorage.getItem(ONBOARDING_DATA);
  if (storedData) {
    storedData = JSON.parse(storedData);
    return storedData[key];
  }
  return null;
};

export const setMeetingBannerHidden = () => {
  localStorage.setItem(MEETING_BANNER, 'hidden');
};

export const isMeetingBannerHidden = () => {
  const bannerInfo = localStorage.getItem('meetingBanner');
  if (bannerInfo) {
    return true;
  }
  return false;
};

export const getOttoTablePageSize = (key: string) => {
  let savedPageSize = localStorage.getItem('ottoTablesPageSizes');
  savedPageSize = savedPageSize ? JSON.parse(savedPageSize) : {};
  const pageSize = savedPageSize[key] || 10;
  return parseInt(pageSize);
};


export const saveOttoTablePageSize = (key: string, pageSize: number) => {
  let savedPageSize = localStorage.getItem('ottoTablesPageSizes');
  const abc = savedPageSize ? JSON.parse(savedPageSize) : {};
  savedPageSize = {...abc, [key]: pageSize};
  localStorage.setItem('ottoTablesPageSizes', JSON.stringify(savedPageSize));
};

export const getCookieWithKey = (key: string | undefined): string => {
  const cookieHeader = document.cookie;
  const cookies: Record<string, string> = {};
  if (cookieHeader) {
    cookieHeader.split(';').forEach(cookie => {
      const [name, ...rest] = cookie.split('=');
      const value = rest.join('=').trim();
      cookies[name.trim()] = value;
    });
  }
  return cookies[key];
};
