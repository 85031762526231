import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {Divider, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStar} from '@fortawesome/pro-solid-svg-icons';
import {faCircleInfo} from '@fortawesome/pro-duotone-svg-icons';
import {pinPoint} from '@/utils/icons';
import {meters2ScreenPixels} from 'google-map-react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

export const getPositionColor = key => {
  switch (key) {
    case 1:
    case 2:
    case 3:
      return '#2AC155';
    case 4:
    case 5:
    case 6:
    case 7:
      return '#FF8536';
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
      return '#F44343';
    default:
      return '#B62222';
  }
};

export const getPositionColorV2 = position => {
  if (position >= 1 && position < 4) {
    return '#2AC155';
  } else if (position >= 4 && position < 8) {
    return '#FF8536';
  } else if (position >= 8 && position <= 20) {
    return '#F44343';
  } else {
    return '#B62222';
  }
};

export const MapTooltip = observer(({
  lat,
  lng,
  zoom,
  radiusMiles,
  setShowPinDetailModal,
  data,
  isReportBuilder=false,
  isLoader=false,
  hover=false,
  thumbnail='',
  selected=false,
  businessList,
  businessName,
  centerPoint,
  gridShape,
  pinWidth,
  fontSize,
  squareFour,
  businessIcon=false,
  setIsOpen= ()=> {},
}: any) => {
  const {w, h} = meters2ScreenPixels(squareFour ? (2*Math.PI*radiusMiles)*604.672 : (gridShape == 'square' ? (2*Math.PI*radiusMiles)*804.672 : (2*Math.PI*radiusMiles)*604.672), {lat, lng}, zoom);

  return <>
    {centerPoint && <GrayRadiusStyled style={{width: w, height: h}}></GrayRadiusStyled>}
    {!squareFour && <>
      {isLoader ? <WrapperStyled isReportBuilder={isReportBuilder} hover={hover} itemColor={'#4E5156'} selected={selected} thumbnail={thumbnail}>
        <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', zIndex: 2}}>
            {pinPoint('#4E5156', hover ? '#4E5156' : '')}
            <PinPointValue isLoader={isLoader}><Spin indicator={<LoadingOutlined style={{fontSize: 14, color: 'white'}} spin />} /></PinPointValue>
          </div>
        </div>
      </WrapperStyled> :
        <Tooltip color='#121212' overlayInnerStyle={{padding: '10px', borderRadius: '8px'}} title={
          <div>
            {businessList?.map(item => {
              return (
                <ToolTipText key={item?.title} style={{color: item?.position == data?.gmbPosition ? '#fff' : '#A3A4A4'}}>
                  <Numbering itemColor={getPositionColor(item?.position)}>{item?.position}</Numbering>{item?.title}
                </ToolTipText>);
            })}
            <Divider style={{background: '#4E5156', margin: '8px 0'}}/>
            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
              {businessIcon && <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                <FontAwesomeIcon icon={faCircleInfo}/>This is the location of {businessName}
              </div>}
              <div style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                <FontAwesomeIcon icon={faCircleInfo}/>Click on the grid dot to view all results in this location.
              </div>
            </div>
          </div>
        }>
          {businessIcon ? <CenterPinWrapper onClick={() => {
            setShowPinDetailModal(true);
            setIsOpen(data);
          }}>
            <CenterGBPImgStyled pinWidth={pinWidth} src={'/img/localSEOGBP.svg'} />
            <CenterPinDotWrapper pinWidth={pinWidth} color={getPositionColor(data?.gmbPosition)}>
              <CenterPinDotValue fontSize={fontSize}>{data?.gmbPosition == null ? '20+' : data?.gmbPosition}</CenterPinDotValue>
            </CenterPinDotWrapper>
          </CenterPinWrapper> : (data?.gmbPosition === 1 ?
            <StarWrapperStyled pinWidth={pinWidth} fontSize={fontSize} onClick={() => {
              setShowPinDetailModal(true);
              setIsOpen(data);
            }} positionOne={true} isReportBuilder={false} hover={hover} selected={false} itemColor={getPositionColor(data?.gmbPosition)} thumbnail={thumbnail}>
              <div style={{zIndex: 999}}>
                <div style={{paddingTop: '4px'}}>{data?.gmbPosition == null ? '20+' : data?.gmbPosition}</div>
                <StyledFirstPosition icon={faStar} color='#1FAC47' />
              </div>
            </StarWrapperStyled> :
            <WrapperStyled pinWidth={pinWidth} onClick={() => {
              setShowPinDetailModal(true);
              setIsOpen(data);
            }} positionOne={false} isReportBuilder={isReportBuilder} hover={hover} itemColor={getPositionColor(data?.gmbPosition)} selected={false} thumbnail={thumbnail}>
              <div style={{position: 'relative'}}>
                <div style={{position: 'absolute', zIndex: 2}}>
                  {pinPoint(getPositionColor(data?.gmbPosition), hover ? getPositionColor(data?.gmbPosition) : '')}
                  <PinPointValue top={pinWidth <= 26 ? '35%' : '30%'} fontSize={fontSize}>{data?.gmbPosition == null ? '20+' : data?.gmbPosition}</PinPointValue>
                </div>
              </div>
            </WrapperStyled>)}
        </Tooltip>}
    </>}
  </>;
});

const StarWrapperStyled = styled.div<{hover: boolean; thumbnail?:string; selected?: boolean; itemColor?: string; isReportBuilder?: boolean; positionOne?: boolean; pinWidth?: number; fontSize?: number}>`
  pointer-events: ${p => p?.isReportBuilder ? 'none' : 'default'};
  padding: 4px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 24px;
  margin-top: -27px;
  margin-left: -27px;
  border: ${p => p?.isReportBuilder ? '0px' : '1px'} solid ${p => p?.selected ? p.itemColor : 'transparent'};

  > div {
    position: relative;
    font-weight: 600;
    font-size: ${p => p?.fontSize ? p?.fontSize : p.positionOne ? '16' : '18'}px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    width: ${p => p.pinWidth ? p.pinWidth : '50'}px;
    height: 50px;
    contain: content;
    border-radius: ${p => p.positionOne ? '0px' : '22px'};
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${p => p.positionOne ? 'transparent' : p?.itemColor}; 
  }
  svg {
    ${p => p.pinWidth ? `width: ${p.pinWidth}px` : ''}
  }
`;

const WrapperStyled = styled.div<{hover: boolean; thumbnail?:string; selected?: boolean; itemColor?: string; isReportBuilder?: boolean; positionOne?: boolean; pinWidth?: number}>`
  pointer-events: ${p => p?.isReportBuilder ? 'none' : 'default'};
  padding: 4px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  border-radius: 24px;
  margin-top: -27px;
  margin-left: -27px;
  border: ${p => p?.isReportBuilder ? '0px' : '1px'} solid ${p => p?.selected ? p.itemColor : 'transparent'};
  svg {
    ${p => p.pinWidth ? `width: ${p.pinWidth}px` : ''}
  }
`;

const PinPointValue = styled.div<{fontSize?: number; top?: string; isLoader?: boolean}>`
  position: absolute;
  color: white;
  font-size: ${p => p?.fontSize ? p?.fontSize : '16'}px;
  font-weight: 600;
  top: ${p => p?.isLoader ? '20%' : (p?.top ? p?.top : '27%')};
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const StyledFirstPosition = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ToolTipText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
`;

const Numbering = styled.div<{itemColor?: string}>`
  width: fit-content;
  height: 19px;
  background: ${p => p?.itemColor};
  padding: 0 4px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
`;

const GrayRadiusStyled = styled.div`
  position: absolute;
  width: 368px;
  height: 368px;
  background: #121212;
  opacity: 0.1;
  border-radius: 50% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CenterPinWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const CenterGBPImgStyled = styled.img<{pinWidth?: number}>`
  margin-top: ${p => p?.pinWidth < 50 ? (p?.pinWidth <= 22 ? '' : '-8px') : '-15px'};
  margin-right: ${p => p?.pinWidth <= 22 ? '21px' : ''};
  ${p => p?.pinWidth && `width: ${p?.pinWidth}px;`}
`;

const CenterPinDotWrapper = styled.div<{color?: string; pinWidth?: number}>`
  position: absolute;
  bottom: 0;
  left: ${p => `${p?.pinWidth == 36 ? 2 : (p?.pinWidth > 22 ? -2 : -10)}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => `${p?.pinWidth <= 22 ? p?.pinWidth-10 : p?.pinWidth-20}px`};
  height: ${p => `${p?.pinWidth <= 22 ? p?.pinWidth-10 : p?.pinWidth-20}px`};
  border-radius: 50%;
  background: ${p => p?.color};
  border: 1px solid #000000;
`;

const CenterPinDotValue = styled.div<{fontSize?: number}>`
  color: white;
  font-size: ${p => p?.fontSize ? p?.fontSize-2 : '14'}px;
  font-weight: 600;
  pointer-events: none;
`;
