import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {Pill} from '@/components/layoutV2/user-menu/styles';

export const TabsRB = observer(() => {
  const {reportBuilder: {details: {isHideReport, getReportKrtDetailData, reportsGoogleListUser, reportsGoogleListMatrix, gmbReportDataRB, reportsFbList, reportsGoogleListOverTime, reportsGoogleListByCampaign,
    getReportGSCDetailsData, getReportGADetailData, singleProjectData, getReportsLocalSeoList, reportsFbListUser, reportsFbListAge, reportsFbListGender}}, ottoV2Store: {ottoProjectNotFoundInRb}} = useStore('');
  const [selected, setSelected] = useState('otto');
  const selectedObj = getReportsLocalSeoList?.find(item => singleProjectData?.reportsData.localSeo?.localSeoIds.includes(item.id));
  const {settings: {customer: {profile: {whitelabelOtto}}}} = useStore('');

  const scrollToSection = (tab, scroll) => {
    // stickNavbar();
    const element = document.getElementById(scroll);
    const alreadyScroll = document.getElementsByClassName('stickynav').length;
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - (alreadyScroll == 0? 70 : 70);
      window.scrollTo({top: y, behavior: 'smooth'});
      setTimeout(() => {
        setSelected(tab);
      }, 400);
    }
  };

  const isGoogleAd = (reportsGoogleListUser && reportsGoogleListUser['data']?.length > 0) || (reportsGoogleListMatrix && reportsGoogleListMatrix['data']?.length > 0) || (reportsGoogleListOverTime && reportsGoogleListOverTime['data']?.length > 0) || reportsGoogleListByCampaign && reportsGoogleListByCampaign['data']?.length > 0;
  const isFacebookAd = (reportsFbListUser && reportsFbListUser['data']?.length > 0 )|| (reportsFbListAge && reportsFbListAge['data']?.length > 0) || (reportsFbListGender && reportsFbListGender['data']?.length > 0);
  const saveSeo = singleProjectData?.layout?.filter(it => singleProjectData?.reportsData?.localSeo?.localSeoIds.includes(it?.index));
  const allSeos = saveSeo?.filter(item=> item.seoData?.length != 0);
  return (<>
    <Wapper className={`tabs`} id='scrollHeader'>
      {!ottoProjectNotFoundInRb && singleProjectData.ottoProjectUuid && (singleProjectData?.layout?.filter(item=> item?.tag == 'otto')?.length > 0 ) && <TabTitle isSelected={selected == 'otto'? 'black' : '#f9f9fb'} onClick={()=> scrollToSection('otto', 'ottoSection')}>{whitelabelOtto} SEO <Pill>Beta</Pill></TabTitle>}
      { (!isHideReport || (getReportGADetailData && getReportGADetailData['topMetrics']?.length != 0)) && singleProjectData?.reportsData?.ga && <TabTitle isSelected={selected == 'ga'? '#F1AA3E' : '#f9f9fb'} onClick={()=> scrollToSection('ga', 'gaSection')}>Google Analytics</TabTitle>}
      {(!isHideReport || (getReportGSCDetailsData && Object.values(getReportGSCDetailsData).filter(n=> n))?.length != 0) && singleProjectData?.reportsData?.gsc && <TabTitle isSelected={selected == 'gsc' ? '#2D6CCA' : '#f9f9fb'} onClick={()=> scrollToSection('gsc', 'gscSection')}>Google Search Console</TabTitle>}
      {(!isHideReport || getReportKrtDetailData) && singleProjectData?.reportsData?.rankTracker && <TabTitle isSelected={selected == 'krt' ? '#019197' : '#f9f9fb'} onClick={()=> scrollToSection('krt', 'krtSection')}>Keyword Rank Tracking</TabTitle>}
      {((!isHideReport || ((selectedObj && selectedObj?.keywordBreakdown?.length != 0) || allSeos))) && singleProjectData?.reportsData?.localSeo && <TabTitle isSelected={selected == 'lsh' ? '#2D6CCA' : '#f9f9fb'} onClick={()=> scrollToSection('lsh', 'lshSection')}>Local SEO</TabTitle>}
      {((!isHideReport || gmbReportDataRB?.length) && singleProjectData?.reportsData.gmb?.gmbIds) && <TabTitle isSelected={selected == 'gmb' ? '#2D6CCA' : '#f9f9fb'} onClick={()=> scrollToSection('gmb', 'gmbSection')}>Google Business Profile</TabTitle>}
      {((!isHideReport || (reportsFbList?.length > 0 && reportsFbList && reportsFbList) && isFacebookAd) && singleProjectData?.reportsData?.facebookAds?.facebookAdsAccountId) && <TabTitle isSelected={selected == 'fb' ? '#2D6CCA' : '#f9f9fb'} onClick={()=> scrollToSection('fb', 'fbSection')}>Facebook Ads</TabTitle>}
      {((!isHideReport || isGoogleAd) && singleProjectData?.reportsData?.googleAds?.googleAdsAccountId) &&
      <TabTitle isSelected={selected == 'gl' ? '#2D6CCA' : '#f9f9fb'} onClick={()=> scrollToSection('gl', 'googleSection')}>Google Ads</TabTitle>}
    </Wapper>
  </>
  );
});

const TabTitle = styled.p<{isSelected?: string}>`
  border-bottom: ${props=> '3px solid ' + props.isSelected};
  cursor: pointer;
  color: ${props=> props.isSelected != '#f9f9fb' ? 'black' : 'gray !important'};
  font-size: 14px;
`;

const Wapper = styled.div`

  display: flex;
  width: 1100px !important;
  margin: 0px 0 20px 0;
  border-bottom: 1px solid lightgray;
  p{
    margin: 0;
    padding-bottom: 15px;
    width: fit-content;
    margin-right: 22px;
    // margin-right: 45px;
    font-size: 16px;
    font-weight: 500;
  }
`;
