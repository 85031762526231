import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {notification, Row, Col} from 'antd';
import styles from './styles.module.scss';
import {Input, Button} from '@/components/common-components/v2';
import {getLocation} from '@/utils/string';
import styled from 'styled-components';
import {useEffect, useState} from 'react';
import {notification as notify} from '@/utils/notification-v2';
import {LargeBadge} from '@/components/common-components/components/badge';


export const ApiKey= observer(() => {
  const {settings: {customer: {profile}}} = useStore('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {contentOptimizer: {aiOutline: {updateUserAiSettings}, pages: {projects, updateContentProjectSettings, getAllProjects}}} = useStore('');

  const [keys, setKeys] = useState<{openAIKey: string; anthropicKey: string}>({
    openAIKey: '',
    anthropicKey: '',
  });

  const handleInputChange = e => {
    const {name, value} = e.target;
    setKeys({
      ...keys,
      [name]: value,
    });
  };

  useEffect(()=> {
    if (profile?.isLinkgraph) {
      getAllProjects();
    }
  }, []);

  useEffect(()=> {
    if (projects) {
      setKeys(() => ({
        openAIKey: projects?.[0]?.defaultAiSettings?.openaiSecretKey || '',
        anthropicKey: projects?.[0]?.defaultAiSettings?.anthropicSecretKey || '',
      }));
    }
  }, [projects]);

  const handleUpdateKeys = async () => {
    try {
      await updateContentProjectSettings({payload: {openai_secret_key: keys?.openAIKey, anthropic_secret_key: keys?.anthropicKey}, projectId: 0});
      notify.success('Success', 'Updated Successfully');
    } catch (error) {
      const errorObj = error?.response?.data;
      if ('anthropicSecretKey' in errorObj && 'openaiSecretKey' in errorObj) {
        notify.error('Bring your own keys', 'Both fields are required');
      } else if ('anthropicSecretKey' in errorObj) {
        notify.error('Anthropic secret key', errorObj?.anthropicSecretKey.join(' '));
      } else if ('openaiSecretKey' in errorObj) {
        notify.error('OpenAI secret key', errorObj?.openaiSecretKey.join(''));
      }
    }
  };

  const handleCopyTxt = entryText => {
    navigator.clipboard.writeText(entryText);
    notification.success({
      message: 'API Key is copied to clipboard',
      placement: 'bottomRight',
    });
  };
  const title = 'Search Atlas API Key';
  return (
    <div className={styles.topHeading}>
      {!profile.isWhitelabel ? title : title?.replace('Search Atlas', getLocation(location))}
      <Row gutter={24}>
        <Col xl={18} md={18} xs={24} className={styles.container}> <Input className={styles.keyContainer} value={profile?.searchatlasApiKey}/></Col>
        <Col xl={6} md={6} xs={24} style= {{marginTop: '24px'}}><div className={styles.footerRight} onClick={() => handleCopyTxt(profile?.searchatlasApiKey)}><img src='/img/copy-white.svg' />Copy API Key</div></Col>
      </Row>
      {profile?.isLinkgraph && <StyleDive>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className={styles.topHeading}>Bring your own keys</div>
          <LargeBadge alpha/>
        </div>
        <div>
          <Stylespan>OpenAI secret key</Stylespan>
          <StyledInput name={'openAIKey'} value={keys.openAIKey} onChange={handleInputChange}/>
        </div>
        <div>
          <Stylespan>Anthropic secret key</Stylespan>
          <StyledInput name={'anthropicKey'} value={keys.anthropicKey} onChange={handleInputChange}/>
          <ButtonStyle>
            <Button onClick={handleUpdateKeys}>Update keys</Button>
          </ButtonStyle>
        </div>
      </StyleDive>}
    </div>
  );
});

const StyleDive = styled.div`
border-top: 1px solid #dfdfdf;
margin-top: 35px;
`;

const Stylespan = styled.div`
  font-size: 15px;
  color: #696969;
  margin-top: 21px;
  margin-bottom: 4px;
`;

const StyledInput = styled(Input)`
  border-radius: 8px;
`;

const ButtonStyle = styled.div`
Button{
    margin: 0px;
    padding: 9px 25px;
    margin-top: 21px;

}
  //   @media (max-width: 767px) {
  //   Button {
  //     width: 100%;
  //     text-align: center;
  //   }
  // }

`;

